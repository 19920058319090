import {
    AfterViewInit, Component, ElementRef, EventEmitter, Input,
    OnChanges, OnInit, Output, SimpleChanges, ViewChild
} from '@angular/core';
import {LinesOfBusiness} from '../model/LinesOfBusiness';
import {Formulary} from '../model/Formulary';
import {timer} from 'rxjs';
import {AbstractControl, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {MatCheckbox} from '@angular/material/checkbox';
import {take} from 'rxjs/operators';
import {MAT_SELECT_CONFIG, MatSelect} from '@angular/material/select';
import {ActivatedRoute} from '@angular/router';
import {NAVIGATEDFROM} from '../formulary-page/formulary-page.component';
import {CVSBannerService, CVSBannerType} from 'angular-component-library';
import _ from 'lodash';
import {FormularyDrugSearch} from '../model/FormularyDrugSearch';
import {dateTruncation, isValidDate} from '../utility/utility';
import {CVSBannerComponentData} from 'angular-component-library/completed/banner/banner.interfaces';
import {MatOptionSelectionChange} from '@angular/material/core';
import {FormularyService} from '../service/formulary/formulary.service';
import {AppService} from '../service/app/app.service';

// eslint-disable-next-line no-shadow
export enum SEARCH_FIELD_ORDER {
    LOB = 'LOB',
    FORMULARY = 'FORMULARY',
    DRUG_INDICATOR = 'DRUG_INDICATOR',
    DRUG = 'DRUG'
}

export interface SearchDisplayOption {
    fieldOrder?: SEARCH_FIELD_ORDER[];
    hideSpyglass?: boolean;
    drugDefault?: string;
    drugIndicatorDefault?: string;
    displayAllWhenAllSelected?: boolean;
    formularyNoSelection?: boolean;
    searchByEnterKey?: boolean;
    hideSearchButton?: boolean;
    hideAdditionalFilters?: boolean;
}

export interface ControlDetails {
    [name: string]: {
        input: string;
        search?: string;
        drugSearch: string;
        maxLength: number;
        minLength?: number;
        hint?: string;
        error?: string;
        pattern?: string;
        validators: [(any) => {}];
    };
}

@Component({
    selector: 'app-formulary-drug-search',
    templateUrl: './formulary-drug-search.component.html',
    styleUrls: ['./formulary-drug-search.component.scss'],
    providers: [{
        provide: MAT_SELECT_CONFIG,
        useValue: {overlayPanelClass: 'app-formulary-drug-search-overlay-class' }
    }]
})
export class FormularyDrugSearchComponent implements OnInit, OnChanges, AfterViewInit {
    /* eslint-disable @typescript-eslint/member-ordering */
    defaultDisplayOption = {
        fieldOrder: [
            SEARCH_FIELD_ORDER.LOB,
            SEARCH_FIELD_ORDER.FORMULARY,
            SEARCH_FIELD_ORDER.DRUG_INDICATOR,
            SEARCH_FIELD_ORDER.DRUG,
        ],
        hideSpyglass: false,
        drugDefault: undefined,
        drugIndicatorDefault: undefined,
        displayAllWhenAllSelected: false,
        formularyNoSelection: false,
        searchByEnterKey: true,
        hideSearchButton: true,
        hideAdditionalFilters: true,
    };
    @ViewChild('drugInputCtrl') drugInputCtrl: ElementRef;
    @ViewChild('allLob') allLobSelected: MatCheckbox;
    @ViewChild('allFormularyCheckbox') allFormularySelected: MatCheckbox;
    @ViewChild('allSearchFormulariesCheckbox') allSearchFormulariesCheckbox: MatCheckbox;
    @ViewChild('lobSelectList') lobSelectList: MatSelect;
    @ViewChild('formularySelectList', {static: false}) formularySelectList: MatSelect;
    @Input() linesOfBusiness: LinesOfBusiness[];
    @Input() formularies: Formulary[];
    @Input() displayOption: SearchDisplayOption = this.defaultDisplayOption;
    @Input() buttons: [{ name: string; func: () => {} }];
    @Output() selectedLobs = new EventEmitter();
    @Output() selectedFormularies = new EventEmitter();
    @Output() drugSearchCriteria = new EventEmitter();
    setLobAndFormularyDefaultOnce = false;
    allFormularySelectedCheck = true;
    allSearchFormularySelectedCheck = false;
    filteredListFormularyData: Formulary[] = [];
    filteredFormularyListBeforeFormularyFilter = [];
    @Input() drugIndicatorList = [];
    isAllDisabled = false;
    isSearchAllDisabled = false;
    currentPath;
    regex = /[$&+,:;=?@#|'<>.^*()%!-]/;
    FORMULARY_SELECTION_LIMIT = 10;
    showHint = false;
    lob: UntypedFormControl;
    formGroup: UntypedFormGroup;
    @Input() showLobSpinner = true;
    @Input() showFormularySpinner = true;
    matSelectTriggerForFormulary = 'ALL';
    matSelectTriggerForLOB = 'Select All';
    isFirstOptionLastActive = false;
    filter = {
        lobValues: [],
        formularyValues: []
    };
    drugSearchDetails: ControlDetails = {
        GPI: {
            input: 'text', drugSearch: 'gpis', hint: 'Search by GPI 2, 4, 6, 8, 10, 12 or 14', maxLength: 14,
            error: 'Error: Provide GPI 2, 4, 6, 8, 10, 12 or 14 value',
            validators: [
                v => (v.value &&
                ([2, 4, 6, 8, 10, 12, 14].includes(v.value.length)) &&
                !this.regex.test(v.value) ? null : [{error: this.drugSearchDetails.GPI.error}])
            ]
        },
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'RxCUI': {
            search: 'RXCUI', drugSearch: 'rxcuis', input: 'number', maxLength: 8, minLength: 4,
            error: 'Error: Provide a valid value',
            validators: [v => (v.value && v.value.length >= 4) ? null : [{error: this.drugSearchDetails.RxCUI.error}]]
        },
        NDC: {
            input: 'number', drugSearch: 'ndcs', hint: 'Search by 5, 9 or 11 digits', maxLength: 11,
            error: 'Error: Provide a 5, 9 or 11 digit value',
            validators: [v => (v.value && [5, 9, 11].includes(v.value.length)) ? null : [{error: this.drugSearchDetails.NDC.error}]]
        },
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Drug Name': {
            search: 'DRUG_NAME',
            drugSearch: 'drugName',
            input: 'text',
            hint: 'Enter 3 or more characters',
            error: 'Error: Provide 3 or more characters',
            pattern: '.',
            minLength: 3,
            maxLength: 50,
            validators: [v => (v.value && v.value.length >= 3) ? null : [{error: this.drugSearchDetails['Drug Name'].error}]]
        }
    };
    onFormularySelected = true;
    offFormularySelected = false;
    codedSelected = true;
    defaultSelected = false;
    singleDateSelected = true;
    fromDate = new Date();
    toDate;
    showHighestPriorityChecked = false;
    errorHeaderText = 'Error: Invalid search values';
    errorMessage = 'Please complete or correct all errored fields in order to search';
    formularyStatusList = ['On and Off Formulary', 'On Formulary', 'Off Formulary'];
    formularyStatusListSelection = this.formularyStatusList[1];
    codedStatusList = ['Coded and Default', 'Coded', 'Default'];
    codedStatusListSelection = this.codedStatusList[1];
    formularyLob: any[];

    // eslint-disable-next-line max-len
    constructor(private activatedRoute: ActivatedRoute, private bannerService: CVSBannerService, private _formularyService: FormularyService, private _appService: AppService) {
    }

    public get SEARCH_FIELD_ORDER() {
        return SEARCH_FIELD_ORDER;
    }

    ngOnChanges(changes) {
        this.displayOption = {...this.defaultDisplayOption, ...this.displayOption};
        this.setDefaults();
    }

    ngOnInit(): void {
        this.currentPath = this.activatedRoute.snapshot.data['mode'];
        this.createForm();
        this.setLobAndFormularyDefaultOnce = true;
        this.setDefaults();
    }

    ngAfterViewInit() {
        if (this.formularySelectList) {
            (this.formularySelectList as any)._getItemHeight = () => 32;
        }
    }

    createForm() {
        this.formGroup = new UntypedFormGroup({
            lob: new UntypedFormControl('', [Validators.required]),
            formulary: new UntypedFormControl('', [Validators.required]),
            drugInputFormControl: new UntypedFormControl('', [Validators.required]),
            drugIndicatorFormControl: new UntypedFormControl(this.displayOption.drugIndicatorDefault, [Validators.required])
        });
    }

    setDefaults() {
        this.showLobSpinner = this.linesOfBusiness === undefined && this.showLobSpinner;
        this.showFormularySpinner = this.formularies === undefined && this.showFormularySpinner;
        if (this.linesOfBusiness && this.formularies && this.setLobAndFormularyDefaultOnce) {
            this.formGroup.controls.lob.patchValue(this.linesOfBusiness ? [...this.linesOfBusiness.map(l => l.code)] : []);
            this.matSelectTriggerForLOB = `${this.linesOfBusiness.map((lob) => lob ? lob.description : '')}`;
            this.filter.lobValues = this.linesOfBusiness ? [...this.linesOfBusiness.map(l => l.code)] : [];
            if (this.displayOption.formularyNoSelection) {
                this.allFormularySelectedCheck = false;
                this.formGroup.controls.formulary.patchValue([]);
            } else if (this.formularies.length > this.FORMULARY_SELECTION_LIMIT) {
                this.isAllDisabled = true;
                this.showHint = true;
                this.allFormularySelectedCheck = false;
                this.matSelectTriggerForFormulary = undefined;
                this.formGroup.controls.formulary.patchValue([]);
            } else {
                this.allFormularySelectedCheck = true;
                this.formGroup.controls.formulary.patchValue(this.formularies ? [...this.formularies] : []);
            }
            this.filter.formularyValues = this.formularies ? [...this.formularies] : [];
            this.filteredListFormularyData = this.formularies;
            this.filteredFormularyListBeforeFormularyFilter = _.clone(this.formularies);
            this.setLobAndFormularyDefaultOnce = false;
        }
    }

    resetDrugInput(event: MatOptionSelectionChange) {
        if (event.isUserInput) {
            this.displayOption.drugIndicatorDefault = event.source.value;
            this.resetDrugInputValidations();
            timer(50).pipe(take(1)).subscribe(() => {
                this.drugInputCtrl.nativeElement.focus();
            });
        }
    }

    drugInputKeyPress(drugIndicator: string, keyboardEvent: KeyboardEvent) {
        if (keyboardEvent.key === 'Enter' && this.displayOption.searchByEnterKey) {
            this.validateDrugInput(drugIndicator);
            if (this.formGroup.valid) {
                let value;
                if (this.currentPath === NAVIGATEDFROM.DRUG_RESEARCH) {
                    value = [this.drugSearchDetails[drugIndicator]?.drugSearch, drugIndicator].find(i => i !== undefined);
                    this.setDrugSearchCriteria(value, this.formGroup.controls.drugInputFormControl.value,
                        this.displayOption.fieldOrder, null);
                } else {
                    value = [this.drugSearchDetails[drugIndicator]?.search, drugIndicator].find(i => i !== undefined);
                    const formularyDrugSearch = this.setUpFormularyDrugSearchObject();
                    this.setDrugSearchCriteria(value, this.formGroup.controls.drugInputFormControl.value,
                        this.displayOption.fieldOrder, formularyDrugSearch);
                }
            }
            return false;
        }
        if (this.drugSearchDetails[drugIndicator].input === 'number') {
            if (!'0123456789'.includes(keyboardEvent.key)) {
                return false;
            }
        }
        this.formGroup.controls.drugInputFormControl.markAsTouched();
        if (!this.formGroup.controls.drugInputFormControl.value) {
            return true;
        }
        if (this.formGroup.controls.drugInputFormControl.value.length < this.drugSearchDetails[drugIndicator].maxLength) {
            return true;
        }
        this.formGroup.controls.drugInputFormControl.setValue(
            this.formGroup.controls.drugInputFormControl.value.substr(0, this.drugSearchDetails[drugIndicator].maxLength)
        );
        return false;
    }

    onSearchIconClick(drugIndicator: string, drug: string, fieldOrder) {
        const invalidFieldExist = this.invalidFieldExists(fieldOrder);
        if (!invalidFieldExist) {
            this.bannerService.close();
            if (drugIndicator !== undefined) {
                this.validateDrugInput(drugIndicator);
                if (this.currentPath === NAVIGATEDFROM.DRUG_RESEARCH && this.formGroup.controls.drugInputFormControl.valid &&
                    this.formGroup.controls.drugIndicatorFormControl.valid) {
                    const value = [this.drugSearchDetails[drugIndicator].drugSearch, drugIndicator].find(i => i !== undefined);
                    this.setDrugSearchCriteria(value, drug, fieldOrder, null);
                } else if (this.formGroup.valid) {
                    const value = [this.drugSearchDetails[drugIndicator].search, drugIndicator].find(i => i !== undefined);
                    const formularyDrugSearch = this.setUpFormularyDrugSearchObject();
                    this.setDrugSearchCriteria(value, drug, fieldOrder, formularyDrugSearch);
                }
            }
            return false;
        }
    }

    filterBySelectedLobToggleAllCheck(lobSelectList) {
        this.allLobSelected.checked = (lobSelectList._value.length === lobSelectList.options._results.length);
        this.allLobSelected.indeterminate = !this.allLobSelected.checked && lobSelectList._value.length !== 0;
        this.filterBySelectedLob(lobSelectList);
    }

    filterBySelectedLob(lobSelectList) {
        this.formularyLob = [];
        const selectedValues = lobSelectList._value;
        selectedValues.forEach(lob =>  {
            this.formularyLob = [...this.formularyLob, ...this._formularyService.businessLobToFormularyLobMap[lob]];
        });
        let formularyList = [];
        this.filter.lobValues = lobSelectList._value;
        this.filteredListFormularyData = [];
        this.formularyLob?.forEach(lobFilter => {
            formularyList = formularyList.concat(
                this.formularies.filter(data => data.linesOfBusiness.find(lob => lob.code === lobFilter)));
        });
        this.filteredListFormularyData = formularyList.filter((n, i) => formularyList.indexOf(n) === i)
            .sort((a, b) => (Number(a.formularyNumber) > Number(b.formularyNumber)) ? 1 : -1);
        this.setFormularySelectionOnBlobChange();
        this.selectedLobs.emit(this.filter);
    }

    emitSelectedFormularies() {
        this.filter.formularyValues = this.formGroup.controls.formulary.value;
        this.selectedFormularies?.emit(this.filter);
    }

    toggleLobAll(allLob, lobSelectionList) {
        if (!allLob.checked) {
            this.formGroup.controls.lob.patchValue([...this.linesOfBusiness.map(lob => lob.code)]);
            this.setAllLobSelection({checked: true} as MatCheckbox, lobSelectionList);
        } else {
            this.formGroup.controls.lob.patchValue([]);
            this.setAllLobSelection({checked: false} as MatCheckbox, lobSelectionList);
        }
        this.filterBySelectedLob(lobSelectionList);
    }

    toggleAllFormulary(searchText, allFormulary, formularySelectList) {
        if (!this.isAllDisabled) {
            if (allFormulary.checked) {
                if (searchText.length === 0) {
                    this.formGroup.controls.formulary.patchValue([...this.filteredListFormularyData]);
                } else {
                    const searchFilteredFormularies = this.filteredListFormularyData
                        .filter(formulary => formulary.clientFormularyName.includes(searchText) ||
                            formulary.formularyNumber.includes(searchText));
                    const values = this.formGroup.controls.formulary.value;
                    searchFilteredFormularies.forEach((i) => {
                        const index = values.indexOf(i.formularyNumber);
                        if (index !== -1) {
                            values.splice(index, 1);
                        }
                    });
                    this.formGroup.controls.formulary.patchValue(values);
                }
            } else {
                if (searchText.length === 0) {
                    this.formGroup.controls.formulary.patchValue([]);
                } else {
                    this.formGroup.controls.formulary.patchValue([
                        ...this.filteredListFormularyData.filter(
                            (formulary) =>
                                formulary.clientFormularyName.includes(searchText) ||
                                formulary.formularyNumber.includes(searchText)
                        ),
                    ]);
                }
            }
            this.setAllFormularySelection({checked: true} as MatCheckbox, formularySelectList);
        }
    }

    setDrugSearchCriteria(drugIndicator: string, drug: string, fieldOrder, formularyDrugSearch: FormularyDrugSearch) {
        this.drugSearchCriteria.emit({drugIndicator, drug, fieldOrder, formularyDrugSearch});
    }

    resetDrugInputValidations() {
        const ctrl = this.formGroup.controls.drugInputFormControl;
        ctrl.setValue('');
        ctrl.clearValidators();
        ctrl.updateValueAndValidity();
    }

    validateDrugInput(drugIndicator: string) {
        const ctrl = this.formGroup.controls.drugInputFormControl;
        ctrl.setValidators([
            ...this.drugSearchDetails[drugIndicator].validators, Validators.maxLength(this.drugSearchDetails[drugIndicator].maxLength),
            Validators.minLength(this.drugSearchDetails[drugIndicator].minLength)
        ]);
        ctrl.updateValueAndValidity();
    }

    setAllLobSelection(allCheckBox: MatCheckbox, selectList: MatSelect) {
        selectList.customTrigger = true;
        if (selectList.value.length > 1 || allCheckBox.checked) {
            this.matSelectTriggerForLOB = `${selectList.value.map((code) => {
                const lob = this.linesOfBusiness.find(lobSelect => lobSelect.code === code);
                return lob ? lob.description : '';
            })}`;
        } else {
            selectList.customTrigger = undefined;
            if (selectList.value.length === 1) {
                this.matSelectTriggerForLOB = selectList.value[0].lob;
            }
        }
    }

    setAllFormularySelection(allCheckBox: MatCheckbox, selectList: MatSelect) {
        selectList.customTrigger = true;
        if (allCheckBox.checked) {
            this.matSelectTriggerForFormulary = 'ALL';
        } else if (selectList.value.length > 1) {
            this.matSelectTriggerForFormulary = `${selectList.value.length} Formularies selected`;
        } else {
            selectList.customTrigger = undefined;
            if (selectList.value.length === 1) {
                this.matSelectTriggerForFormulary = `${selectList.value[0].formularyNumber} - ${selectList.value[0].clientFormularyName}`;
            }
        }
    }

    findSearchMatchFormularies(text: string): Formulary[] {
        let result = [];
        if (text !== '') {
            result = _.clone(this.filteredListFormularyData).filter(d => ((d.clientFormularyName.includes(text)
               || d.formularyNumber.includes(text))));
        } else {
            this.onSearchClose(this.lobSelectList);
        }
        return result;
    }

    setAllFilteredFormulary(allFilteredFormulariesCheckbox: MatCheckbox, searchFilterList: Formulary[], formularySelectList: MatSelect) {
        if (!this.isSearchAllDisabled) {
            if (allFilteredFormulariesCheckbox.checked) {
                const values = this.formGroup.controls.formulary.value;
                searchFilterList.forEach((i) => {
                    if (values.findIndex(f => f.formularyNumber === i.formularyNumber) === -1) {
                        values.push(i);
                    }
                });
                this.formGroup.controls.formulary.patchValue(values);
            } else {
                const values = this.formGroup.controls.formulary.value;
                searchFilterList.forEach((i) => {
                    const index = values.findIndex(f => f.formularyNumber === i.formularyNumber);
                    if (index !== -1) {
                        values.splice(index, 1);
                    }
                });
                this.formGroup.controls.formulary.patchValue(values);
            }
            this.setAllFormularyCheckStates();
            this.setAllFormularySelection({checked: this.formularies.length === formularySelectList.value.length} as MatCheckbox,
                formularySelectList);
        }
    }

    setAllSearchSelection(event: any) {
        const selectedFormularies = this.formGroup.controls.formulary.value;
        if (event.source._selected) {
            const index = selectedFormularies.findIndex(i => i === event.source.value);
            if (index === -1) {
                selectedFormularies.push(event.source.value);
            }
            this.formGroup.controls.formulary.patchValue(selectedFormularies);
        } else {
            if (selectedFormularies.includes(event.source.value)) {
                const existingFLIndex = selectedFormularies.findIndex(i => i === event.source.value);
                selectedFormularies.splice(existingFLIndex, 1);
                this.formGroup.controls.formulary.patchValue(selectedFormularies);
            }
        }
    }

    setAllFilteredSearchSelection() {
        const selectedFormularies = this.formGroup.controls.formulary.value;
        this.formularySelectList.options.forEach(opt => {
            if (opt.selected) {
                const index = selectedFormularies.findIndex(i => i === opt.value);
                if (index === -1) {
                    selectedFormularies.push(opt.value);
                }
                this.formGroup.controls.formulary.patchValue(selectedFormularies);
            } else {
                if (selectedFormularies.includes(opt.value)) {
                    const existingFLIndex = selectedFormularies.findIndex(i => i === opt.value);
                    selectedFormularies.splice(existingFLIndex, 1);
                    this.formGroup.controls.formulary.patchValue(selectedFormularies);
                }
            }
        });
    }

    keyUpEventOnSearchText(searchText) {
        if (searchText !== '') {
            this.filteredListFormularyData = this.findSearchMatchFormularies(searchText);
            this.setSearchFilterCheckStates();
        } else {
            this.onSearchClose(this.lobSelectList);
        }
        this.setAllFilteredSearchSelection();
    }

    setSearchFilterCheckStates() {
        const intersection = _.intersection(this.filteredListFormularyData.map(f => f.formularyNumber),
            this.formularySelectList.value.map(f => f.formularyNumber));
        this.allSearchFormularySelectedCheck = (intersection.length === this.filteredListFormularyData.length);
        this.isSearchAllDisabled = (this.filteredListFormularyData.length + this.formularySelectList.value.length - intersection.length) >
            this.FORMULARY_SELECTION_LIMIT;
        this.allSearchFormulariesCheckbox.indeterminate = !this.allSearchFormularySelectedCheck && intersection.length !== 0;
    }

    setAllFormularyCheckStates() {
        this.allFormularySelected.checked =
            (this.formularySelectList.value.length === this.filteredListFormularyData.length);
        this.allFormularySelectedCheck = this.allFormularySelected.checked;
        this.allFormularySelected.indeterminate = !this.allFormularySelected.checked && this.formularySelectList.value.length !== 0;
    }

    onSearchClose(lobSelectList) {
        this.filterBySelectedLob(lobSelectList);
        this.setSearchFilterCheckStates();
        this.allSearchFormularySelectedCheck = false;
        this.isSearchAllDisabled = this.formularySelectList.value.length > this.FORMULARY_SELECTION_LIMIT;
    }

    formularyStatusChanged(event: MatOptionSelectionChange){
        if (event.isUserInput) {
            if (event.source.value === this.formularyStatusList[0]) {
                this.onFormularySelected = true;
                this.offFormularySelected = true;
            } else if (event.source.value === this.formularyStatusList[1]) {
                this.onFormularySelected = true;
                this.offFormularySelected = false;
            } else if (event.source.value === this.formularyStatusList[2]) {
                this.onFormularySelected = false;
                this.offFormularySelected = true;
            }
        }
    }

    codedDefaultChanged(event: MatOptionSelectionChange) {
        if (event.isUserInput) {
            if (event.source.value === this.codedStatusList[0]) {
                this.codedSelected = true;
                this.defaultSelected = true;
            } else if (event.source.value === this.codedStatusList[1]) {
                this.codedSelected = true;
                this.defaultSelected = false;
            } else if (event.source.value === this.codedStatusList[2]) {
                this.codedSelected = false;
                this.defaultSelected = true;
            }
        }
    }

    getDates(event) {
        this.fromDate = event[0];
        this.toDate = event[1];
        this.singleDateSelected = event[2];
    }

    focusToSearchField() {
        this.getDocument().getElementById('searchText').focus();
    }

    isDrugTierDateValid() {
        const fromDate = this.fromDate ? dateTruncation(this.fromDate.toISOString()) : null;
        const toDate = this.toDate ? dateTruncation(this.toDate.toISOString()) : null;
        let isValid = true;
        if (this.singleDateSelected && !isValidDate(fromDate)) {
            isValid = false;
        } else if (!this.singleDateSelected && (!isValidDate(fromDate) || !isValidDate(toDate) ||
            (new Date(fromDate) > new Date(toDate)))) {
            this.getDocument().getElementById('endDate').focus();
            isValid = false;
        }
        return isValid;
    }

    getDocument() {
        return document;
    }

    moveFocusToList(event: KeyboardEvent) {
        if (event.key === 'ArrowDown') {
            this.formularySelectList.close();
            this.formularySelectList.open();
            this.formularySelectList.options.first.focus('keyboard');
            this.getDocument().getElementById('formulary').focus();
        }
    }

    onShowHighestPriorityClick() {
        this.showHighestPriorityChecked = !this.showHighestPriorityChecked;
        if (this.showHighestPriorityChecked) {
            this.formularyStatusListSelection = this.formularyStatusList[0];
            this.codedStatusListSelection = this.codedStatusList[0];
            this.defaultSelected = true;
            this.codedSelected = true;
            this.onFormularySelected = true;
            this.offFormularySelected = true;
            this.singleDateSelected = true;
            this.toDate = '';
        } else {
            this.defaultShowPriority();
        }
    }

    defaultShowPriority() {
        this.formularyStatusListSelection = this.formularyStatusList[1];
        this.codedStatusListSelection = this.codedStatusList[1];
        this.offFormularySelected = false;
        this.codedSelected = true;
        this.onFormularySelected = true;
        this.defaultSelected = false;
    }

    invalidFieldExists(fieldOrder): boolean {
        if (fieldOrder.includes(SEARCH_FIELD_ORDER.LOB)) {
            this.formGroup.controls.lob.markAsTouched();
        }
        if (fieldOrder.includes(SEARCH_FIELD_ORDER.FORMULARY)) {
            this.formGroup.controls.formulary.markAsTouched();
        }
        if (fieldOrder.includes(SEARCH_FIELD_ORDER.DRUG_INDICATOR)) {
            this.formGroup.controls.drugIndicatorFormControl.markAsTouched();
        }
        if (fieldOrder.includes(SEARCH_FIELD_ORDER.DRUG)) {
            this.formGroup.controls.drugInputFormControl.markAsTouched();
        }
        const isValid = this.isDrugTierDateValid();
        const invalidFieldExists = ((this.formGroup.controls.lob.touched && this.formGroup.controls.lob.invalid) ||
            (this.formGroup.controls.formulary.touched && this.formGroup.controls.formulary.invalid) ||
            (this.formGroup.controls.drugIndicatorFormControl.touched && this.formGroup.controls.drugIndicatorFormControl.invalid) ||
            (this.formGroup.controls.drugInputFormControl.touched && this.formGroup.controls.drugInputFormControl.invalid) || !isValid);
        if (invalidFieldExists) {
            const errorBannerData = {
                bannerType: CVSBannerType.Error,
                hideX: false,
                outletId: '#errorBanner',
                headline: this.errorHeaderText,
                body: this.errorMessage,
            } as CVSBannerComponentData;
            this.bannerService.sendAlert(errorBannerData);
        }
        return invalidFieldExists;
    }

    processUserFormularySelectionChange(event: MatOptionSelectionChange) {
        if (event.isUserInput) {
            this.setAllSearchSelection(event);
            this.setAllFormularyCheckStates();
        }
        this.setSearchFilterCheckStates();
        this.setAllFormularySelection(this.allFormularySelected, this.formularySelectList);
    }

    keyDownOnFormulary(event: KeyboardEvent, searchText: any): boolean {
        if (event.key === 'ArrowUp') {
            if ((this.formularySelectList.options.first as any)._active && this.isFirstOptionLastActive) {
                this.isFirstOptionLastActive = false;
                event.preventDefault();
                searchText.focus();
                return false;
            }
        }
        this.isFirstOptionLastActive = (this.formularySelectList.options.first as any)._active;
        const offset = this.formularySelectList.options.length > 6 ? 6 : 0;
        if (offset > 0) {
            (this.formularySelectList as any)
                ._scrollOptionIntoView(this.formularySelectList._keyManager.activeItemIndex + offset);
        }
    }

    private setUpFormularyDrugSearchObject(): FormularyDrugSearch {
        const formularyNumbers = this.formGroup.controls.formulary.value.map(m => m.formularyNumber ? m.formularyNumber : m);
        return {
            formularyNumbers: formularyNumbers.join(','),
            onFormulary: this.onFormularySelected,
            offFormulary: this.offFormularySelected,
            coded: this.codedSelected,
            defaulted: this.defaultSelected,
            fromDate: this.fromDate ? dateTruncation(this.fromDate.toISOString()) : null,
            toDate: this.toDate ? dateTruncation(this.toDate.toISOString()) : null,
            singleDateSelected: this.singleDateSelected,
            showHighestPriority: this.showHighestPriorityChecked
        } as FormularyDrugSearch;
    }

    onDrugInputFocusOut(drugIndicator: string) {
        this.validateDrugInput(drugIndicator);
    }

    isControlInError(control: AbstractControl){
        return !control.valid && control.touched;
    }

    getErrorOrHintText(drugInputFormControl: AbstractControl<any>, fieldName: string): string {
        if (this.isControlInError(drugInputFormControl)) {
            return this.drugSearchDetails[fieldName].error;
        }
        return this.drugSearchDetails[fieldName].hint;
    }

    setFormularySelectionOnBlobChange() {
        if (this.filteredListFormularyData.length > this.FORMULARY_SELECTION_LIMIT) {
            this.isAllDisabled = true;
            this.allFormularySelectedCheck = false;
            this.matSelectTriggerForFormulary = undefined;
            this.formGroup.controls.formulary.patchValue([]);
        } else {
            this.isAllDisabled = false;
            this.allFormularySelectedCheck = true;
            this.formGroup.controls.formulary.patchValue(
                this.filteredListFormularyData
                    ? [...this.filteredListFormularyData]
                    : []
            );
        }
        this.filter.formularyValues = this.formGroup.controls.formulary.value;
    }
}

