<div class="collapse-expend-section">
    <div>
        <p id="requiredFieldStatement">All fields marked with an asterisk (*) are required.</p>
    </div>
    <div>
    <button class="pds-btn-white" (click)="accordion.closeAll()">
        <mat-icon svgIcon="collapse--xs"></mat-icon>
        Collapse All
    </button>
    <button class="pds-btn-white" (click)="accordion.openAll()">
        <mat-icon svgIcon="expand--xs"></mat-icon>
        Expand All
    </button>
    </div>
</div>
<ng-container #stepTherapy>
    <mat-accordion multi>
        <mat-expansion-panel  [@.disabled]="true" #panel *ngFor="let stepTherapy of stepTherapyUMPrograms"
                              class="gap-mat-expansion"         
                              [expanded]="panelOpenState"
                              [formGroup]="stepTherapyFormGroups[stepTherapy.id]">
            <mat-expansion-panel-header class="umProgram-Prior-Auth-collapse-hdr">
                <mat-panel-title id="priorAuthTitle">
                    <div id="step-therapy-{{stepTherapy.umProgramName}}-{{stepTherapy.rank}}" class="anchorTarget"></div>
                    <div class="st-div-hdr"><h2 class="stH2">{{stepTherapy.umProgramName}}</h2></div>
                    <div class="st-div-hdr-icon"><app-custom-state id="recommendationStatusAll" [status]="mappedStatus[stepTherapy.id]"></app-custom-state></div>
                    <div class="st-div-hdr-formulary">
                        <span class="st-div-hdr-formulary-label">Formulary ID</span>
                        <span *ngIf="stepTherapy?.formularyId;else formulary_na">{{stepTherapy.formularyId}}</span>
                        <ng-template #formulary_na>N/A</ng-template>
                    </div>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-card>
                <div class="sub-field-font">
                    <mat-card-content>
                        <mat-form-field >
                            <mat-select placeholder="Step Type" formControlName="stepTherapyType"
                                        (selectionChange)="enableGridNotes(stepTherapy.id)"
                                        *ngIf="this.medispanTrackerResponse.businessLob === BUSINESS_LOB.MEDICARE">
                                <mat-option *ngFor="let priorAuth of stMedicareSelection" [value]="priorAuth.code">
                                    {{priorAuth.description}}
                                </mat-option>
                            </mat-select>
                            <mat-select placeholder="Step Type" formControlName="stepTherapyType"
                                        (selectionChange)="enableGridNotes(stepTherapy.id)"
                                        *ngIf="this.medispanTrackerResponse.businessLob !== BUSINESS_LOB.MEDICARE">
                                <mat-option *ngFor="let priorAuth of stNonMedicareSelection" [value]="priorAuth.code">
                                    {{priorAuth.description}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </mat-card-content>
                    <mat-card-content class="rejectMessageAndStNotesGrid">
                        <mat-form-field appearance="outline">
                            <mat-label class="formFieldLabelHeight">Custom Claim Reject Message</mat-label>
                            <textarea (input)="onRejectionMessageStepTherapyChanges(stepTherapy.id,stepTherapy.umProgramName)" matInput formControlName="customClaimRejectMessage" name="Custom Claim Reject Message" id="customClaimRejectMessage"></textarea>
                             <mat-hint *ngIf="hasTruncRejectMessageError && (forRejectionMessageField == stepTherapy.umProgramName)" class="warning-error-rejection-color">
                                <mat-icon svgIcon="warning-circle--xs" class="warning-icon-color"></mat-icon>Pasted data truncated to {{stepTherapyFormGroups[stepTherapy.id].controls['customClaimRejectMessage'].value?.length}} characters
                            </mat-hint>
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                            <mat-label class="formFieldLabelHeight">Step Notes</mat-label>
                            <textarea (input)="onStNotesStepTherapyChanges(stepTherapy.id,stepTherapy.umProgramName)" matInput formControlName="stNotes" name="St Notes" id="stNotes"></textarea>
                            <mat-hint *ngIf="hasTruncStNotesError && (forStNotesField == stepTherapy.umProgramName)" class="warning-error-rejection-color">
                                <mat-icon svgIcon="warning-circle--xs" class="warning-icon-color"></mat-icon>Pasted data truncated to {{stepTherapyFormGroups[stepTherapy.id].controls['stNotes'].value?.length}} characters
                            </mat-hint>
                            <mat-hint *ngIf="!hasTruncStNotesError">Provide concise notes for Step Therapy.</mat-hint>
                        </mat-form-field>
                    </mat-card-content>

                </div>
                <div>
                    <mat-checkbox formControlName="applyDefaultCheckBox"
                                  (change)="setDefaultSelection(stepTherapy.id)">
                        Apply Default Attachment</mat-checkbox>
                </div>
                <div id="tierAttachmentGridAll" class="sub-field-font">
                    <mat-card-content>
                        <mat-form-field>
                            <mat-select placeholder="Attachment Type" formControlName="attachmentType" (selectionChange)="buildAttachmentLevels($event.value, stepTherapy.id, false)">
                                <mat-option *ngFor="let attachmentType of attachmentTypes" [value]="attachmentType">
                                    {{attachmentType}}
                                </mat-option>
                            </mat-select>
                            <mat-error><mat-icon svgIcon="error-f--xs"></mat-icon> Select Attachment Type</mat-error>
                        </mat-form-field>
                    </mat-card-content>
                    <mat-card-content>
                        <mat-form-field>
                            <mat-select placeholder="Attachment Level" formControlName="attachmentLevel">
                                <mat-option *ngFor="let level of attachmentLevels[stepTherapy.id.toString()]" [value]="level[1]">({{(level[0].replace('_', ' '))}}) {{level[1]}}</mat-option>
                            </mat-select>
                            <mat-error><mat-icon svgIcon="error-f--xs"></mat-icon> Select Attachment Level</mat-error>
                        </mat-form-field>
                    </mat-card-content>
                    <mat-card-content *ngIf="['GPI'].includes(stepTherapyFormGroups[stepTherapy.id].controls.attachmentType.value)">
                        <mat-form-field>
                            <mat-select #attachmentMony
                                        multiple
                                        placeholder="Attachment MONY"
                                        formControlName="attachmentMonyDD">
                                <mat-checkbox #attachmentMonyCheckbox
                                              class="margin"
                                              formControlName="attachmentMonyCheckbox"
                                              [indeterminate]="monyIndeterminates[stepTherapy.id]"
                                              click="$event.preventDefault()"
                                              (change)="toggleAllSelection(stepTherapy.id)">
                                    Select All
                                </mat-checkbox>
                                <mat-option *ngFor="let data of attachmentMonyList"
                                            [value]="data.code"
                                            (click)="onAttachmentMonyDDChange(stepTherapy.id)">{{data.description}}</mat-option>
                            </mat-select>
                            <mat-error><mat-icon svgIcon="error-f--xs"></mat-icon> Select M, O, N, and/or Y</mat-error>
                        </mat-form-field>
                    </mat-card-content>
                    <mat-card-content *ngIf="[undefined, null, '','NDC'].includes(stepTherapyFormGroups[stepTherapy.id].controls.attachmentType.value)">
                        <mat-form-field>
                            <app-smart-input
                                    placeholder="Attachment MONY"
                                    formControlName="monyCode">
                            </app-smart-input>
                            <mat-error><mat-icon svgIcon="error-f--xs"></mat-icon> Select M, O, N, and/or Y</mat-error>
                        </mat-form-field>
                    </mat-card-content>
                </div>
                <div id="tierAttachmentRxOtcAll" class="sub-field-font">
                    <mat-card-content>
                        <mat-form-field>
                            <mat-select matInput placeholder="Attachment Rx/OTC" formControlName="coverageMiscRxOtc">
                                <mat-option *ngFor="let data of rxOtcList"
                                            [value]="data.code">{{data.description}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </mat-card-content>
                    <mat-card-content *ngIf="medispanTrackerResponse.businessLob === BUSINESS_LOB.MEDICARE">
                        <mat-form-field>
                            <mat-select  matInput placeholder="Attachment Med D" formControlName="coverageMiscMedD">
                                <mat-option *ngFor="let data of medDList"
                                            [value]="data.code">{{data.description}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </mat-card-content>
                </div>
            </mat-card>
        </mat-expansion-panel>
    </mat-accordion>
</ng-container>