import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {GridOptions, MenuItemDef} from '@ag-grid-community/core';
import {SetFilterModule} from '@ag-grid-enterprise/set-filter';
import {ColumnsToolPanelModule} from '@ag-grid-enterprise/column-tool-panel';
import {ClientSideRowModelModule} from '@ag-grid-community/client-side-row-model';
import {MenuModule} from '@ag-grid-enterprise/menu';
import {ClipboardModule} from '@ag-grid-enterprise/clipboard';
import {RichSelectModule} from '@ag-grid-enterprise/rich-select';
import {ClientProductDrugCoverage} from '../../../model/ClientProductDrugCoverage';
import {KabobCellComponent} from '../../../common/kabob-cell/kabob-cell.component';
import {CustomStateComponent} from '../../../common/custom-state/custom-state.component';
import {BUSINESS_LOB} from '../../../enum/BusinessLob';
import {MatTabChangeEvent} from '@angular/material/tabs';
import {AppService} from '../../../service/app/app.service';
import {RecommendationService} from '../../../service/recommendation/recommendation.service';
import {Subscription, take} from 'rxjs';
import {buildTabSelectionChangeEvent, setGridHeight} from '../../../utility/utility';
import {
    BUSINESS_LOB_DESC_CD,
    BUSINESS_LOB_NAME, MED_D,
    PRODUCT_DRUG_COVERAGE_TYPE, RX_OTC
} from '../../../default-values/DefaultValues';
import {UserService} from '../../../service/user/user.service';
import {
    RecommendationFormState
} from '../recommendations-formulary-requirements/recommendations-formulary-requirements.component';
import {TREE_LEVELS} from '../recommendation-left-navigation/TreeData';
import {MedispanTrackerResponse} from '../../../model/MedispanTrackerResponse';
import {Router} from '@angular/router';
import {ProductDrugCoverageRequirements} from '../../../model/ProductDrugCoverageRequirements';

@Component({
    selector: 'app-recommendations-product-and-drug-coverage',
    templateUrl: './recommendations-product-and-drug-coverage.component.html',
    styleUrls: ['./recommendations-product-and-drug-coverage.component.scss']
})
export class RecommendationsProductAndDrugCoverageComponent implements OnInit, OnChanges, OnDestroy {
    @Input() medispanTrackerResponse: MedispanTrackerResponse;
    @Input() mainTabName: string;
    @Input() subTabIndex: number;
    @Input() fromSource;
    @Input() updateLoading: boolean;
    @Input() productDetail;
    ndcBusinessLob;
    programTypes = PRODUCT_DRUG_COVERAGE_TYPE;
    clientProductDrugCoverageRowData: ClientProductDrugCoverage[] = [];
    recommendationFormState: RecommendationFormState;
    selectedBusinessLobName: String;
    selectedBusinessLobCd;
    encryptedRecordId;
    rxOtcList = RX_OTC;
    medDList = MED_D;
    savedPdcRequirements: ProductDrugCoverageRequirements[] = [];
    showSpinner = true;
    filteredProductAndDrugCoverageData: ClientProductDrugCoverage[];
    requirementDrugInfo: any[];
    gridHeight: any = 'auto';
    context = {this: this};
    gridColumnApi: any;
    subscriptions: Subscription[] = [];
    popupParent: HTMLElement | null = document.querySelector('body');
    modules = [SetFilterModule, ColumnsToolPanelModule, ClientSideRowModelModule,
        MenuModule, ClipboardModule, RichSelectModule];
    colDef = [
        {
            headerName: 'Action',
            field: 'kabob',
            cellRenderer: KabobCellComponent,
            editable: false,
            onCellClicked: (params) => this.kabobClicked(params),
            // eslint-disable-next-line @typescript-eslint/naming-convention
            cellStyle: {cursor: 'pointer', display: 'flex', 'align-items': 'center'},
            pinned: true,
            hide: false
        },
        {
            headerName: 'Program Type',
            field: 'productDrugCoverageTypeCd',
            filter: 'agSetColumnFilter',
            pinned: true,
            valueGetter: (params) => this.getProgramType(params)
        },
        {
            headerName: 'Program Name',
            field: 'productDrugCoverageName',
            filter: 'agSetColumnFilter',
            pinned: true
        },
        {
            headerName: 'Formulary ID',
            field: 'formularyId',
            filter: 'agSetColumnFilter',
            width: 140,
            pinned: true
        },
        {
            headerName: 'Requirement State',
            field: 'statusCd',
            filter: 'agSetColumnFilter',
            cellRenderer: CustomStateComponent
        },
        {
            headerName: 'Attachment Type',
            field: 'attachmentType',
            filter: 'agSetColumnFilter',
        },
        {
            headerName: 'Attachment Level',
            field: 'attachmentLevel',
            filter: 'agSetColumnFilter',
        },
        {
            headerName: 'Attachment MONY',
            field: 'monyCode',
            filter: 'agSetColumnFilter',
        },
        {
            headerName: 'Attachment Rx/OTC',
            field: 'coverageMiscRxOtc',
            filter: 'agSetColumnFilter',
        },
    ];
    activeGridOptions: GridOptions = {
        defaultColDef: {
            sortable: true,
            resizable: true,
            suppressSizeToFit: true,
        },
        enableCellTextSelection: true,
        suppressLoadingOverlay: true,
        columnDefs: this.colDef,
        onGridReady: (e) => this.onGridReady(e)
    };
    constructor(private appService: AppService, private recommendationService: RecommendationService, private userService: UserService,
        private _router: Router, private _recommendationService: RecommendationService,) { }

    ngOnInit(): void {
        this.ndcBusinessLob = this.medispanTrackerResponse.businessLob;
        this.selectedBusinessLobName = this.ndcBusinessLob;
        if(this.selectedBusinessLobName === BUSINESS_LOB.MEDICARE) {
            this.addMedicareColumn();
        } else {
            this.activeGridOptions.api?.setColumnDefs(this.colDef);
        }
        this.getActiveProductDrugCoverage();
        this.subscriptions.push(this._recommendationService.getLoadProductDrugCoverageRecommendationsComponentGridDataObservable()
            .subscribe( {
                next: () => {
                    this.filterProductAndDrugCoverage(buildTabSelectionChangeEvent(
                        BUSINESS_LOB_DESC_CD.get(this.ndcBusinessLob),
                        this.ndcBusinessLob
                    ));
                }
            }));
    }
    ngOnChanges(changes: SimpleChanges): void {
        this.encryptedRecordId = this.productDetail?.filter(
            data => BUSINESS_LOB_NAME.get(data.businessLobCd) === this.selectedBusinessLobName)[0]?.encryptedRecordId;
        if (changes.updateLoading) {
            this.filterProductAndDrugCoverage(
                buildTabSelectionChangeEvent(
                    BUSINESS_LOB_DESC_CD.get(this.ndcBusinessLob),
                    this.ndcBusinessLob
                )
            );
            this.showSpinner = !this.updateLoading;
        }
    }

    ngOnDestroy() {
        this.subscriptions.forEach( sub => sub.unsubscribe());
    }

    public kabobClicked(params: any) {
        this.buildRecommendationFormState(this.filteredProductAndDrugCoverageData?.at(params.rowIndex));
        params.api.contextMenuFactory.showMenu(
            params.node, params.column, params.value, params.event
        );
    }

    buildRecommendationFormState(productDrugCoverage?: ClientProductDrugCoverage) {
        this.recommendationFormState = {
            medispanTrackerResponse: this.medispanTrackerResponse,
            tabs: [this.mainTabName, this.subTabIndex],
            programIndex: TREE_LEVELS.PRODUCT_DRUG_COVERAGE.code,
            productDrugCoverage: productDrugCoverage,
        };
    }

    getContextMenuItems(params): MenuItemDef[] {
        const name = 'Edit Requirements';
        const options: MenuItemDef[] = [
            {
                name: name,
                action: () => {
                    params.context.this._router.navigate(['/recommendationsForm'],
                        {state: params.context.this.recommendationFormState});
                }
            }];
        return options;
    }
    addMedicareColumn() {
        const medicareColDef = [
            ...this.colDef,
            {
                headerName: 'Attachment Med D',
                field: 'coverageMiscMedD',
                filter: 'agSetColumnFilter',
            }
        ];
        if (this.activeGridOptions.api) {
            this.activeGridOptions?.api?.setColumnDefs(medicareColDef);
        } else {
            this.activeGridOptions.columnDefs = medicareColDef;
        }
    }

    filterProductAndDrugCoverage(event: MatTabChangeEvent) {
        this.requirementDrugInfo = [];
        this.selectedBusinessLobName = event.tab.textLabel;
        this.colDef.filter(col =>  {
            if(col.headerName === 'Action' && !this.allowEditing()) {
                col.hide = true;
            } else if(col.headerName === 'Action' && this.allowEditing()) {
                col.hide = false;
            }
        });
        if(this.clientProductDrugCoverageRowData !== undefined) {
            this.selectedBusinessLobCd = BUSINESS_LOB_DESC_CD.get(this.selectedBusinessLobName?.toString());
            this.filteredProductAndDrugCoverageData = this.clientProductDrugCoverageRowData?.filter(data =>
                BUSINESS_LOB_NAME.get(data.businessLobCode) === this.selectedBusinessLobName
            );
            this.encryptedRecordId = this.productDetail?.filter(
                data => BUSINESS_LOB_NAME.get(data.businessLobCd) === this.selectedBusinessLobName)[0]?.encryptedRecordId;
            if (this.encryptedRecordId) {
                this.subscriptions.push(
                    this._recommendationService.getProductDrugCoverageRequirements(this.encryptedRecordId, this.selectedBusinessLobCd)
                        .pipe(take(1))
                        .subscribe({
                            next: (pdcRecommendation) => {
                                this.requirementDrugInfo = [];
                                this.savedPdcRequirements = pdcRecommendation;
                                this.buildProductDrugCoverageRequirementInfo();
                            }
                        }));
            } else {
                this.buildProductDrugCoverageRequirementInfo();
            }
            this.recommendationService.setActiveProductDrugCoveragesByLOBTab(
                BUSINESS_LOB_DESC_CD.get(this.selectedBusinessLobName?.toString()), this.filteredProductAndDrugCoverageData);
        }
        if(this.selectedBusinessLobName === BUSINESS_LOB.MEDICARE) {
            this.addMedicareColumn();
        } else {
            this.activeGridOptions.api?.setColumnDefs(this.colDef);
        }

        this.gridHeight = setGridHeight(this.filteredProductAndDrugCoverageData);
    }

    buildProductDrugCoverageRequirementInfo() {
        this.filteredProductAndDrugCoverageData.forEach(productDrugRow => {
            let pdcRequirement: ProductDrugCoverageRequirements;
            if (this.savedPdcRequirements) {
                const requirements = this.savedPdcRequirements
                    ?.filter((f) => Number(f.clientProductDrugCoverageId) === productDrugRow.id
                        && f.clientProductDrugCoverageId !== null);
                if (requirements.length > 0) {
                    pdcRequirement = requirements[0];
                }
            }
            this.requirementDrugInfo.push({
                productDrugCoverageTypeCd: productDrugRow.productDrugCoverageTypeCd,
                productDrugCoverageName: productDrugRow.productDrugCoverageName,
                formularyId: productDrugRow ? productDrugRow.formularyId ? productDrugRow.formularyId : 'N/A' : 'N/A',
                statusCd : pdcRequirement?.statusCd ? pdcRequirement?.statusCd : '0',
                attachmentType : pdcRequirement?.attachmentType ? pdcRequirement?.attachmentType : 'N/A',
                attachmentLevel : pdcRequirement?.attachmentLevel ? '(' + pdcRequirement.attachmentType + ' ' +
                    pdcRequirement.attachmentLevel.length + ') ' + pdcRequirement.attachmentLevel : 'N/A',
                monyCode : pdcRequirement?.attachmentMony ? pdcRequirement?.attachmentMony : 'N/A',
                coverageMiscRxOtc : pdcRequirement?.attachmentRxOtcCd && (!pdcRequirement?.statusCd || pdcRequirement?.statusCd !== '0')
                    ? this.rxOtcList.filter(item => item.code === pdcRequirement?.attachmentRxOtcCd)[0]?.description : 'N/A',
                coverageMiscMedD : pdcRequirement?.attachmentMedDCd && (!pdcRequirement?.statusCd || pdcRequirement?.statusCd !== '0')
                    ? this.medDList.filter(item => item.code === pdcRequirement?.attachmentMedDCd)[0]?.description : 'N/A',
            });
        });
    }

    allowEditing(): boolean {
        return (this.ndcBusinessLob === this.selectedBusinessLobName) && this.userService.hasEditPermission();
    }

    getActiveProductDrugCoverage() {
        this.clientProductDrugCoverageRowData = [];
        const index = this.appService.getSuperClientContext().id;
        this.recommendationService.getActiveProductDrugCoverage(index).pipe(take(1)).subscribe((data) => {
            this.clientProductDrugCoverageRowData = data;
        });
    }

    getProgramType(params) {
        return this.programTypes[this.programTypes.findIndex(i => i.code === params.data?.productDrugCoverageTypeCd)]?.description;
    }

    private onGridReady(params: any) {
        this.gridColumnApi = params.columnApi;
        this.gridColumnApi.autoSizeAllColumns();
    }
}
