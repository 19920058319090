<div class="form-group-base floating-box">
    <div style="margin-left: 5px; margin-right: 15px; margin-bottom: 10px">
        <div class="um-hdr-container">
            <h1 id="umProgramHeader">{{ getUmProgramTypeTitle() }}</h1>
            <span>
                <button class="pds-btn-primary back-and-save-button" (click)="onSaveClicked()">Save Requirements</button>
            </span>
        </div>
    </div>
    <div id="matCardsContainer" style="overflow-y:auto;height: 63vh">
        <app-prior-auth-form-entry *ngIf="programIndexSelected === 1"
                                   [umPrograms]="umPrograms"
                                   [umProgram]="umProgram"
                                   [medispanTrackerResponse]="medispanTrackerResponse"
                                   (isPriorAuthSavedEmitter)="onRequirementSaved($event)"
                                   (isPriorAuthIncompleteEmitter)="onIncompleteRequirementSaved($event)"
        ></app-prior-auth-form-entry>

        <app-step-therapy-form-entry *ngIf="programIndexSelected === 2"
                                     [stepTherapyUMPrograms]="stepTherapyUMPrograms"
                                     [medispanTrackerResponse]="medispanTrackerResponse"
                                     [umProgram]="umProgram"
                                     (isStepTherapySavedEmitter)="onRequirementSaved($event)"
                                     (isSavedStepTherapyIncomplete)="onIncompleteRequirementSaved($event)"
        ></app-step-therapy-form-entry>

        <app-quantity-limit-form-entry
                *ngIf="programIndexSelected === 3"
                [qlUmPrograms]="qlUmPrograms"
                [umProgram]="umProgram"
                [medispanTrackerResponse]="medispanTrackerResponse"
                (isQuantityLimitSavedEmitter)="onRequirementSaved($event)"
                (isQuantityLimitIncompleteEmitter)="onIncompleteRequirementSaved($event)"
        ></app-quantity-limit-form-entry>
    </div>
</div>

