<h2 class="ppdcHeading">
    UM Program, Product, and Drug Coverage Requirements
</h2>
<p class="subHeader">Provide Utilization Management Program, Product, Drug Coverage requirements below to view in the requirements page.</p>
<div class="ppdc-container">
    <app-business-lob-tabs (selectedTab)="onBusinessLobTabSelectionChange($event)" [isPrimaryTab]="true" [isUpdated]="isUpdated" [lobLastToggled]="lobLastToggled"></app-business-lob-tabs>
</div>
<mat-tab-group id="secondaryTab" color="accent" (selectedTabChange)="onSecondaryTabChange($event) " [selectedIndex]="selectedIndex">
    <mat-tab label="UM Program"></mat-tab>
    <mat-tab label="Product & Drug Coverage"></mat-tab>
</mat-tab-group>

<div class="ppdcContent">
    <div *ngIf="selectedIndex === 0">
        <button class="pds-btn-ghost" (click)="openDialog(umProgramFormRef)" [disabled]="isButtonDisabled"
        [cvsTooltip]="noFormularyFoundTooltipText" [placement]="'right'" [showArrow]="true" [smallTooltip]="false">
            Create UM Program
        </button>
        <cvs-loader-overlay-container id="UmLoadingSpinner"
                                      [isLoading]="umLoadingSpinner"
                                      [loadingMessage]="'Loading Content...'">
        <app-cvs-ag-grid id="UmPpdcRequirementsGrid"
                         class="ag-theme-balham margin-top"
                         [gridOptions]="umProgramGridOptions"
                         [modules]="modules"
                         [popupParent]="popupParent"
                         [disableCvsExport]="true"
                         [paginationEnabled]="true"
                         [paginationPageSize]="10"
                         [getContextMenuItems]="umProgramContextMenuItems"
                         [context]="context"
                         [overlayNoRowsTemplate]="overlayNoRowsTemplate"
                         [domLayout]="'autoHeight'"
                         [rowData]="clientUmProgramRowData"
        >
        </app-cvs-ag-grid>
        </cvs-loader-overlay-container>
    </div>
    <div *ngIf="selectedIndex === 1">
        <button class="pds-btn-ghost"
        (click)="openDialog(productDrugCoverageFormRef)" [disabled]="isButtonDisabled"
        [cvsTooltip]="noFormularyFoundTooltipText" [placement]="'right'" [showArrow]="true" [smallTooltip]="false"
        >
            Create Product or Drug Coverage
        </button>
        <cvs-loader-overlay-container id="ProductAndDrugloadingSpinner"
                                      [isLoading]="productAndDrugLoadingSpinner"
                                      [loadingMessage]="'Loading Content...'">
        <app-cvs-ag-grid id="CreatePpdcRequirementsGrid"
                         class="ag-theme-balham margin-top"
                         [gridOptions]="productAndDrugCoverageGridOptions"
                         [modules]="modules"
                         [popupParent]="popupParent"
                         [disableCvsExport]="true"
                         [paginationEnabled]="true"
                         [paginationPageSize]="10"
                         [overlayNoRowsTemplate]="overlayNoRowsTemplate"
                         [domLayout]="'autoHeight'"
                         [rowData]="productDrugCoverageRowData"
                         [getContextMenuItems]="productAndDrugCoverageContextMenuItems"
                         [context]="context"
        >
        </app-cvs-ag-grid>
        </cvs-loader-overlay-container>
    </div>
</div>

<ng-template #productDrugCoverageFormRef>
    <app-product-drug-coverage (closeEvent)="onModalCancel()" (submitEvent)="onModalSubmit()" [isAnUpdate]="isAnUpdate"
                               [formularies]="currentFormularies" [formularyDescriptionForDD]="formularyDescriptionForDD"
                               [productDrugCoverageState]="productDrugCoverageState"
                               [businessLobCode]="businessLobCode">
    </app-product-drug-coverage>
</ng-template>

<ng-template #umProgramFormRef>
    <app-um-drug-program (closeEvent)="onModalCancel()" (submitEvent)="onModalSubmit()" [isAnUpdate]="isAnUpdate"
                         [formularies]="currentFormularies" [formularyDescriptionForDD]="formularyDescriptionForDD"
                         [umProgramState]="umProgramState" [businessLobCode]="businessLobCode"></app-um-drug-program>
</ng-template>

<ng-template #moveRankModalRef>
    <app-move-rank-modal (closeEvent)="onModalCancel()"  (submitEvent)="onMoveRankSubmit($event)" [selectedComponentName]="selectedComponentName" [currentRankIndex]="currentRankIndex" [rankList]="moveRankOptions" [moveRankData]="moveRankData" ></app-move-rank-modal>
</ng-template>
