import {Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {ColDef, GridOptions} from '@ag-grid-community/core';
import {SetFilterModule} from '@ag-grid-enterprise/set-filter';
import {ColumnsToolPanelModule} from '@ag-grid-enterprise/column-tool-panel';
import {ClientSideRowModelModule} from '@ag-grid-community/client-side-row-model';
import {MenuModule} from '@ag-grid-enterprise/menu';
import {ClipboardModule} from '@ag-grid-enterprise/clipboard';
import {KabobCellComponent} from '../../common/kabob-cell/kabob-cell.component';
import {AppService} from '../../service/app/app.service';
import {FormularyService} from '../../service/formulary/formulary.service';
import {MatTabChangeEvent} from '@angular/material/tabs';
import {take} from 'rxjs/operators';
import {Subscription} from 'rxjs';
import {
    CVSBannerComponentData,
    CVSBannerService,
    CVSBannerType,
    CVSConfirmationDialogContentComponent,
    CVSSnackBarData,
    CVSSnackBarService
} from 'angular-component-library';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Formulary} from '../../model/Formulary';
import {ClientFormularyRequirementRule} from '../../model/ClientFormularyRequirementRule';
import {dateFormatter} from '../../utility/utility';
import {FormRequirementRow} from '../../model/FormRequirementRow';
import {COVERAGE_TYPES} from '../../default-values/DefaultValues';
import {CODING_TYPES} from '../../enum/CodingTypes';
import {ConfigurationService} from '../../service/configuration/configuration.service';
import {
    FormularyRequirementsModalComponent
} from './formulary-requirements-modal/formulary-requirements-modal.component';
import {ClientFormularyMapping} from '../../model/ClientFormularyMapping';
import {formularyRequirementsContextMenuItems} from './context-menu-items';
import {KabobMenuActionTitlesEnum} from './KabobMenuActionTitlesEnum';
import {MoveRankData} from '../../model/MoveRankData';
import {CLIENT_CONFIGURATION_FOR_RANK_CHANGE} from '../../enum/ClientConfigurationForRankChange';

export interface IDrugList {
    drugListCodes: string;
    drugListID: string;
    drugListDescription: string;
}

@Component({
    selector: 'app-formulary-requirements',
    templateUrl: './formulary-requirements.component.html',
    styleUrls: ['./formulary-requirements.component.scss']
})
export class FormularyRequirementsComponent implements OnInit, OnDestroy{
    @ViewChild('formularyRequirementsFormRef') public formularyRequirementsFormRef: TemplateRef<any>;
    @ViewChild(FormularyRequirementsModalComponent) formularyRequirementsModalComponent: FormularyRequirementsModalComponent;
    @ViewChild('moveRankModalRef') public moveRankModalRef: TemplateRef<any>;
    @Input() isUpdated: boolean;
    @Input() lobLastToggled: string;
    formularyData: Formulary[];
    formulariesDrugListMap: Map<string, IDrugList[]> = new Map<string, IDrugList[]>();
    formularyRequirementsModalRef: MatDialogRef<CVSConfirmationDialogContentComponent, any>;
    confirmationDialog: MatDialogRef<CVSConfirmationDialogContentComponent, any>;
    spinnerValueFromFormularyRequirement = true;
    subscriptions: Subscription[] = [];
    requirementRulesData: ClientFormularyRequirementRule[];
    resultsArray: FormRequirementRow[] = [];
    showSpinner = true;
    formularyLobcd;
    formularyOpened;
    formularyRequirementsState;
    selectedFormularyName: string;
    currentRankIndex: number;
    moveRankData: MoveRankData;
    ranks: number[];
    moveRankOptions: number[];
    public context = {this: this};
    formularies: ClientFormularyMapping[];
    coverageTypes = COVERAGE_TYPES;
    deleteDialog: MatDialogRef<CVSConfirmationDialogContentComponent, any>;
    isAnUpdate = false;
    modules = [SetFilterModule, ColumnsToolPanelModule, ClientSideRowModelModule,
        MenuModule, ClipboardModule];
    defaultColDef: ColDef = {
        sortable: true,
        resizable: true,
    };
    columnDefs: ColDef[] = [
        {
            headerName: 'Action', field: 'kabob', cellRenderer: KabobCellComponent, width: 100,
            onCellClicked: (params) => this.onFormularyRequirementsKabobCellClicked(params)
        },
        {
            headerName: 'Rank', field: '', filter: 'agSetColumnFilter', width: 100,
            valueGetter: (params) => params.node?.rowIndex + 1
        },
        {
            headerName: 'Formulary Requirement Name',
            field: 'formularyRequirementName',
            filter: 'agSetColumnFilter',
            width: 200
        },
        {
            headerName: 'Formulary Requirement Description',
            field: 'formularyDescription',
            filter: 'agSetColumnFilter',
            width: 230
        },
        {
            headerName: 'Formulary Coverage Category',
            field: 'formularyCoverageCategoryCd',
            filter: 'agSetColumnFilter',
            width: 220,
            valueGetter: (params) => this.getFormularyCoverageCategory(params)
        },
        {
            headerName: 'Formulary Requirement View Date Range', field: '', filter: 'agSetColumnFilter', width: 250,
            valueFormatter: params => dateFormatter(params.data?.formularyRequirementViewStartDate)
                .concat(' - ').concat(dateFormatter(params.data?.formularyRequirementViewEndDate))
        },
        {
            headerName: 'Formulary Requirement Effective Date Range',
            field: '',
            filter: 'agSetColumnFilter',
            width: 270,
            valueFormatter: params => dateFormatter(params.data?.formularyRequirementEffectiveStartDate)
                .concat(' - ').concat(dateFormatter(params.data?.formularyRequirementEffectiveEndDate))
        },
        {
            headerName: 'Coding Setup', field: 'codingSetupCd', filter: 'agSetColumnFilter', width: 150,
            valueGetter: (params) => this.getCodingSetupCd(params)
        },
    ];
    overlayNoRowsTemplate = `<span><img src="/assets/error-f--s.svg" width="25px" height="25px"></br><b>No results found.</b></span>`;
    popupParent: HTMLElement | null = document.querySelector('body');
    businessLobCode;
    protected readonly formularyRequirementsContextMenuItems = formularyRequirementsContextMenuItems;

    constructor(private _appService: AppService,
        private _formularyService: FormularyService,
        private matDialog: MatDialog,
        private bannerService: CVSBannerService,
        private _configurationService: ConfigurationService,
        private deleteModal: MatDialog,
        private snackBarService: CVSSnackBarService
    ) {
    }

    ngOnInit(): void {
    }

    onBusinessLobTabSelectionChange(event: MatTabChangeEvent) {
        this.businessLobCode = event.tab.ariaLabel;
        this.formularyLobcd = this.businessLobCode;
        this.getClientFormularyData();
        this.populateClientFormularyTables();
        this.getClientFormularyDataWithDrugListInfo();
    }

    ngOnDestroy() {
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }

    getClientFormularyData() {
        this.showSpinner = true;
        this.formularies = [];
        this.resultsArray = [];
        this.formulariesDrugListMap = new Map<string, IDrugList[]>();
        this._appService.getInitializationSubject().pipe(take(1)).subscribe({
            next: () => {
                const index = this._appService.getSuperClientContext().id;
                this.subscriptions.push(this._formularyService.getClientFormularies(index).pipe(take(1)).subscribe(formularyResponse => {
                    formularyResponse?.forEach((formulary) => {
                        if (formulary.businessLobCode === this.formularyLobcd && formulary.showFormulary === 'Y') {
                            this.formularies.push(formulary);
                        }
                    });
                    this.resultsArray = this.getRequirementRowData();
                }));
            },
            error: () => {
            },
            complete: () => {
            }
        });
    }

    getClientFormularyDataWithDrugListInfo() {
        this.spinnerValueFromFormularyRequirement = true;
        this.formularyData = [];
        const index = this._appService.getSuperClientContext().id;
        this._formularyService.getActiveFutureDrugListInfoForClientFormulary(index).pipe(take(1)).subscribe({
            next: (formularyResponse) => {
                this.formularyData = formularyResponse;
                if (this.formularyData && this.formularyData.length > 0) {
                    const formularies = this._formularyService.getDistinctFormulariesByBusinessLob(this.formularyLobcd, this.formularyData);
                    this.getDrugListsInfo(formularies);
                    this.formularyRequirementsModalComponent?.getDrugList();
                }

            },
            error: () => {
                const errorBannerData = {
                    bannerType: CVSBannerType.Error,
                    hideX: false,
                    outletId: '#errorBanner',
                    headline: `System error: Unable to retrieve data`,
                    body: `Please try again`,
                } as CVSBannerComponentData;
                this.bannerService.sendAlert(errorBannerData);
            },
            complete: () => {
                this.spinnerValueFromFormularyRequirement = false;
            }
        });
    }

    populateClientFormularyTables() {
        this._configurationService.getClientFormularyRequirementRules(this._appService.getSuperClientContext().id, this.formularyLobcd)
            .pipe(take(1)).subscribe({
                next: (formularyRequirements) => {
                    this.requirementRulesData = formularyRequirements;
                    this.resultsArray = this.getRequirementRowData();
                }
            });
    }

    openDialog(formularyRequirementsFormRef: TemplateRef<any>, formulary: any) {
        this.formularyOpened = formulary;
        this.formularyRequirementsModalRef = this.matDialog.open(CVSConfirmationDialogContentComponent, {
            data: {
                headline: `Create a Formulary Requirement Rule for: ${formulary.formularyId} - ${formulary.formularyName}`,
                templatePortalContent: formularyRequirementsFormRef
            },
            disableClose: true,
            panelClass: 'formulary-requirements-modal'
        });

        this.formularyRequirementsModalRef.componentInstance.onCancelClick.subscribe(() => {
            this.formularyRequirementsModalRef.close();
        });
    }

    getDrugListsInfo(formularies: Formulary[]) {
        formularies.forEach(formulary => {
            const drugLists = [];
            formulary?.drugLists?.forEach((drug) => {
                const drugListInfo: IDrugList = {
                    drugListCodes: drug.drugListType.toUpperCase(),
                    drugListID: drug.id,
                    drugListDescription: drug.description
                };
                drugLists.push(drugListInfo);
            });
            this.formulariesDrugListMap.set(formulary.formularyNumber, drugLists);
        });
    }

    closeModal() {
        this.populateClientFormularyTables();
        this.isAnUpdate = false;
        this.formularyRequirementsModalRef.close();
    }

    getRequirementRowData() {
        const requirementRows: FormRequirementRow[] = [];
        this.formularies?.forEach(f => {
            let filteredRequirementRules: ClientFormularyRequirementRule[] = [];
            if (this.requirementRulesData !== null && this.requirementRulesData !== undefined) {
                filteredRequirementRules = this.requirementRulesData.filter(rd => rd.formularyId === f.formularyId);
            }
            const requirementRow: FormRequirementRow = {
                formularyId: f.formularyId,
                formularyName: f.formularyName,
                businessLobCode: f.businessLobCode,
                gridOption: <GridOptions>{},
                data: filteredRequirementRules
            };
            requirementRows.push(requirementRow);
        });
        this.showSpinner = false;
        return requirementRows;
    }

    public getFormularyCoverageCategory(params) {
        return this.coverageTypes[this.coverageTypes.findIndex(i => i.code === params.data.formularyCoverageCategoryCd)].description;
    }

    public getCodingSetupCd(params) {
        return CODING_TYPES[params.data.codingSetupCd];
    }

    buildFormularyRequirementsState(paramsData: ClientFormularyRequirementRule) {
        this.formularyRequirementsState = {
            lob: this.businessLobCode,
            id: paramsData.id,
            rank: paramsData.rank,
            formularyId: paramsData.formularyId,
            formularyRequirementName: paramsData.formularyRequirementName,
            formularyDescription: paramsData.formularyDescription,
            formularyRequirementEffectiveStartDate: paramsData.formularyRequirementEffectiveStartDate,
            formularyRequirementEffectiveEndDate: paramsData.formularyRequirementEffectiveEndDate,
            formularyRequirementViewStartDate: paramsData.formularyRequirementViewStartDate,
            formularyRequirementViewEndDate: paramsData.formularyRequirementViewEndDate,
            codingSetupCd: paramsData.codingSetupCd,
            formularyCoverageCategoryCd: paramsData.formularyCoverageCategoryCd,
            planGpiListId: paramsData.planGpiListId,
            preferredGpiListId: paramsData.preferredGpiListId,
            preferredNdcListId: paramsData.preferredNdcListId,
        };
    }

    showDeleteFormularyRequirementsModal() {
        this.openDeleteFormularyRequirementsModal();
        this.deleteDialog.componentInstance.onConfirmClick.subscribe(() => {
            // eslint-disable-next-line max-len
            this._configurationService.deleteClientFormularyRequirementRule(this._appService.getSuperClientContext().id, this.formularyRequirementsState).pipe(take(1)).subscribe(
                {
                    next: () => {
                        this.snackBarService.open({
                            iconName: 'check-circle--s',
                            message: 'Formulary Requirement Rule deleted successfully',
                            duration: 5,
                            verticalPosition: 'bottom',
                            horizontalPosition: 'center'
                        } as CVSSnackBarData);
                        this.populateClientFormularyTables();
                    }
                }
            );
        });
    }

    openDeleteFormularyRequirementsModal() {
        this.deleteDialog = this.deleteModal.open(CVSConfirmationDialogContentComponent, {
            data: {
                headline: 'Delete this Formulary Requirement Rule?',
                // eslint-disable-next-line max-len
                body: `All preferences related to this Formulary Requirement Rule will be<br>permanently deleted from ${this.formularyRequirementsState.formularyId} - ${this.formularyRequirementsState.formularyRequirementName}.<br><br>` +
                    'Warning: This cannot be undone.',
                actionLabel: 'Delete',
                cancelLabel: 'Cancel',
            }, disableClose: true
        });

    }

    openUpdateFormularyRequirementsModal(modalRef: TemplateRef<any>) {
        this.isAnUpdate = true;
        this.formularyRequirementsModalRef = this.matDialog.open(CVSConfirmationDialogContentComponent, {
            data: {
                // eslint-disable-next-line max-len
                headline: `Update a Formulary Requirement Rule for: ${this.formularyRequirementsState.formularyId} - ${this.formularyRequirementsState.formularyRequirementName}`,
                templatePortalContent: modalRef,
            },
            disableClose: true,
            panelClass: 'formulary-requirements-modal'
        });
        this.formularyRequirementsModalRef.componentInstance.onCancelClick.subscribe(() => {
            this.isAnUpdate = false;
            this.formularyRequirementsModalRef.close();
        });
    }

    showMoveRank(rank: number, data: any, children: any) {
        this.selectedFormularyName = data.formularyRequirementName;
        this.currentRankIndex = rank;
        this.ranks = [];
        children.forEach((row: { data: any }) => {
            this.ranks.push(row.data.rank);
        });
        this.ranks.sort((a, b) => a - b);
        this.moveRankOptions = this.ranks.map((item) => this.ranks.indexOf(item) + 1);
        this.moveRankOptions.splice(this.currentRankIndex - 1, 1);
        this.buildMoveRankData(KabobMenuActionTitlesEnum.MOVE_RANK);
        this.confirmationDialog = this.matDialog.open(CVSConfirmationDialogContentComponent, {
            data: {
                headline: 'Move Rank',
                templatePortalContent: this.moveRankModalRef,
            },
            disableClose: true,
        });

        this.confirmationDialog.componentInstance.onCancelClick.subscribe(() => {
            this.confirmationDialog.close();
        });
    }

    onModalCancel() {
        this.confirmationDialog.close();
    }

    onMoveRankSubmit(index: number) {
        this.onModalCancel();
    }

    onFormularyRequirementsKabobCellClicked(params) {
        this.subscribeToRankMovement();
        this.formularyRequirementsState = [];
        this.buildFormularyRequirementsState(params.data);
        // @ts-ignore
        if (params.column.colDef.field === 'kabob') {
            // @ts-ignore
            params.api.contextMenuFactory.showMenu(params.node, params.column, params.value, params.event);
        }
    }

    buildMoveRankData(action: KabobMenuActionTitlesEnum) {
        this.moveRankData = {
            id: this.formularyRequirementsState.id,
            businessLobCode: this.formularyRequirementsState.lob,
            newRankToBeMovedTo: this.formularyRequirementsState.rank,
            clientConfigurationPageForRankMovement: CLIENT_CONFIGURATION_FOR_RANK_CHANGE.FORMULARY_REQUIREMENT,
            formularyId: this.formularyRequirementsState.formularyId
        };
        if (action === KabobMenuActionTitlesEnum.MOVE_UP) {
            this.moveRankData.newRankToBeMovedTo = this.moveRankData.newRankToBeMovedTo - 1;
        } else if (action === KabobMenuActionTitlesEnum.MOVE_DOWN) {
            this.moveRankData.newRankToBeMovedTo = this.moveRankData.newRankToBeMovedTo + 1;
        }
    }

    subscribeToRankMovement() {
        this.subscriptions.push(this._configurationService.getRankMovementProvider()
            .subscribe((componentPage) => {
                if (componentPage === CLIENT_CONFIGURATION_FOR_RANK_CHANGE.FORMULARY_REQUIREMENT) {
                    // eslint-disable-next-line max-len
                    this._configurationService.getClientFormularyRequirementRules(this._appService.getSuperClientContext().id, this.formularyLobcd)
                        .pipe(take(1)).subscribe({
                            next: (formularyRequirements) => {
                                this.requirementRulesData = formularyRequirements;
                                this.resultsArray?.forEach(rr => {
                                    if (rr.formularyId === this.formularyRequirementsState.formularyId) {
                                        // eslint-disable-next-line max-len
                                        const requirementRuleForFormulary = this.requirementRulesData.filter(f => f.formularyId === this.formularyRequirementsState.formularyId);
                                        rr.data = requirementRuleForFormulary;
                                        rr.gridOption.api.setRowData(requirementRuleForFormulary);
                                        rr.gridOption.api.redrawRows();
                                    }
                                });
                            }
                        });
                }
            }
            ))
        ;
    }

    moveRankUp() {
        this.buildMoveRankData(KabobMenuActionTitlesEnum.MOVE_UP);
        this.moveRankClientConfigurationCall();
    }
    moveRankDown() {
        this.buildMoveRankData(KabobMenuActionTitlesEnum.MOVE_DOWN);
        this.moveRankClientConfigurationCall();
    }

    moveRankClientConfigurationCall() {
        this._configurationService.moveRankClientConfiguration(this._appService.getSuperClientContext().id, this.moveRankData)
            .pipe(take(1)).subscribe(() => {
                this.snackBarService.open({
                    iconName: 'check-circle--s',
                    message: 'Rank moved successfully',
                    duration: 5,
                    verticalPosition: 'bottom',
                    horizontalPosition: 'center'
                } as CVSSnackBarData);
                if (this.moveRankData.clientConfigurationPageForRankMovement ===
                    CLIENT_CONFIGURATION_FOR_RANK_CHANGE.FORMULARY_REQUIREMENT) {
                    this._configurationService.rankMovementProvider.next(CLIENT_CONFIGURATION_FOR_RANK_CHANGE.FORMULARY_REQUIREMENT);
                }
            });
    }
}
