import {Component, OnInit} from '@angular/core';
import {
    AuthorizationService,
    BrandTypes,
    CVSAuthConfig,
    CVSBaseConfig,
    MenuState,
    SideNavConfig
} from 'angular-component-library';
import {UserService} from './service/user/user.service';
import {ENVIRONMENT} from './environments/environment';
import {CustomTooltipComponent} from './custom-tooltip/custom-tooltip.component';
import {SuperClient} from './model/SuperClient';
import {FEATURE_NAME, FeatureFlagService} from './service/feature-flag/feature-flag.service';
import {UserPreferenceService} from './service/user-preference/user-preference.service';
import {AppService} from './service/app/app.service';
import {NavItemConfig} from 'angular-component-library/completed/side-nav/SideNavConfig';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';
import {CustomWelcomeComponent} from './common/custom-welcome/custom-welcome.component';
import {NavigationEnd, Router} from '@angular/router';
import {filter, take} from 'rxjs';
import _ from 'lodash';
import {CLIENT_SELECTION_MENU, IMenu, MENU, MY_PBM_URL_MENU} from './menu/menu-permission-map';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

    components: [
        { 'customTooltipComponent': CustomTooltipComponent }
    ];

    authConfig: CVSAuthConfig;
    baseConfig: CVSBaseConfig;
    sideNavConfig: SideNavConfig;
    currentSuperClient: string;
    updatedWorkflowUrl = 'workflow';
    previousUrl: string = null;
    currentUrl: string = null;

    private year = new Date().getFullYear();
    constructor(private authorizationService: AuthorizationService,
        private userService: UserService,
        private featureFlagService: FeatureFlagService,
        private userPreferenceService: UserPreferenceService,
        private appService: AppService,
        private matIconRegistry: MatIconRegistry,
        private router: Router,
        private domSanitizer: DomSanitizer) {
        this.router.events?.pipe(filter(event => event instanceof NavigationEnd), take(1)).subscribe((event: any) => {
            if (event.id === 1 && event.url === event.urlAfterRedirects) {
                window.location.assign('/');
            }
        });
        this.router.events?.pipe(
            filter((event) => event instanceof NavigationEnd)
        ).subscribe((event: NavigationEnd) => {
            this.previousUrl = this.currentUrl;
            this.currentUrl = event.url;
            this.setupWorkflowMenu();
        });

        this.matIconRegistry.addSvgIcon('unchecked-circle',
            domSanitizer.bypassSecurityTrustResourceUrl('/assets/unchecked-circle.svg'));

        this.authConfig = {
            authenticationEnabled: ENVIRONMENT.authenticationEnabled,
            ssoBaseUrl: ENVIRONMENT.ssoBaseUrl,
            authorizationUrl: ENVIRONMENT.authorizationUrl,
            clientId: ENVIRONMENT.clientId,
            idle: ENVIRONMENT.idle,
            timeOut: ENVIRONMENT.timeOut,
            tokenUrl: ENVIRONMENT.tokenUrl,
            useAccessToken: ENVIRONMENT.useAccessToken,
            discoveryDocumentUrl: ENVIRONMENT.discoveryDocumentUrl,
            loginUrl: ENVIRONMENT.loginUrl,
            logoutUrl: ENVIRONMENT.logoutUrl,
            issuer: ENVIRONMENT.issuer,
            accessDeniedMessage: ENVIRONMENT.accessDeniedMessage,
            scope: ENVIRONMENT.scope
        } as CVSAuthConfig;

        this.baseConfig = {
            homeRoute: '',
            appName: 'Clinical Requirements (CLR)' + ENVIRONMENT.region,
            unbranded: false,
            brand: BrandTypes.CAREMARK,
            footerText: `© Copyright ${this.year} CVS Caremark`,
            headerPortal: CustomWelcomeComponent
        } as CVSBaseConfig;
        this.sideNavConfig = {
            sideNavItems: [],
            iconMenu: true,
            menuState: MenuState.Open
        } as SideNavConfig;
    }

    ngOnInit(): void {
        this.authorizationService.authorizationSuccess.subscribe( () => {
            this.appService.initializeServices().subscribe(mapResult => {
                this.userService.getSuperClientContextSubject().subscribe(superClient => {
                    this.currentSuperClient = superClient.superClientCode;
                    this.setSuperClientBanner(superClient);
                    this.buildMenu();
                });
            });
        });

        if (ENVIRONMENT.appDynamicKey !== null) {
            this.loadAppDynamicScript();
        }
    }
    buildClientSelectionMenu(userPermissions: string[]) {
        let navItem: NavItemConfig;
        if ( this.hasMenuPermission(CLIENT_SELECTION_MENU.CLIENT_SELECTION.permissions, userPermissions)
            && this.userService.getUser().clients.length > 1) {
            navItem = {};
            navItem.name = CLIENT_SELECTION_MENU.CLIENT_SELECTION.name;
            navItem.icon = CLIENT_SELECTION_MENU.CLIENT_SELECTION.icon;
            navItem.routerLink = CLIENT_SELECTION_MENU.CLIENT_SELECTION.routerLink;
        }
        return navItem;
    }

    buildMyPBMURLMenu() {
        const navItem: NavItemConfig = {
            name : MY_PBM_URL_MENU.myPBM.name,
            icon : MY_PBM_URL_MENU.myPBM.icon,
            routerLink : MY_PBM_URL_MENU.myPBM.routerLink
        };
        return navItem;
    }

    setSuperClientBanner(superClient: SuperClient) {
        this.baseConfig.appName = `Clinical Requirements (CLR)${ENVIRONMENT.region} - ${superClient.clientName}`;
    }

    buildMenu() {
        this.sideNavConfig.sideNavItems = [];
        const permissions = this.userService.getUser()?.permissions.map(p => p.toUpperCase().trim());
        const internalUser = this.userService.getUser()?.internalUser;
        const clientSelection = this.buildClientSelectionMenu(permissions);
        const myPBMUrlMenu = this.buildMyPBMURLMenu();
        if (clientSelection) {
            this.sideNavConfig.sideNavItems.push(clientSelection);
        }
        const menu = _.values(MENU);
        menu.forEach( (m: IMenu) => {
            const isMenuVisible = (m.featureFlag) ? this.hasFeatureFlagEnabled(m.featureFlag, internalUser): true;
            if (this.hasMenuPermission(m.permissions, permissions) && isMenuVisible) {
                const navItem: NavItemConfig = {};
                navItem.name = m.name;
                navItem.icon = m.icon;
                if (m.name === 'Workflow') {
                    navItem.routerLink = this.updatedWorkflowUrl===null? 'workflow' : this.updatedWorkflowUrl;
                } else {
                    navItem.routerLink = m.routerLink;
                }
                this.sideNavConfig.sideNavItems.push(navItem);
            }
        });
        if (myPBMUrlMenu) {
            this.sideNavConfig.sideNavItems.push(myPBMUrlMenu);
        }
    }

    hasMenuPermission(menuPermission: string[], userPermissions: string[]) {
        let hasPermission = false;
        menuPermission.forEach( mp => {
            const index = userPermissions.indexOf(mp.toString().toUpperCase());
            if (index !== -1 ) {
                hasPermission = true;
            }
        });
        return hasPermission;
    }

    hasFeatureFlagEnabled(menuFeature: FEATURE_NAME[] , isInternal: boolean) {
        let isEnabledViaFeatureFlag = false;
        menuFeature.forEach( feature => {
            if (feature.toString() !== FEATURE_NAME.WORKFLOW_PAGE_EXTERNAL) {
                isEnabledViaFeatureFlag = this.featureFlagService.isFeatureEnabled(feature);
            }
            if (!isInternal && feature.toString() === FEATURE_NAME.WORKFLOW_PAGE_EXTERNAL) {
                isEnabledViaFeatureFlag = this.featureFlagService.isFeatureEnabled(FEATURE_NAME.WORKFLOW_PAGE_EXTERNAL);
            }
        });
        return isEnabledViaFeatureFlag;
    }

    setupWorkflowMenu() {
        if (this.previousUrl !== null && this.currentUrl !==null) {
            if (this.previousUrl.includes('workflow')) {
                if (this.currentUrl.includes('clientSelection')) {
                    this.updatedWorkflowUrl = null;
                } else {
                    this.updatedWorkflowUrl = this.previousUrl;
                }
            } else {
                this.updatedWorkflowUrl = null;
            }
            this.buildMenu();
        }
    }

    private loadAppDynamicScript() {
        const script1 = document.createElement('script');
        script1.type = 'text/javascript';
        script1.innerHTML =
            'window["adrum-start-time"] = new Date().getTime(); ' +
            '(function(config){ ' +
            'config.appKey = "' +
            ENVIRONMENT.appDynamicKey +
            '"; ' +
            'config.adrumExtUrlHttp = "http://cdn.appdynamics.com"; ' +
            'config.adrumExtUrlHttps = "https://cdn.appdynamics.com"; ' +
            'config.beaconUrlHttp = "http://pdx-col.eum-appdynamics.com"; ' +
            'config.beaconUrlHttps = "https://pdx-col.eum-appdynamics.com"; ' +
            'config.useHTTPSAlways = true; ' +
            'config.resTiming = {"bufSize": 200, "clearResTimingOnBeaconSend": true}; ' +
            'config.maxUrlLength = 512; ' +
            '})(window["adrum-config"] || (window["adrum-config"] = {}));';
        const script2 = document.createElement('script');
        script2.src = '//cdn.appdynamics.com/adrum/adrum-23.3.0.4265.js';
        const head = document.getElementsByTagName('head')[0];
        if (head !== null && head !== undefined) {
            document.head.appendChild(script1);
            document.head.appendChild(script2);
        }
    }
}
