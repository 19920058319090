<ng-container class="scrollable-area">
    <form [formGroup]="productDrugCoverageForm" (ngSubmit)="saveProductDrugCoverage()">
        <div id="productDrugCoverageErrorBanner"></div>
        <div class="pdc-coverage-form">
            <p>All fields marked with an asterisk (*) are required.</p>
            <mat-form-field class="pdcComponentItem_width">
                <mat-label>Product & Drug Coverage Type</mat-label>
                <mat-select pdsMatOptionKeyboardFocus formControlName="type">
                    <mat-option *ngFor="let coverageType of coverageTypes" [value]="coverageType.code">
                        {{coverageType.description}}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="productDrugCoverageForm.controls.type.errors?.required">
                    <mat-icon svgIcon="error-f--s"></mat-icon>Select a Product or Drug Coverage Type
                </mat-error>
            </mat-form-field>

            <mat-form-field  [ngClass]="{'form-warning' :productDrugCoverageForm.controls.name.errors?.pastedValueTruncated}">
                <app-smart-input
                        placeholder="Product & Drug Coverage Name *"
                        maxlength="30"
                        formControlName="name">
                </app-smart-input>
                <mat-error *ngIf="productDrugCoverageForm.controls.name.errors?.required">
                    <mat-icon svgIcon="error-f--s"></mat-icon>Provide a Product or Drug Coverage Name
                </mat-error>
                <mat-error *ngIf="productDrugCoverageForm.controls.name.errors?.pastedValueTruncated" class="warning-error-color">
                    <mat-icon svgIcon="warning-circle--xs" class="warning-icon-color"></mat-icon> Pasted data truncated to {{productDrugCoverageForm.controls.name.errors.pastedValueTruncated}} characters
                </mat-error>
            </mat-form-field>

            <mat-form-field [ngClass]="{'form-warning' :productDrugCoverageForm.controls.description.errors?.pastedValueTruncated}">
                <app-smart-input
                        placeholder="Product & Drug Coverage Description *"
                        maxlength="50"
                        formControlName="description">
                </app-smart-input>
                <mat-error *ngIf="productDrugCoverageForm.controls.description.errors?.required">
                    <mat-icon svgIcon="error-f--s"></mat-icon>Provide a Product or Drug Coverage Description
                </mat-error>
                <mat-error *ngIf="productDrugCoverageForm.controls.description.errors?.pastedValueTruncated" class="warning-error-color">
                    <mat-icon svgIcon="warning-circle--xs" class="warning-icon-color"></mat-icon> Pasted data truncated to {{productDrugCoverageForm.controls.description.errors.pastedValueTruncated}} characters
                </mat-error>
            </mat-form-field>
            <pds-autocomplete-multiselect id="pdcFormularyAutoComplete" [label]='"Formulary ID *"'
                                          [appearance]="appearance"
                                          formControlName="formularyDescription"
                                          (onClosed)="setFormularyId()"
                                          [listOfOptions]="formularyDescriptionForDD"
            >
            </pds-autocomplete-multiselect>
            <div style="width: 58.5%; display: flex"
                 *ngIf="productDrugCoverageForm.controls.formularyDescription.errors?.required">
                <mat-icon id="pdcAutoCompErrorIcon" svgIcon="error-f--xs"></mat-icon>
                <mat-label id="pdcAutoCompErrorText">
                    Select a Formulary ID</mat-label>
            </div>
            <mat-form-field id="pdcViewDateRange" class="pdcDates_width">
                <mat-label>View Date Range *</mat-label>
                <mat-date-range-input [rangePicker]="viewPicker">
                    <input matStartDate formControlName="viewStartDate">
                    <input matEndDate formControlName="viewEndDate">
                </mat-date-range-input>
                <mat-hint>MM/DD/YYYY - MM/DD/YYYY</mat-hint>
                <mat-datepicker-toggle id="vdrDateRangePicker" matSuffix [for]="viewPicker">
                    <mat-icon id="vdrDateRangeIcon" matDatepickerToggleIcon svgIcon="calendar--s"></mat-icon>
                </mat-datepicker-toggle>
                <mat-date-range-picker #viewPicker></mat-date-range-picker>
                <mat-error *ngIf="productDrugCoverageForm.controls.viewStartDate.errors || productDrugCoverageForm.controls.viewEndDate.errors">
                    <mat-icon svgIcon="error-f--s"></mat-icon>Provide a valid date range within 01/01/1995 - 12/31/2039
                </mat-error>
            </mat-form-field>

            <mat-form-field class="pdcDates_width">
                <mat-label>Effective Date Range *</mat-label>
                <mat-date-range-input [rangePicker]="picker">
                    <input matStartDate formControlName="effectiveStartDate">
                    <input matEndDate formControlName="effectiveEndDate">
                </mat-date-range-input>
                <mat-hint>MM/DD/YYYY - MM/DD/YYYY</mat-hint>
                <mat-datepicker-toggle id="edrDateRangePicker" matSuffix [for]="picker">
                    <mat-icon id="edrDateRangeIcon" matDatepickerToggleIcon svgIcon="calendar--s"></mat-icon>
                </mat-datepicker-toggle>
                <mat-date-range-picker #picker></mat-date-range-picker>
                <mat-error *ngIf="productDrugCoverageForm.controls.effectiveStartDate.errors || productDrugCoverageForm.controls.effectiveEndDate.errors">
                    <mat-icon svgIcon="error-f--s"></mat-icon>Provide a valid date range within 01/01/1995 - 12/31/2039
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>Default Custom Rejection Message</mat-label>
                <textarea
                        matInput
                        cdkTextareaAutosize
                        (input)="onRejectionMessageDrugChanges()"
                        [cdkAutosizeMinRows]="3"
                        [cdkAutosizeMaxRows]="6"
                        formControlName="rejectionMessage"
                        (focusout)="stopWarning()"
                ></textarea>
                <mat-hint *ngIf="!hasTruncRejectMessageError" class="mat-hint-rejection">(optional)</mat-hint>
                <mat-hint *ngIf="hasTruncRejectMessageError" class="warning-error-rejection-color">
                    <mat-icon svgIcon="warning-circle--xs" class="warning-icon-color"></mat-icon> Pasted data truncated to {{productDrugCoverageForm.controls['rejectionMessage'].value?.length}} characters
                </mat-hint>
            </mat-form-field>
        </div>
        <div class="form-buttons">
            <button class="pds-btn-white" (click)="onModalCancel()" type="reset">Cancel</button>
            <button *ngIf="!isAnUpdate" class="pds-btn-primary" type="submit">Create Product or Drug Coverage</button>
            <button *ngIf="isAnUpdate == true" class="pds-btn-primary" type="submit">Update Product or Drug Coverage</button>
        </div>
    </form>
</ng-container>
