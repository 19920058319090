import {Component, OnInit} from '@angular/core';
import {SuperClient} from '../model/SuperClient';
import {UserService} from '../service/user/user.service';
import {Router} from '@angular/router';
import {ICLRRouteReuseStrategy} from '../ICLRRouteReuseStrategy';
import {CacheService} from '../service/HttpDataCache';
import {MAT_SELECT_CONFIG} from '@angular/material/select';

@Component({
    selector: 'app-client-selection',
    templateUrl: './client-selection-page.component.html',
    styleUrls: ['./client-selection-page.component.scss'],
    providers: [
        { provide: MAT_SELECT_CONFIG,
            useValue : { overlayPanelClass : 'app-client-selection-overlay-class'}}
    ]
})
export class ClientSelectionPageComponent implements OnInit {

    superClients: SuperClient[] = [];
    selectedSuperClient: SuperClient;

    constructor(
        private userService: UserService,
        private router: Router) {
    }

    ngOnInit(): void {
        this.userService.superClientContextSubject.subscribe(currentContext => {
            if (currentContext) {
                this.selectedSuperClient = currentContext;
                this.superClients = this.userService.getUserSuperClients();
                if (this.superClients.length === 1) {
                    this.router.navigate(['/formulary/formularySearch']);
                }
                this.superClients.forEach((client) => {
                    if (client.clientName === '*ALL') {
                        client.clientName = 'CVS Standard Template';
                    }
                });
            }
        });
    }

    updateSuperClientContext(event) {
        CacheService.instance().clearCache();
        this.userService.setSuperClientContext(event.value);
        (this.router.routeReuseStrategy as ICLRRouteReuseStrategy).flush();
    }

}
