import {Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {MatTabChangeEvent} from '@angular/material/tabs';
import {SetFilterModule} from '@ag-grid-enterprise/set-filter';
import {ColumnsToolPanelModule} from '@ag-grid-enterprise/column-tool-panel';
import {ClientSideRowModelModule} from '@ag-grid-community/client-side-row-model';
import {ClipboardModule} from '@ag-grid-enterprise/clipboard';
import {MenuModule} from '@ag-grid-enterprise/menu';
import {ColDef, GridOptions} from '@ag-grid-community/core';
import {KabobCellComponent} from '../../common/kabob-cell/kabob-cell.component';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {
    CVSConfirmationDialogContentComponent,
    CVSSnackBarData,
    CVSSnackBarService,
    PDSSelectionOption
} from 'angular-component-library';
import {take} from 'rxjs/operators';
import {AppService} from '../../service/app/app.service';
import {ConfigurationService} from '../../service/configuration/configuration.service';
import {ClientUmProgram} from '../../model/ClientUmProgram';
import {dateFormatter} from '../../utility/utility';
import {ClientProductDrugCoverage} from '../../model/ClientProductDrugCoverage';
import {PRODUCT_DRUG_COVERAGE_TYPE, UM_PROGRAM_TYPE} from '../../default-values/DefaultValues';
import {productAndDrugCoverageContextMenuItems, umProgramContextMenuItems} from './context-menu-items';
import {MoveRankData} from '../../model/MoveRankData';
import {CLIENT_CONFIGURATION_FOR_RANK_CHANGE} from '../../enum/ClientConfigurationForRankChange';
import {Subscription} from 'rxjs';
import {KabobMenuActionTitlesEnum} from './KabobMenuActionTitlesEnum';
import {FormularyService} from '../../service/formulary/formulary.service';
import {ClientFormularyMapping} from '../../model/ClientFormularyMapping';

@Component({
    selector: 'app-product-drug-requirements',
    templateUrl: './product-drug-requirements.component.html',
    styleUrls: ['./product-drug-requirements.component.scss']
})
export class ProductDrugRequirementsComponent implements OnInit, OnDestroy{
    @ViewChild('productDrugCoverageFormRef') public productDrugCoverageFormRef: TemplateRef<any>;
    @ViewChild('umProgramFormRef') public umProgramFormRef: TemplateRef<any>;
    @Input() currentStepFromParent;
    @Input() selectedTab;
    @Input() isUpdated;
    @Input() lobLastToggled: string;
    @ViewChild('moveRankModalRef') public moveRankModalRef: TemplateRef<any>;
    currentFormularies: ClientFormularyMapping[] = [];
    formularyDescriptionForDD: PDSSelectionOption[] = [];
    formulariesAndBusinessLobMapping: Map<string, ClientFormularyMapping[]> = new Map();
    subscriptions: Subscription[] = [];
    umProgramState: ClientUmProgram;
    businessLobCode;
    productDrugCoverageState;
    moveRankData: MoveRankData;
    isAnUpdate = false;
    confirmationDialog: MatDialogRef<CVSConfirmationDialogContentComponent, any>;
    selectedComponentName: string;
    selectedIndex: number;
    index: number;
    currentRankIndex: number;
    ranks: number[];
    moveRankOptions: number[];
    productDrugCoverageRowData: ClientProductDrugCoverage[];
    clientUmProgramRowData: ClientUmProgram[];
    coverageTypes = PRODUCT_DRUG_COVERAGE_TYPE;
    programTypes = UM_PROGRAM_TYPE;
    productAndDrugLoadingSpinner = true;
    umLoadingSpinner = true;
    deleteDialog: MatDialogRef<CVSConfirmationDialogContentComponent, any>;
    modules = [SetFilterModule, ColumnsToolPanelModule, ClientSideRowModelModule,
        MenuModule, ClipboardModule];
    defaultColDef: ColDef = {
        sortable: true,
        resizable: true,
    };
    public context = {this: this};
    umProgramGridOptions: GridOptions = {
        defaultColDef: this.defaultColDef,
        columnDefs: [
            {headerName: 'Action', field: 'kabob', cellRenderer: KabobCellComponent, width: 100,
                onCellClicked: (params) => this.onUmProgramKabobCellClicked(params)
            },
            {headerName: 'Rank', field: '', filter: 'agSetColumnFilter', width: 100,
                valueGetter: (params) => params.node?.rowIndex + 1},
            {headerName: 'UM Program Type', field: '', filter: 'agSetColumnFilter', width: 200,
                valueGetter: (params) => this.getUmProgramType(params)},
            {headerName: 'UM Program Name', field: '', filter: 'agSetColumnFilter', width: 200,
                valueGetter: (params) => params.data?.umProgramName},
            {headerName: 'UM Program Description', field: '', filter: 'agSetColumnFilter', width: 220,
                valueGetter: (params) => params.data?.umProgramDescription},
            {headerName: 'Formulary ID', field: '', filter: 'agSetColumnFilter', width: 150,
                valueGetter: (params) => params.data?.formularyId},
            {headerName: 'UM Program View Date Range', field: '', filter: 'agSetColumnFilter', width: 220,
                valueFormatter: params => dateFormatter(params.data?.umProgramViewStartDate)
                    .concat(' - ').concat(dateFormatter(params.data?.umProgramViewEndDate))},
            {headerName: 'UM Program Effective Date Range', field: '', filter: 'agSetColumnFilter', width: 220,
                valueFormatter: params => dateFormatter(params.data?.umProgramEffectiveStartDate)
                    .concat(' - ').concat(dateFormatter(params.data?.umProgramEffectiveEndDate))},
            {headerName: 'Default Custom Rejection Message', field: '', filter: 'agSetColumnFilter', width: 220,
                valueGetter: (params) => params.data?.rejectionMessage},
        ],
        enableCellTextSelection: true,
        suppressLoadingOverlay: true,

    };
    productAndDrugCoverageGridOptions: GridOptions = {
        defaultColDef: this.defaultColDef,
        columnDefs: [
            {headerName: 'Action', field: 'kabob', cellRenderer: KabobCellComponent, width: 100,
                onCellClicked: (params) => this.onProductAndDrugCoverageKabobCellClicked(params)},
            {headerName: 'Rank', field: '', filter: 'agSetColumnFilter', width: 100,
                valueGetter: (params) => params.node?.rowIndex + 1},
            {headerName: 'Product & Drug Coverage Type', field: '', filter: 'agSetColumnFilter', width: 200,
                valueGetter: (params) => this.getProductDrugCoverageType(params)},
            {headerName: 'Product & Drug Coverage Name', field: '', filter: 'agSetColumnFilter', width: 200,
                valueGetter: (params) => params.data?.productDrugCoverageName},
            {headerName: 'Product & Drug Coverage Description', field: '', filter: 'agSetColumnFilter', width: 220,
                valueGetter: (params) => params.data?.productDrugCoverageDescription},
            {headerName: 'Formulary ID', field: '', filter: 'agSetColumnFilter', width: 150,
                valueGetter: (params) => params.data?.formularyId},
            {headerName: 'Product & Drug Coverage View Date Range', field: '', filter: 'agSetColumnFilter', width: 220,
                valueFormatter: params => dateFormatter(params.data?.productDrugCoverageViewStartDate)
                    .concat(' - ').concat(dateFormatter(params.data?.productDrugCoverageViewEndDate))},
            {headerName: 'Product & Drug Coverage Effective Date Range', field: '', filter: 'agSetColumnFilter', width: 220,
                valueFormatter: params => dateFormatter(params.data?.productDrugCoverageEffectiveStartDate)
                    .concat(' - ').concat(dateFormatter(params.data?.productDrugCoverageEffectiveEndDate))},
            {headerName: 'Default Custom Rejection Message', field: '', filter: 'agSetColumnFilter', width: 220,
                valueGetter: (params) => params.data?.rejectionMessage},
        ],
        enableCellTextSelection: true,
        suppressLoadingOverlay: true
    };
    overlayNoRowsTemplate = `<span><img src="/assets/error-f--s.svg" width="25px" height="25px"></br><b>No results found.</b></span>`;
    popupParent: HTMLElement | null = document.querySelector('body');
    isButtonDisabled = false;
    noFormularyFoundTooltipText = '';
    protected readonly umProgramContextMenuItems = umProgramContextMenuItems;
    protected readonly productAndDrugCoverageContextMenuItems = productAndDrugCoverageContextMenuItems;

    constructor(
        private matDialog: MatDialog,
        private _appService: AppService,
        private _configurationService: ConfigurationService,
        private deleteModal: MatDialog,
        private snackBarService: CVSSnackBarService,
        private _formularyService: FormularyService
    ) {}

    ngOnInit(): void {
        this.selectedIndex = 0;
        this.index = this._appService.getSuperClientContext().id;
    }
    ngOnDestroy() {
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }

    onBusinessLobTabSelectionChange(event: MatTabChangeEvent) {
        this.selectedTab = event.tab.ariaLabel;
        this.businessLobCode = this.selectedTab;
        this.getClientUmProgramData(this.index, this.selectedTab);
        this.getClientProductDrugCoverageData(this.index, this.selectedTab);
        this.buildActiveFormularies();
    }

    onSecondaryTabChange(event: MatTabChangeEvent) {
        this.selectedIndex = event.index;
        if (this.selectedIndex === 0) {
            this.getClientUmProgramData(this.index, this.selectedTab);
        } else if (this.selectedIndex === 1) {
            this.getClientProductDrugCoverageData(this.index, this.selectedTab);
        }
    }

    openDialog(modalRef: TemplateRef<any>) {
        this.isAnUpdate = false;
        this.confirmationDialog = this.matDialog.open(CVSConfirmationDialogContentComponent, {
            data: {
                headline: this.selectedIndex===0 ? 'Create UM Program' : 'Create Product or Drug Coverage',
                templatePortalContent: modalRef
            },
            disableClose: true,
        });

        this.confirmationDialog.componentInstance.onCancelClick.subscribe(() => {
            this.confirmationDialog.close();
        });
    }

    onModalCancel() {
        this.getClientProductDrugCoverageData(this.index, this.selectedTab);
        this.confirmationDialog.close();
    }

    onModalSubmit() {
        if (this.selectedIndex === 0) {
            this.confirmationDialog.close();
            this.getClientUmProgramData(this.index, this.selectedTab);
        } else {
            this.confirmationDialog.close();
            this.getClientProductDrugCoverageData(this.index,this.selectedTab);
        }
    }

    getClientUmProgramData(superClientIndex: number, lineOfBusiness: string) {
        this.umProgramGridOptions.suppressNoRowsOverlay = true;
        this.umLoadingSpinner = true;
        this._configurationService.getClientUmProgram(superClientIndex, lineOfBusiness).pipe(take(1)).subscribe(data => {
            this.clientUmProgramRowData = data;
            if (this.clientUmProgramRowData === null) {
                this.umProgramGridOptions.suppressNoRowsOverlay = false;
                this.umProgramGridOptions.api?.showNoRowsOverlay();
            }
            this.umLoadingSpinner = false;

        });
    }

    getClientProductDrugCoverageData(superClientIndex: number, lineOfBusiness: string) {
        this.productAndDrugCoverageGridOptions.suppressNoRowsOverlay = true;
        this.productAndDrugLoadingSpinner = true;
        this._configurationService.getClientProductDrugCoverage(superClientIndex,lineOfBusiness).pipe(take(1)).subscribe(data => {
            this.productDrugCoverageRowData = data;
            if (this.productDrugCoverageRowData === null) {
                this.productAndDrugCoverageGridOptions.suppressNoRowsOverlay = false;
                this.productAndDrugCoverageGridOptions.api?.showNoRowsOverlay();
            }
            this.productAndDrugLoadingSpinner = false;
        });
    }

    getUmProgramType(params) {
        return this.programTypes[this.programTypes.findIndex(i => i.code === params.data.umProgramTypeCd)].description;
    }

    getProductDrugCoverageType(params) {
        return this.coverageTypes[this.coverageTypes.findIndex(i => i.code === params.data.productDrugCoverageTypeCd)].description;
    }

    showMoveRank(rank: number) {
        this.selectedComponentName = this.selectedIndex ===0 ? this.umProgramGridOptions.rowData[rank-1]?.umProgramName :
            this.productAndDrugCoverageGridOptions.rowData[rank-1]?.productDrugCoverageName;
        this.currentRankIndex = rank;
        this.ranks = this.selectedIndex ===0 ? this.umProgramGridOptions.rowData?.map((item) => item.rank) :
            this.productAndDrugCoverageGridOptions.rowData?.map((item) => item.rank);
        this.ranks.sort((a, b) => a - b);
        this.moveRankOptions = this.ranks.map((item) => this.ranks.indexOf(item)+1);
        this.moveRankOptions.splice(this.currentRankIndex-1, 1);
        this.buildMoveRankData(KabobMenuActionTitlesEnum.MOVE_RANK);
        this.confirmationDialog = this.matDialog.open(CVSConfirmationDialogContentComponent, {
            data: {
                headline: 'Move Rank',
                templatePortalContent: this.moveRankModalRef,
            },
            disableClose: true,
        });
        this.confirmationDialog.componentInstance.onCancelClick.subscribe(() => {
            this.confirmationDialog.close();
        });
    }

    buildMoveRankData( action: KabobMenuActionTitlesEnum) {
        if (this.selectedIndex === 0) {
            this.moveRankData = {
                id: this.umProgramState.id,
                businessLobCode: this.umProgramState.businessLobCode,
                newRankToBeMovedTo: this.umProgramState.rank,
                clientConfigurationPageForRankMovement: CLIENT_CONFIGURATION_FOR_RANK_CHANGE.UM_PROGRAM
            };
        } else if (this.selectedIndex === 1) {
            this.moveRankData = {
                id: this.productDrugCoverageState.id,
                businessLobCode: this.productDrugCoverageState.businessLobCode,
                newRankToBeMovedTo: this.productDrugCoverageState.rank,
                clientConfigurationPageForRankMovement: CLIENT_CONFIGURATION_FOR_RANK_CHANGE.PRODUCT_DRUG_COVERAGE
            };
        }
        if (action === KabobMenuActionTitlesEnum.MOVE_UP) {
            this.moveRankData.newRankToBeMovedTo = this.moveRankData.newRankToBeMovedTo - 1;
        } else if (action === KabobMenuActionTitlesEnum.MOVE_DOWN) {
            this.moveRankData.newRankToBeMovedTo = this.moveRankData.newRankToBeMovedTo + 1;
        }
    }

    subscribeToRankMovement() {
        this.subscriptions.push(this._configurationService.getRankMovementProvider()
            .subscribe((componentPage) => {
                if (componentPage === CLIENT_CONFIGURATION_FOR_RANK_CHANGE.PRODUCT_DRUG_COVERAGE) {
                    this.getClientProductDrugCoverageData(this.index, this.selectedTab);
                    this.productAndDrugCoverageGridOptions.api.redrawRows();
                } else if (componentPage === CLIENT_CONFIGURATION_FOR_RANK_CHANGE.UM_PROGRAM) {
                    this.getClientUmProgramData(this.index, this.selectedTab);
                    this.umProgramGridOptions.api.redrawRows();
                }
            }));
    }

    onMoveRankSubmit(event) {
        this.onModalCancel();
    }

    showDeleteUmProgramModal(){
        this.openDeleteUmProgramModal();
        this.deleteDialog.componentInstance.onConfirmClick.subscribe(() => {
            // eslint-disable-next-line max-len
            this._configurationService.deleteUmProgram(this._appService.getSuperClientContext().id, this.umProgramState).pipe(take(1)).subscribe(
                {next: () => {
                    this.snackBarService.open({
                        iconName: 'check-circle--s',
                        message: 'UM Program deleted successfully',
                        duration: 5,
                        verticalPosition: 'bottom',
                        horizontalPosition: 'center'
                    } as CVSSnackBarData);
                    this.getClientUmProgramData(this.index, this.businessLobCode);
                }}
            );
        });
    }

    showDeleteProductDrugCoverageModal(){
        this.openDeleteProductDrugCoverageModal();
        this.deleteDialog.componentInstance.onConfirmClick.subscribe(() => {
            // eslint-disable-next-line max-len
            this._configurationService.deleteProductDrugCoverage(this._appService.getSuperClientContext().id, this.productDrugCoverageState.id).pipe(take(1)).subscribe(
                {next: () => {
                    this.snackBarService.open({
                        iconName: 'check-circle--s',
                        message: 'Product or Drug Coverage deleted successfully',
                        duration: 5,
                        verticalPosition: 'bottom',
                        horizontalPosition: 'center'
                    } as CVSSnackBarData);
                    this.getClientProductDrugCoverageData(this.index, this.businessLobCode);
                }}
            );
        });
    }
    openDeleteUmProgramModal() {
        this.deleteDialog =  this.deleteModal.open(CVSConfirmationDialogContentComponent, {
            data: {
                headline: 'Delete this UM Program?',
                body: 'All preferences related to this UM Program will be permanently deleted.<br><br>Warning: This cannot be undone',
                actionLabel: 'Delete',
                cancelLabel: 'Cancel',
            },disableClose: true,
        });

    }

    openDeleteProductDrugCoverageModal() {
        this.deleteDialog =  this.deleteModal.open(CVSConfirmationDialogContentComponent, {
            data: {
                headline: 'Delete this Product & Drug Coverage Requirement?',
                body: 'All preferences related to this Product or Drug Coverage will be permanently deleted.<br><br>' +
                    'Warning: This cannot be undone',
                actionLabel: 'Delete',
                cancelLabel: 'Cancel',
            },disableClose: true,
        });

    }

    buildUmProgramState(paramsData: ClientUmProgram){
        this.umProgramState = {
            businessLobCode: this.businessLobCode,
            id: paramsData.id,
            rank: paramsData.rank,
            superclientId: paramsData.superclientId,
            umProgramDescription: paramsData.umProgramDescription,
            umProgramViewEndDate: paramsData.umProgramViewEndDate,
            umProgramViewStartDate: paramsData.umProgramViewStartDate,
            umProgramEffectiveEndDate: paramsData.umProgramEffectiveEndDate,
            umProgramEffectiveStartDate:paramsData.umProgramEffectiveStartDate,
            umProgramName: paramsData.umProgramName,
            umProgramTypeCd: paramsData.umProgramTypeCd,
            rejectionMessage: paramsData.rejectionMessage,
            formularyId: paramsData.formularyId,
            formularyDescription: this.getFormularyWithDescription(paramsData.formularyId)
        };
    }

    buildProductDrugCoverageState(paramsData: ClientProductDrugCoverage){
        this.productDrugCoverageState = {
            businessLobCode: this.businessLobCode,
            id: paramsData.id,
            rank: paramsData.rank,
            superclientId: paramsData.superclientId,
            productDrugCoverageDescription: paramsData.productDrugCoverageDescription,
            productDrugCoverageViewEndDate: paramsData.productDrugCoverageViewEndDate,
            productDrugCoverageViewStartDate: paramsData.productDrugCoverageViewStartDate,
            productDrugCoverageEffectiveEndDate: paramsData.productDrugCoverageEffectiveEndDate,
            productDrugCoverageEffectiveStartDate:paramsData.productDrugCoverageEffectiveStartDate,
            productDrugCoverageName: paramsData.productDrugCoverageName,
            productDrugCoverageTypeCd: paramsData.productDrugCoverageTypeCd,
            rejectionMessage: paramsData.rejectionMessage,
            formularyId: paramsData.formularyId,
            formularyDescription: this.getFormularyWithDescription(paramsData.formularyId)
        };
    }

    openUpdateUmProgramModal(modalRef: TemplateRef<any>) {
        this.isAnUpdate = true;
        this.confirmationDialog = this.matDialog.open(CVSConfirmationDialogContentComponent, {
            data: {
                headline: 'Update UM Program',
                templatePortalContent: modalRef,
            },
            disableClose: true,
        });
        this.confirmationDialog.componentInstance.onCancelClick.subscribe(() => {
            this.isAnUpdate = false;
            this.confirmationDialog.close();
        });
    }

    openUpdateProductDrugCoverageModal(modalRef: TemplateRef<any>) {
        this.isAnUpdate = true;
        this.confirmationDialog = this.matDialog.open(CVSConfirmationDialogContentComponent, {
            data: {
                headline: 'Update Product or Drug Coverage',
                templatePortalContent: modalRef,
            },
            disableClose: true,
        });
        this.confirmationDialog.componentInstance.onCancelClick.subscribe(() => {
            this.isAnUpdate = false;
            this.confirmationDialog.close();
        });
    }

    onUmProgramKabobCellClicked(params) {
        this.subscribeToRankMovement();
        this.umProgramState = undefined;
        if(params.data.hasOwnProperty('umProgramName')){
            this.buildUmProgramState(params.data);
        }
        // @ts-ignore
        if (params.column.colDef.field === 'kabob') {
            // @ts-ignore
            params.api.contextMenuFactory.showMenu(params.node, params.column, params.value, params.event);
        }
    }

    onProductAndDrugCoverageKabobCellClicked(params) {
        this.subscribeToRankMovement();
        this.productDrugCoverageState = [];
        if(params.data.hasOwnProperty('productDrugCoverageName')){
            this.buildProductDrugCoverageState(params.data);
        }
        // @ts-ignore
        if (params.column.colDef.field === 'kabob') {
            // @ts-ignore
            params.api.contextMenuFactory.showMenu(params.node, params.column, params.value, params.event);
        }
    }
    moveRankUp(){
        this.buildMoveRankData(KabobMenuActionTitlesEnum.MOVE_UP);
        // eslint-disable-next-line max-len
        this._configurationService.moveRankClientConfiguration(this._appService.getSuperClientContext().id,this.moveRankData)
            .pipe(take(1)).subscribe(() => {
                this.snackBarService.open({
                    iconName: 'check-circle--s',
                    message: 'Rank moved successfully',
                    duration: 5,
                    verticalPosition: 'bottom',
                    horizontalPosition: 'center'
                } as CVSSnackBarData);
                if (this.moveRankData.clientConfigurationPageForRankMovement ===
                CLIENT_CONFIGURATION_FOR_RANK_CHANGE.PRODUCT_DRUG_COVERAGE) {
                    this._configurationService.rankMovementProvider.next(CLIENT_CONFIGURATION_FOR_RANK_CHANGE.PRODUCT_DRUG_COVERAGE);
                } else if (this.moveRankData.clientConfigurationPageForRankMovement ===
                CLIENT_CONFIGURATION_FOR_RANK_CHANGE.UM_PROGRAM) {
                    this._configurationService.rankMovementProvider.next(CLIENT_CONFIGURATION_FOR_RANK_CHANGE.UM_PROGRAM);
                }
            });
    }
    moveRankDown(){
        this.buildMoveRankData(KabobMenuActionTitlesEnum.MOVE_DOWN);
        // eslint-disable-next-line max-len
        this._configurationService.moveRankClientConfiguration(this._appService.getSuperClientContext().id,this.moveRankData)
            .pipe(take(1)).subscribe(() => {
                this.snackBarService.open({
                    iconName: 'check-circle--s',
                    message: 'Rank moved successfully',
                    duration: 5,
                    verticalPosition: 'bottom',
                    horizontalPosition: 'center'
                } as CVSSnackBarData);
                if (this.moveRankData.clientConfigurationPageForRankMovement ===
                CLIENT_CONFIGURATION_FOR_RANK_CHANGE.PRODUCT_DRUG_COVERAGE) {
                    this._configurationService.rankMovementProvider.next(CLIENT_CONFIGURATION_FOR_RANK_CHANGE.PRODUCT_DRUG_COVERAGE);
                } else if (this.moveRankData.clientConfigurationPageForRankMovement ===
                CLIENT_CONFIGURATION_FOR_RANK_CHANGE.UM_PROGRAM) {
                    this._configurationService.rankMovementProvider.next(CLIENT_CONFIGURATION_FOR_RANK_CHANGE.UM_PROGRAM);
                }
            });
    }

    buildActiveFormularies() {
        this.currentFormularies = [];
        this.formularyDescriptionForDD = [];
        let formularies: ClientFormularyMapping[] = [];
        this._formularyService.getActiveClientFormularyMappingForAllLOB(this.index).pipe(take(1)).subscribe(formularyResponse => {
            this.formulariesAndBusinessLobMapping = formularyResponse;
            if(this.formulariesAndBusinessLobMapping) {
                formularies = this.getCurrentFormularies();
                this.currentFormularies = formularies.map(formulary => ({
                    ...formulary,
                    formularyDescription: `${formulary.formularyId} - ${formulary.formularyName}`
                }));
                this.currentFormularies.map( m => {
                    const formularyData: PDSSelectionOption = {displayText : m.formularyDescription, value: m.formularyDescription};
                    this.formularyDescriptionForDD.push(formularyData);
                });
            }
            this.setIsButtonDisabled();
        });
    }

    getCurrentFormularies() {
        return Object.keys(this.formulariesAndBusinessLobMapping)
            .includes(this.businessLobCode) ? this.formulariesAndBusinessLobMapping[this.businessLobCode] : [];
    }

    getFormularyDescription(formularyId: string) {
        const filteredFormulary = this.currentFormularies?.find(formulary => formulary.formularyId === formularyId);
        if (filteredFormulary) {
            return filteredFormulary.formularyDescription;
        }
        if ( this.formularyDescriptionForDD.findIndex( fdd => fdd.value.includes(formularyId)) === -1) {
            const formularyData: PDSSelectionOption = {displayText: `${formularyId} -`, value: `${formularyId} -`};
            this.formularyDescriptionForDD.push(formularyData);
        }
        return `${formularyId} -`;
    }

    getFormularyWithDescription(formularyIds: string) {
        const selectedFormularyDescription = [];
        if (formularyIds !== undefined && formularyIds !== null && formularyIds !== '' ) {
            const formularyIdList = formularyIds.split(',');
            if (formularyIdList && formularyIdList.length > 0) {
                formularyIdList.map( f => {
                    selectedFormularyDescription.push(this.getFormularyDescription(f.trim()));
                });
            }
        }
        return selectedFormularyDescription;
    }

    setIsButtonDisabled(){
        if (!(this.currentFormularies && this.currentFormularies.length > 0)) {
            this.isButtonDisabled = true;
            this.noFormularyFoundTooltipText = 'No formulary found';
        } else {
            this.isButtonDisabled = false;
            this.noFormularyFoundTooltipText = '';
        }
    }
}
