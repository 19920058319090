import {
    AfterViewInit,
    Component, EventEmitter,
    Input,
    OnInit, Output, QueryList,
    ViewChild,
    ViewChildren
} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup, UntypedFormGroup, Validators} from '@angular/forms';
import {
    ATTACHMENT_LEVEL, ATTACHMENT_LEVEL_CATEGORIES,
    ATTACHMENT_MONY, BUSINESS_LOB_DESC_CD, COVERAGE_TYPES, MED_D, RX_OTC
} from '../../../../default-values/DefaultValues';
import {BUSINESS_LOB} from '../../../../enum/BusinessLob';
import {MedispanTrackerResponse} from '../../../../model/MedispanTrackerResponse';
import {MatSelect} from '@angular/material/select';
import { MatAccordion } from '@angular/material/expansion';
import {MatCheckbox} from '@angular/material/checkbox';
import {RecommendationService} from '../../../../service/recommendation/recommendation.service';
import {Subscription} from 'rxjs';
import {
    IRecommendationRules,
} from '../RecommendationFormRules';
import {Formulary} from '../../../../model/Formulary';
import {CVSBannerService, CVSBannerType} from 'angular-component-library';
import {FormularyRequirements} from '../../../../model/FormularyRequirements';
import {map, take, tap} from 'rxjs/operators';
import {AppService} from '../../../../service/app/app.service';
import {ConfigurationService} from '../../../../service/configuration/configuration.service';
import {ADHOC_UPDATE_TYPES} from '../../../../service/tracker-data/tracker-data.service';

export interface ClientFormularyRequirementRuleCoverageMapping {
    id?: number;
    formularyRequirementName: string;
    formularyDescription: string;
    formularyCoverageCategoryCd: number;
    formularyNumber: string;
    codingTypeSelected: string;
}
@Component({
    selector: 'app-formulary-form-entry',
    templateUrl: './formulary-form-entry.component.html',
    styleUrls: ['./formulary-form-entry.component.scss'],
})
export class FormularyFormEntryComponent implements OnInit, AfterViewInit {
    @ViewChildren('attachmentMony') attachmentMony: QueryList<MatSelect>;
    @ViewChildren('attachmentMonyCheckbox') attachmentMonyCheckbox: QueryList<MatCheckbox>;
    @ViewChildren('checkbox') checkbox: QueryList<MatCheckbox>;
    @ViewChild(MatAccordion) accordion: MatAccordion;
    @Input() medispanTrackerResponse: MedispanTrackerResponse;
    @Input() formularyNumber: any;
    @Output() isFormularyFormSaved: EventEmitter<boolean> = new EventEmitter(false);
    formularies: Formulary[] = [];
    attachmentMonyList = ATTACHMENT_MONY;
    attachmentLevels: [key: string, value: string][] = [];
    attachmentTypesKey: [key: string, value: string[]][] = [];
    attachmentLevelsKey: {[key: string]: [key: string, value: string][]} = {};
    rxOtcList = RX_OTC;
    medDList = MED_D;
    monyIndeterminates= new Map<string, boolean>();
    rules: IRecommendationRules;
    recommendationStatus;
    panelOpenState = true;
    formularyRequirementRuleCoverages: ClientFormularyRequirementRuleCoverageMapping[] = [];
    mappedFormularyRuleCoverages: {[key: string]: ClientFormularyRequirementRuleCoverageMapping[]} = {};
    isLoading = true;
    coverageTypes = COVERAGE_TYPES;
    formularyForm: FormGroup;
    savedFormularyRequirements: FormularyRequirements[] = [];
    blob;
    mappedStatus = new Map<string, string>();
    isUpdateTypeGPIADHOC = false;
    isUpdateTypeNDCTOGPIADHOC= false;
    isUpdateTypeNDCADHOC= false;
    isUpdateTypeGPITONDCADHOC= false;
    private subscriptions: Subscription[] = [];

    // eslint-disable-next-line @typescript-eslint/member-ordering
    protected readonly BUSINESS_LOB = BUSINESS_LOB;

    constructor(private _recommendationService: RecommendationService, private fb: FormBuilder,
        private bannerService: CVSBannerService, private configurationService: ConfigurationService,
        private _appService: AppService) {
        this.formularyForm = this.fb.group({
            formularyArray: this.fb.array([]) ,
        });
    }

    get formularyRequirement(): FormArray {
        return this.formularyForm.get('formularyArray') as FormArray;
    }

    get formularyFormArray(): FormArray {
        return this.formularyForm.controls.formularyArray as FormArray;
    }

    get getFormularyFormGroup(): FormGroup[] {
        return this.formularyFormArray.controls as FormGroup[];
    }

    ngOnInit() {
        this.blob = this.medispanTrackerResponse?.businessLob;
        this.computeUpdateTypes();
        this.formularies = this._recommendationService.getActiveFormulariesByLOBTab(this.medispanTrackerResponse?.businessLobCd);
        this.recommendationStatus = '0';
        this.createFormGroups();
        const formulary = this.formularyFormArray.controls as UntypedFormGroup[];
        for (let i = 0; i < formulary.length; i++) {
            const subscription = formulary[i].controls.attachmentType?.valueChanges.subscribe((value) => {
                this.buildAttachmentLevels(value, i);
            });
            if (subscription) {
                this.subscriptions.push(subscription);
            }
        }
        this.getActiveClientFormularyRequirementRules();

    }

    public getActiveClientFormularyRequirementRules() {
        this.configurationService.getActiveClientFormularyRequirementRules(this._appService.getSuperClientContext().id,
            // eslint-disable-next-line max-len
            this.medispanTrackerResponse.businessLobCd)
            .pipe(
                map(data => data.map(d => ({
                    id: d.id,
                    formularyRequirementName: d.formularyRequirementName,
                    formularyDescription: d.formularyDescription,
                    formularyCoverageCategoryCd: d.formularyCoverageCategoryCd,
                    formularyNumber: d.formularyId,
                    codingTypeSelected: d.codingSetupCd
                }))),
                tap(formularyRequirements => {
                    this.formularyRequirementRuleCoverages.push(...formularyRequirements);
                })
            )
            .subscribe(data => {
                this.isLoading = false;
                this.getSavedFormularyRequirement();
            });
    }

    ngAfterViewInit() {
        this.scrollToFormulary(`formulary-${this.formularyNumber}`);
    }

    scrollToFormulary(id: string) {
        const element = document.getElementById(id);
        element?.scrollIntoView();
    }

    getSavedFormularyRequirement() {
        this._recommendationService.getFormularyRequirements(this.medispanTrackerResponse.encryptedRecordId,
            this.medispanTrackerResponse.businessLobCd)
            .pipe(
                map(data => data?.map(d => ({
                    encryptedRecordId: d.encryptedRecordId,
                    formularyId: d.formularyId,
                    businessLobCd: d.businessLobCd,
                    statusCd: d.statusCd,
                    attachmentType: d.attachmentType,
                    attachmentLevel: d.attachmentLevel,
                    attachmentMony: d.attachmentMony,
                    attachmentRxOtcCd: d.attachmentRxOtcCd,
                    attachmentMedDCd: d.attachmentMedDCd,
                    clientFormularyReqRuleId: d.clientFormularyReqRuleId,
                    negativeChange: d.negativeChange,
                    rejectionMessage: d.rejectionMessage
                }))),
                tap(formularyRequirements => {
                    this.savedFormularyRequirements.push(...formularyRequirements);
                })
            )
            .subscribe({
                next: () => {
                    this.loadSavedFormularyRequirements();
                },
                error: () => {},
            });
    }

    createFormGroups(): void {
        for (let i = 0; i < this.formularies.length; i++) {
            this.formularyRequirement.push(this.fb.group({
                attachmentType: [{value: null, disabled: true}, [Validators.required]],
                attachmentMonyDD: [{value: '', disabled: true}, [Validators.required]],
                monyCode: [{value: 'N/A', disabled: true}],
                attachmentLevel: [{value: '', disabled: true}, [Validators.required]],
                formularyCoverageId: [0],
                formularyReqDesc: [{value: 'N/A', disabled: true}],
                formularyReqCategory: [{value: 'N/A', disabled: true}],
                rejectionMessaging: [{value: 'N/A', disabled: true}],
                coverageMiscRxOtc: [{value: '3', disabled: true}],
                coverageMiscMedD: [{value: '3', disabled: true}],
                negativeChange: [{value: null, disabled: true}],
                formularyNumber: [this.formularies[i].formularyNumber],
                attachmentMonyCheckbox: [{value: false, disabled: true}],
            }));
            this.mappedStatus[this.formularies[i].formularyNumber] = '0';
        }
    }

    loadSavedFormularyRequirements() {
        const formularyFormGroup = this.formularyFormArray.controls as UntypedFormGroup[];
        let mony: string[] = [];
        formularyFormGroup.forEach((formArrayFormGroup, index) => {
            const formularyRequirements = this.savedFormularyRequirements
                .filter((f) => f.formularyId === formArrayFormGroup.value.formularyNumber);
            if (formularyRequirements[0] !== undefined) {
                // eslint-disable-next-line guard-for-in
                for (const controlsKey in formArrayFormGroup.controls) {
                    if(controlsKey !== 'formularyReqDesc' && controlsKey !== 'formularyReqCategory' && controlsKey !== 'monyCode') {
                        formArrayFormGroup.get(controlsKey).enable();
                    }
                }

                formArrayFormGroup.controls.attachmentType.patchValue(formularyRequirements[0].attachmentType, {emitEvent: false});
                this.buildAttachmentLevels(formularyRequirements[0].attachmentType, formularyFormGroup.
                    findIndex(i=> i.value.formularyNumber === formularyRequirements[0].formularyId));
                if (formularyRequirements[0].attachmentMony) {
                    mony = formularyRequirements[0].attachmentMony.split('');
                }
                formArrayFormGroup.controls.attachmentMonyDD.patchValue([...mony.map(m => m)],
                    {emitEvent: false});
                formArrayFormGroup.controls.monyCode.patchValue('N/A', {emitEvent: false});
                formArrayFormGroup.controls.attachmentLevel.patchValue(formularyRequirements[0].attachmentLevel, {emitEvent: false});
                this.onAttachmentMonyDDChange(index);
                if (!this.isLoading) {
                    this.formularyRequirementRuleCoverages.forEach(f => {
                        if (f.formularyNumber === formularyRequirements[0].formularyId
                            && f.id === Number(formularyRequirements[0].clientFormularyReqRuleId)) {
                            formArrayFormGroup.controls.formularyReqDesc.patchValue(f.formularyDescription, {emitEvent: false});
                            // eslint-disable-next-line max-len
                            formArrayFormGroup.controls.formularyReqCategory.patchValue(this.getFormularyCoverageCategory(f), {emitEvent: false});
                            formArrayFormGroup.controls.formularyCoverageId.patchValue(f.id, {emitEvent: false});
                            if(formArrayFormGroup.controls.formularyCoverageId.value === f.id) {
                                this.buildAttachmentType(index.toString(), f.codingTypeSelected);
                            }
                        }
                    });
                }
                formArrayFormGroup.controls.rejectionMessaging.patchValue(formularyRequirements[0].rejectionMessage, {emitEvent: false});
                formArrayFormGroup.controls.coverageMiscRxOtc.patchValue(formularyRequirements[0].attachmentRxOtcCd, {emitEvent: false});
                formArrayFormGroup.controls.coverageMiscMedD.patchValue(formularyRequirements[0].attachmentMedDCd, {emitEvent: false});
                if (formularyRequirements[0].negativeChange==='Yes') {
                    formArrayFormGroup.controls.negativeChange.patchValue(true, {emitEvent: false});
                } else {
                    formArrayFormGroup.controls.negativeChange.patchValue(false, {emitEvent: false});
                }
                this.mappedStatus[formularyRequirements[0].formularyId] = formularyRequirements[0].statusCd;
            }

        });
    }

    enableFormGroup(index: number, enable: boolean) {
        const formArrayFormGroup = (<FormArray>this.formularyForm
            .get('formularyArray'))?.controls[index] as FormGroup;
        for (const controlsKey in formArrayFormGroup.controls) {
            if(controlsKey !== 'formularyReqDesc' && controlsKey !== 'formularyReqCategory'
                && controlsKey !== 'monyCode' && enable) {
                formArrayFormGroup.get(controlsKey).enable();
                if(controlsKey === 'attachmentMonyDD' && formArrayFormGroup.get('attachmentType').value === 'NDC' ) {
                    formArrayFormGroup.get('attachmentMonyDD').disable();
                    formArrayFormGroup.get('attachmentMonyDD').patchValue('', {emitEvent: false});
                } else if (controlsKey === 'attachmentMonyDD' && formArrayFormGroup.get('attachmentType').value === 'GPI') {
                    formArrayFormGroup.get('attachmentMonyDD').enable();
                    formArrayFormGroup.get('attachmentMonyDD').patchValue('', {emitEvent: false});
                }
            } else if(controlsKey !== 'formularyCoverageId' && !enable) {
                formArrayFormGroup.get(controlsKey).disable();
                formArrayFormGroup.get('attachmentType').setValue(null);
                formArrayFormGroup.get('negativeChange').setValue(null);
                formArrayFormGroup.get('attachmentLevel').patchValue('', {emitEvent: false});
            }
        }
    }

    getMappedFormularyRuleCoverages(formularyNumber: string): ClientFormularyRequirementRuleCoverageMapping[]{
        const coverages: ClientFormularyRequirementRuleCoverageMapping[]  = [{
            id: 0,
            formularyRequirementName: 'No Change',
            formularyDescription: 'N/A',
            formularyCoverageCategoryCd: null,
            formularyNumber: null,
            codingTypeSelected: null
        }];
        if(this.mappedFormularyRuleCoverages[formularyNumber]) {
            return this.mappedFormularyRuleCoverages[formularyNumber];
        }
        this.formularyRequirementRuleCoverages?.filter(data => {
            if(data.formularyNumber === formularyNumber && !(data.codingTypeSelected === 'STD_NDC_CD'
                && (this.isUpdateTypeGPIADHOC || this.isUpdateTypeNDCTOGPIADHOC))) {
                coverages.push(data);
            }
        });

        this.mappedFormularyRuleCoverages[formularyNumber] = coverages;
        return coverages;
    }

    formularyCoverageSelected(event: any, index: number) {
        const formularyFormControl = this.getFormularyFormGroup[index]?.controls;
        const category = this.getFormularyCoverageCategory(event);

        if(event.formularyRequirementName === 'No Change') {
            this.enableFormGroup(index, false);
        } else {
            this.enableFormGroup(index, true);
        }
        this.validateFormularyForm(index, event.codingTypeSelected);

        formularyFormControl.formularyReqCategory.setValue(category);
        formularyFormControl.formularyReqDesc.setValue(event.formularyDescription);
        formularyFormControl.formularyCoverageId.setValue(event.id);
        if(this.isUpdateTypeGPIADHOC || this.isUpdateTypeNDCTOGPIADHOC
            ||event.codingTypeSelected === 'GPI_NO_RESTRICT_NDC_CD') {
            this.attachmentTypesKey[index.toString()] = [ATTACHMENT_LEVEL.GPI];
        }
    }

    getFormularyCoverageCategory(data: any) {
        return data === null || data.formularyCoverageCategoryCd === null ? 'N/A' :
            this.coverageTypes[this.coverageTypes.findIndex(i => i.code === data.formularyCoverageCategoryCd)].description;
    }

    buildAttachmentLevels(value: any, index: number) {
        const formularyFormGroup = this.formularyFormArray.controls as UntypedFormGroup[];
        this.attachmentLevels = [];
        if (value === ATTACHMENT_LEVEL.NDC) {
            const attachmentLevelsCategories = [
                ATTACHMENT_LEVEL_CATEGORIES.NDC_11,
                ATTACHMENT_LEVEL_CATEGORIES.NDC_9,
                ATTACHMENT_LEVEL_CATEGORIES.NDC_5
            ];

            let attachLevel = ATTACHMENT_LEVEL_CATEGORIES.NDC_11;
            if (formularyFormGroup[index].controls.attachmentLevel.value) {
                // eslint-disable-next-line max-len
                attachLevel = attachmentLevelsCategories.find(lvl => +lvl.split('_')[1] === formularyFormGroup[index].controls.attachmentLevel.value?.toString().length);
            }
            this.createdAttachmentLevels(attachmentLevelsCategories, attachLevel, 'ndc', index);
            formularyFormGroup[index]?.controls.attachmentMonyDD.setValue(['N/A'], {emitEvent: false});
            formularyFormGroup[index].controls.attachmentMonyDD.disable();
        } else if (value === ATTACHMENT_LEVEL.GPI) {
            const attachmentLevelsCategories = [
                ATTACHMENT_LEVEL_CATEGORIES.GPI_14,
                ATTACHMENT_LEVEL_CATEGORIES.GPI_12,
                ATTACHMENT_LEVEL_CATEGORIES.GPI_10,
                ATTACHMENT_LEVEL_CATEGORIES.GPI_8
            ];

            let attachLevel = ATTACHMENT_LEVEL_CATEGORIES.GPI_14;
            if (formularyFormGroup[index].controls.attachmentLevel.value) {
                // eslint-disable-next-line max-len
                attachLevel = attachmentLevelsCategories.find(lvl => +lvl.split('_')[1] === formularyFormGroup[index].controls.attachmentLevel.value?.toString().length);
            }
            this.createdAttachmentLevels(attachmentLevelsCategories, attachLevel, 'gpi', index);
            formularyFormGroup[index].controls.attachmentMonyDD.enable();
        }
    }

    createdAttachmentLevels(
        attachmentLevelCategories: ATTACHMENT_LEVEL_CATEGORIES[],
        attachmentLevelCategory: ATTACHMENT_LEVEL_CATEGORIES,
        attachmentType: string,
        index: number
    ) {
        const formularyFormGroup = this.formularyFormArray.controls as UntypedFormGroup[];
        const gpiOrNdcValue = this.medispanTrackerResponse?.[attachmentType];
        this.attachmentLevels = [];
        if (gpiOrNdcValue !== 'N/A') {
            attachmentLevelCategories.forEach(key => {
                this.attachmentLevels.push([key, gpiOrNdcValue?.substring(0, +key.split('_')[1])]);
            });
            this.attachmentLevelsKey[index] = this.attachmentLevels;
            const savedValue = this.attachmentLevels.find(lvl => lvl[0] === attachmentLevelCategory);

            formularyFormGroup[index].controls.attachmentLevel.patchValue(
                savedValue ? savedValue[1] : this.attachmentLevels[0][1],
                { emitEvent: false });
        }
    }

    toggleAllSelection(id: number) {
        const formularyFormGroup = this.getFormularyFormGroup;
        if (formularyFormGroup[id].controls.attachmentMonyCheckbox?.value) {
            formularyFormGroup[id].controls.attachmentMonyDD.patchValue([...this.attachmentMonyList.map(m => m.code)],
                {emitEvent: false});
        } else {
            formularyFormGroup[id].controls.attachmentMonyDD.patchValue([], {emitEvent: false});
        }
    }

    onAttachmentMonyDDChange(id: number) {
        const formularyFormGroup = this.getFormularyFormGroup;
        const attachmentMonyDDFCLength = formularyFormGroup[id].controls.attachmentMonyDD.value.length;
        this.monyIndeterminates[id] =
            (attachmentMonyDDFCLength > 0 && attachmentMonyDDFCLength < this.attachmentMonyList.length);
        if ((!formularyFormGroup[id].controls.attachmentMonyCheckbox &&
                attachmentMonyDDFCLength === this.attachmentMonyList.length) ||
            (formularyFormGroup[id].controls.attachmentMonyCheckbox &&
                attachmentMonyDDFCLength < this.attachmentMonyList.length)) {
            formularyFormGroup[id].controls.attachmentMonyCheckbox.setValue(false);
        }
        if (attachmentMonyDDFCLength === this.attachmentMonyList.length) {
            formularyFormGroup[id].controls.attachmentMonyCheckbox.setValue(true);
        }
    }

    saveFormularyRequirements() {
        const formularyRequirements = this.buildFormularyRequirements();
        if (formularyRequirements.length > 0) {
            this._recommendationService.saveFormularyRequirements(formularyRequirements)
                .pipe(take(1))
                .subscribe({
                    next: () => this.onSaveSuccess(formularyRequirements),
                    error: () => this.onSaveError()
                });
        }
    }

    buildFormularyRequirements(): FormularyRequirements[]{
        const formularyRequirements: FormularyRequirements[] = [];
        for (let i = 0; i < this.formularyForm.value['formularyArray'].length; i++) {
            this.markMandatoryFieldsAsTouched(i);
            const formularyArray = this.formularyForm.value['formularyArray'][i];
            const formularyRequirement: FormularyRequirements = {
                encryptedRecordId: null,
                formularyId: null,
                businessLobCd: null,
                statusCd: null,
                attachmentType: null,
                attachmentLevel: null,
                attachmentMony: null,
                attachmentRxOtcCd: null,
                attachmentMedDCd: null,
                clientFormularyReqRuleId: null,
                negativeChange: null,
                rejectionMessage: null
            };
            formularyRequirement.encryptedRecordId = this.medispanTrackerResponse.encryptedRecordId;
            formularyRequirement.formularyId = this.formularies[i].formularyNumber;
            formularyRequirement.businessLobCd = this.medispanTrackerResponse.businessLobCd;
            if (formularyArray['formularyCoverageId'] === 0 ) {
                formularyRequirement.clientFormularyReqRuleId = null;
                formularyRequirement.attachmentType = null;
                formularyRequirement.attachmentLevel = null;
                formularyRequirement.attachmentMony = null;
                formularyRequirement.attachmentRxOtcCd = null;
                formularyRequirement.attachmentMedDCd = null;
                formularyRequirement.clientFormularyReqRuleId = null;
                formularyRequirement.negativeChange = null;
                formularyRequirement.rejectionMessage = null;
                formularyRequirement.statusCd = '0';
            } else {
                formularyRequirement.attachmentType = formularyArray['attachmentType'];
                formularyRequirement.attachmentLevel = formularyArray['attachmentLevel'];
                formularyRequirement.attachmentMony = (formularyArray['attachmentMonyDD'] !== null
                    && formularyArray['attachmentMonyDD'] !== '')
                    ? formularyArray['attachmentMonyDD']?.join('') === 'N/A' ? null
                        : formularyArray['attachmentType'] === ATTACHMENT_LEVEL.GPI ?
                            formularyArray['attachmentMonyDD']?.join('') : null : null;
                formularyRequirement.attachmentRxOtcCd = formularyArray['coverageMiscRxOtc'];
                // eslint-disable-next-line max-len
                formularyRequirement.attachmentMedDCd = formularyRequirement.businessLobCd !== 'BM' ? null : formularyArray['coverageMiscMedD'];
                formularyRequirement.clientFormularyReqRuleId = formularyArray['formularyCoverageId'];
                formularyRequirement.negativeChange = formularyArray['negativeChange'] === true ? 'Yes' : 'N/A';
                // eslint-disable-next-line max-len
                formularyRequirement.rejectionMessage = formularyArray['rejectionMessaging'] === 'NA' ? null : formularyArray['rejectionMessaging'];
                formularyRequirement.statusCd = (formularyRequirement.attachmentType !== null
                    && formularyRequirement.attachmentMony !== null) ? (formularyRequirement.attachmentType === ATTACHMENT_LEVEL.GPI
                        && formularyRequirement.attachmentMony !== '')
                        ? '2' : '1' : formularyRequirement.attachmentType === ATTACHMENT_LEVEL.NDC ? '2' : '1';
            }
            formularyRequirements.push(formularyRequirement);
        }
        return formularyRequirements;
    }

    onSaveError() {
        this.bannerService.sendAlert({
            bannerType: CVSBannerType.Error,
            headline: 'System error: Changes have not been saved',
            body: 'Please try again',
            outletId: '#recommendationsBanner',
            bannerLinks: [
                {
                    linkText: 'Retry',
                    linkFunc: () => {
                        this.saveFormularyRequirements();
                    }
                }
            ]
        });
    }

    onSaveSuccess(formularyRequirements: FormularyRequirements[]) {
        if (formularyRequirements.filter(f => f.statusCd==='1').length===0) {
            this.bannerService.sendAlert({
                bannerType: CVSBannerType.Success,
                headline: 'Success',
                body: 'Changes have been saved',
                outletId: '#recommendationsBanner'
            });
        } else {
            this.bannerService.sendAlert({
                bannerType: CVSBannerType.Warning,
                headline: 'Saved with incomplete fields',
                body: 'Form was saved, but there are incomplete fields.',
                outletId: '#recommendationsBanner'
            });
        }
        formularyRequirements.forEach(formularyRequirement =>{
            this.mappedStatus[formularyRequirement.formularyId] = formularyRequirement.statusCd;
        });
        this.isFormularyFormSaved.emit(true);
    }

    validateFormularyForm(index: number, codingTypeSelected: string) {
        if(codingTypeSelected === 'STD_NDC_CD') {
            this.attachmentTypesKey[index.toString()] = [ATTACHMENT_LEVEL.NDC];
            if(this.getFormularyFormGroup[index]?.controls.attachmentType.value === ATTACHMENT_LEVEL.GPI) {
                this.resetFormControlValue(index);
            }
        } else if(codingTypeSelected === 'GPI_NO_RESTRICT_NDC_CD') {
            this.attachmentTypesKey[index.toString()] = [ATTACHMENT_LEVEL.GPI];
            if(this.getFormularyFormGroup[index]?.controls.attachmentType.value === ATTACHMENT_LEVEL.NDC) {
                this.resetFormControlValue(index);
            }
        } else {
            this.attachmentTypesKey[index.toString()] = [ATTACHMENT_LEVEL.GPI, ATTACHMENT_LEVEL.NDC];
        }
    }
    resetFormControlValue(index: number) {
        this.getFormularyFormGroup[index]?.controls.attachmentType.setValue(null);
        this.getFormularyFormGroup[index]?.controls.attachmentMonyDD.setValue(null);
        this.getFormularyFormGroup[index]?.controls.attachmentLevel.setValue(null);
    }

    buildAttachmentType(index: string, codingTypeSelected: string) {
        if(codingTypeSelected === 'STD_NDC_CD') {
            this.attachmentTypesKey[index] = [ATTACHMENT_LEVEL.NDC];
        } else if(codingTypeSelected === 'GPI_NO_RESTRICT_NDC_CD' || this.isUpdateTypeGPIADHOC || this.isUpdateTypeNDCTOGPIADHOC) {
            this.attachmentTypesKey[index.toString()] = [ATTACHMENT_LEVEL.GPI];
        } else {
            this.attachmentTypesKey[index] = [ATTACHMENT_LEVEL.GPI, ATTACHMENT_LEVEL.NDC];
        }
    }

    markMandatoryFieldsAsTouched(index) {
        const mandatoryFieldsFormControls = [
            this.getFormularyFormGroup[index].controls['attachmentType'],
            this.getFormularyFormGroup[index].controls['attachmentLevel'],
            this.getFormularyFormGroup[index].controls['attachmentMonyDD'],
        ];
        mandatoryFieldsFormControls.forEach(formControl => formControl?.markAsTouched());
    }

    computeUpdateTypes() {
        this.isUpdateTypeGPIADHOC = this.medispanTrackerResponse?.updateType === ADHOC_UPDATE_TYPES.GPI_ADHOC;
        this.isUpdateTypeNDCTOGPIADHOC = this.medispanTrackerResponse?.updateType === ADHOC_UPDATE_TYPES.NDC_TO_GPI_ADHOC;
        this.isUpdateTypeNDCADHOC = this.medispanTrackerResponse?.updateType === ADHOC_UPDATE_TYPES.NDC_ADHOC;
        this.isUpdateTypeGPITONDCADHOC = this.medispanTrackerResponse?.updateType === ADHOC_UPDATE_TYPES.GPI_TO_NDC_ADHOC;
    }
}
