<div id="successBanner"></div>
<div class="workflow-container">
    <h1 class="margin-bottom-10">Workflow</h1>
    <div class="right-justified margin-bottom-10" *ngIf='showMoveInWorkflow'>
        <app-custom-button *ngIf="moveInWorkflowMedispanMode" [class]="'cvs-btn-ghost'"
                           id="moveInWorkflowButton"
                           [disabled]="disableMoveInWorkflow"
                           buttonText="Move in Workflow"
                           [cvsTooltip]="disableMoveInWorkflow ? moveInWorkflowTooltip : ''"
                           [placement]="'left'"
                           [showArrow]="true"
                           (onclick)="medispanMoveInWorkflowClick()">
        </app-custom-button>
        <app-custom-button *ngIf="!moveInWorkflowMedispanMode" [class]="'cvs-btn-primary'"
                           id="moveInWorkflowButton"
                           [disabled]="downloadSpinner ? true : disableMoveInWorkflow"
                           buttonText="Move in Workflow"
                           [cvsTooltip]="disableMoveInWorkflow ? moveInWorkflowTooltip : ''"
                           [placement]="'left'"
                           [showArrow]="true"
                           [matMenuTriggerFor]="moveInWorkflowMenu"
                           [svgIcon]="'angle-down--s'">
        </app-custom-button>
        <mat-menu #moveInWorkflowMenu="matMenu">
            <ng-container *ngFor="let moveInWorkflowItem of moveInWorkflowList">
                <button mat-menu-item
                        (click)="workflowMenuClick(moveInWorkflowItem)">{{moveInWorkflowItem.description}}</button>
            </ng-container>
        </mat-menu>
    </div>
</div>

<nav #workflow_main mat-tab-nav-bar class="pds-primary-tab">
    <a mat-tab-link
       class=".mat-tab-label"
       *ngFor="let mainTab of workFlowTabs | keyvalue;let i=index"
       id="workflow_tab_{{buildId(mainTab.value.name)}}"
       [routerLink]="mainTab.value.link"
       routerLinkActive
       #rla_medispan_1="routerLinkActive"
       [state]="{ mainTabSelection: mainTab.value.name }"
       [active]="rla_medispan_1.isActive">
        <div class="mat-tab-label-content">{{mainTab.value.name}}</div>
    </a>
</nav>

<nav mat-tab-nav-bar *ngIf="subTabs">
    <a mat-tab-link
       *ngFor="let subTab of subTabs;let i=index"
       id="workflow_subtab_{{buildId(subTab.name)}}"
       [routerLink]="subTab.link"
       routerLinkActive
       #rla1="routerLinkActive"
       [state]="{ mainTabSelection, subTabIndex: i}"
       [active]="rla1.isActive">
        <div class="mat-tab-label-content">{{subTab.name}}
            <ng-container *ngIf="subTab.chipValue">
                <mat-basic-chip id="chip_{{buildId(subTab.name)}}"
                                disabled="true"
                                class="pds-chip--small pds-status-chip--gray-dark">{{statusCount[subTab.status]}}</mat-basic-chip>
            </ng-container>
        </div>
    </a>
</nav>

<ng-container *ngIf="mainTabSelection === 'Medispan'">
    <div *ngIf="hasEditPermission && subTabIndex === 0" class="right-justified margin-bottom-10">
        <button id="settingsButton" class="pds-btn-text-only" [routerLink]="'/medispanSettings'">
            <mat-icon svgIcon="gear--s"></mat-icon>
            Settings
        </button>
    </div>
    <cvs-loader-overlay-container
    id="medispanReportsSpinner"
    [isLoading]="showSpinner">
    <app-workflow-medispan-list [mainTabName]="mainTabSelection"
                                [subTabIndex]="subTabIndex"
                                [navigatedFromLink]="navigatedFromLink"
                                [triggerSaveChild]="triggerSave"
                                [hasEditPermission]="hasEditPermission"
                                (disableMoveInWorkflowButton)="onDisableMoveInWorkflowButton($event)"
                                (actionNoActionCountForBannerMessage)="showSuccessBannerForActionNoAction($event)"
                                (isLoadingSpinner)="isLoadingSpinner($event)">
    </app-workflow-medispan-list>
    </cvs-loader-overlay-container>
</ng-container>

<ng-container *ngIf="mainTabSelection ==='Research & Requirements'">
    <cvs-loader-overlay-container
            id="researchAndRecommendationSpinner"
            [isLoading]="showSpinner ? showSpinner : downloadSpinner"
            [loadingMessage]="showSpinner ? 'Loading Content...' : 'Downloading Report...'">

    <div class="export-reports-section">
        <button class="pds-btn-ghost" [matMenuTriggerFor]="exportReportsMenu" *ngIf="subTabIndex === 2" 
        [disabled]="disableExportButton">
        <mat-icon svgIcon="angle-down--s" class="pds-transition-base pds-icon-rotation"></mat-icon>
            Export Reports
        </button>
        <mat-menu #exportReportsMenu="matMenu">
            <ng-container *ngFor="let exportReportItem of exportReportsList">
                <button mat-menu-item
                        (click)="downloadRequirementReport(exportReportItem.id)">
                        <mat-icon class="pds-icon--s" svgIcon="download-arrow--s"></mat-icon>
                        <span class="export-reports-menu-item">{{exportReportItem.description}}</span>
                        </button>
            </ng-container>
        </mat-menu>
    </div>

        <app-workflow-research-and-recommendations-list
                [mainTabName]="mainTabSelection"
                [subTabIndex]="subTabIndex"
                [navigatedFromLink]="navigatedFromLink"
                (isLoadingSpinner)="isLoadingSpinner($event)"
                (selectedRows)="setSelectedRows($event)">
        </app-workflow-research-and-recommendations-list>
    </cvs-loader-overlay-container>
</ng-container>

<ng-container *ngIf="mainTabSelection ==='Approval Tracking'">
    <cvs-loader-overlay-container
            id="approvalTrackingSpinner"
            [isLoading]="showSpinner"
            [loadingMessage]="'Loading content...'">
    <div *ngIf="subTabIndex === 3 && showApproveSubmit" >
        <button id="cvsGhostBtn" mat-stroked-button color="primary" [disabled]="!hasRowsSelect" (click)="approveAndRequirementButtonClick()"
                cvsTooltip="Select 1 or more item(s) below to Approve & Submit" placement="right"
                [showArrow]="true" [display]="!hasRowsSelect">
            Approve & Submit Requirements
        </button>
    </div>
    <app-workflow-approval-tracking-list [mainTabName]="mainTabSelection"
                                           [subTabIndex]="subTabIndex"
                                           [navigatedFromLink]="navigatedFromLink"
                                           (selectedRows)="setSelectedRows($event)"
                                         (isLoadingSpinner)="isLoadingSpinner($event)">
    </app-workflow-approval-tracking-list>
    </cvs-loader-overlay-container>
</ng-container>

<ng-container *ngIf="mainTabSelection ==='Operations & Implementation'">
    <cvs-loader-overlay-container
            id="operationsSpinner"
            [isLoading]="showSpinner"
            [loadingMessage]="'Loading content...'">
    <app-workflow-operations-and-implementation-list [mainTabName]="mainTabSelection"
                                                     [subTabIndex]="subTabIndex"
                                                     [navigatedFromLink]="navigatedFromLink"
                                                     (selectedRows)="setSelectedRows($event)"
                                                     (isLoadingSpinner)="isLoadingSpinner($event)">
    </app-workflow-operations-and-implementation-list>
    </cvs-loader-overlay-container>
</ng-container>

<ng-container *ngIf="mainTabSelection ==='No Action'">
    <app-workflow-no-action-list [mainTabName]="mainTabSelection"
                                 [navigatedFromLink]="navigatedFromLink"
                                 (selectedRowCount)="setSelectedRows($event)">
    </app-workflow-no-action-list>
</ng-container>
<ng-container *ngIf="mainTabSelection ==='Archive'">
    <app-workflow-archive-list
            [mainTabName]="mainTabSelection"
            [navigatedFromLink]="navigatedFromLink">
    </app-workflow-archive-list>
</ng-container>
<ng-template #customDialogFormRef>
    <app-move-in-workflow-modal></app-move-in-workflow-modal>
</ng-template>

<ng-template #approveAndSubmitFormRef>
    <app-approve-and-submit-modal [currentStatus]="currentTab?.status" (closeEvent)="closeModal($event)"></app-approve-and-submit-modal>
</ng-template>
