<span [formGroup]="formGroup">
    <h2 class="padding-bottom-0">Search</h2>
    <ng-container *ngFor="let field of displayOption.fieldOrder">
        <ng-container [ngSwitch]="field">
            <mat-form-field class="padding-top margin" *ngSwitchCase="SEARCH_FIELD_ORDER.LOB">
                           <cvs-loader-overlay-container id="lobSpinner"
                                                         [isLoading]=showLobSpinner
                                                         [loadingMessage]="'Loading Content...'">
                                <mat-select disableOptionCentering="true" #lobSelectList placeholder="Line of Business" multiple id="lob" panelClass="searchPanel1"
                                            (selectionChange)="filterBySelectedLobToggleAllCheck($event.source)"
                                            (keydown)="setAllLobSelection(allLob, lobSelectList)"
                                            [formControlName]="'lob'">
                                    <mat-checkbox #allLob (click)="toggleLobAll(allLob,lobSelectList)" class="fdsCheckboxMargin"
                                                  checked="true"
                                              >Select All</mat-checkbox>
                                    <mat-option *ngFor="let lob of linesOfBusiness"
                                                [value]="lob.code"
                                                (click)="setAllLobSelection(allLob, lobSelectList)">{{lob.description}}</mat-option>
                                    <mat-select-trigger *ngIf="displayOption.displayAllWhenAllSelected">
                                        {{matSelectTriggerForLOB}}
                                    </mat-select-trigger>
                                </mat-select>
                    </cvs-loader-overlay-container>
                <mat-error id="lobErrorMessage">Error: Select at least one Line of Business</mat-error>
            </mat-form-field>
            <mat-form-field class="margin"
                            *ngSwitchCase="SEARCH_FIELD_ORDER.FORMULARY">
                <cvs-loader-overlay-container id="formularySpinner"
                                              [isLoading]=showFormularySpinner
                                              [loadingMessage]="'Loading Content...'">
                         <mat-select disableOptionCentering="true" #formularySelectList placeholder="Formulary"
                                     multiple id="formulary"
                                     panelClass="searchPanel2"
                                     [formControlName]="'formulary'"
                                     [disabled]="showFormularySpinner"
                                     (opened)="focusToSearchField()"
                                     (keydown)="setAllFormularySelection(allFormularyCheckbox, formularySelectList);keyDownOnFormulary($event, searchText)"
                                     (closed)="emitSelectedFormularies()"
                         >
                             <mat-form-field class="list_search" label="formularySearchLabel">
                                   <input #searchText id="searchText" type="text" matInput placeholder="Search"
                                          (keydown)="$event.stopPropagation();moveFocusToList($event)"
                                          (keyup)="keyUpEventOnSearchText(searchText.value)">
                                 <button matSuffix mat-icon-button>
                                     <mat-icon svgIcon="search--s" *ngIf="!searchText.value"></mat-icon>
                                     <mat-icon svgIcon="close-btn--s" *ngIf="searchText.value"
                                               (click)="searchText.value=''; onSearchClose(lobSelectList)"
                                     ></mat-icon>
                                 </button>
                             </mat-form-field>
                             <mat-checkbox #allFormularyCheckbox
                                           id="allCheckbox"
                                           [checked]="searchText.value !== '' ? false : allFormularySelectedCheck"
                                           (change)="toggleAllFormulary(searchText.value, allFormularyCheckbox,formularySelectList)"
                                           [ngStyle]="{ display : searchText.value === '' ? '' : 'none' }"
                                           [disabled]="isAllDisabled">ALL</mat-checkbox>
                             <mat-checkbox #allSearchFormulariesCheckbox
                                           id="allSearch"
                                           [checked]="allSearchFormularySelectedCheck"
                                           (change)="setAllFilteredFormulary(allSearchFormulariesCheckbox, findSearchMatchFormularies(searchText.value), formularySelectList)"
                                           [ngStyle]="{ display : (searchText.value !== '' && filteredListFormularyData.length > 0) ? '' : 'none' }"
                                           [disabled]="isSearchAllDisabled">ALL</mat-checkbox>

                                        <mat-option #singleFormulary
                                            id="formularyOption"
                                            *ngFor="let formulary of filteredListFormularyData"
                                            [value]="formulary"
                                            [disabled]="!singleFormulary.selected && (formularySelectList.value.length === FORMULARY_SELECTION_LIMIT)"
                                            (onSelectionChange)="processUserFormularySelectionChange($event)">
                                            {{formulary.formularyNumber}} - {{formulary.clientFormularyName}}
                                        </mat-option>
                             <mat-select-trigger
                                     *ngIf="displayOption.displayAllWhenAllSelected">
                            {{matSelectTriggerForFormulary}}
                             </mat-select-trigger>
                         </mat-select>
                </cvs-loader-overlay-container>
                <mat-hint *ngIf="showHint">Choose up to 10</mat-hint>
                <mat-error id="formularyErrorMessage">Error: Select between 1 to 10 formularies</mat-error>
            </mat-form-field>

            <mat-form-field class="margin" *ngSwitchCase="SEARCH_FIELD_ORDER.DRUG_INDICATOR">
                <mat-select disableOptionCentering="true" placeholder="Drug Indicator" id="drugIndicator"
                            panelClass="searchPanel3" [value]="displayOption.drugIndicatorDefault"
                            [formControlName]="'drugIndicatorFormControl'">
                    <mat-option *ngFor="let di of drugIndicatorList"
                                [value]="di"
                                (onSelectionChange)="resetDrugInput($event)">{{di}}</mat-option>
                </mat-select>
                <mat-error id="drugErrorMessage">Error: Select a drug indicator</mat-error>
            </mat-form-field>
            <ng-container *ngSwitchCase="SEARCH_FIELD_ORDER.DRUG">
                <mat-form-field class="marginInput margin" *ngIf="formGroup.controls.drugIndicatorFormControl.value !== null">
                    <input #drugInputCtrl matInput
                           required
                           [formControlName]="'drugInputFormControl'"
                           id="drugInput"
                           type="text"
                           placeholder="Drug"
                           (keypress)="drugInputKeyPress(displayOption.drugIndicatorDefault, $event);"
                           [(ngModel)]="displayOption.drugDefault"
                           (focusout)="onDrugInputFocusOut(displayOption.drugIndicatorDefault)">
                    <mat-hint [class.red]="isControlInError(formGroup.controls.drugInputFormControl)">
                        {{getErrorOrHintText(formGroup.controls.drugInputFormControl, displayOption.drugIndicatorDefault)}}</mat-hint>
                </mat-form-field>
            </ng-container>
        </ng-container>
    </ng-container>
    <br>
    <span *ngIf="!displayOption.hideAdditionalFilters">
        <div class="display-inherit">
            <pds-toggle-switch #toggle
                               aria-label="Only Show Highest Priority"
                               title="Only Show Highest Priority" id="highestPriorityToggle"
                               (onToggle)="onShowHighestPriorityClick()">
            </pds-toggle-switch>
            <mat-icon id="infoCircleIcon" svgIcon="info-circle--xs"
                      [cvsTooltip]="'Highest priority for every MONY code on this current day for this product only'"
                      showArrow
                      placement="left"></mat-icon>
        </div>
    </span>
    <button id="drugSearch" class="pds-btn-primary drugSearchBlock margin-left"
            *ngIf="!displayOption.hideSearchButton"
            (click)="onSearchIconClick(displayOption.drugIndicatorDefault, displayOption.drugDefault, displayOption.fieldOrder)">Search</button>
   <br/>
    <div *ngIf="!displayOption.hideAdditionalFilters" class="padding-top displayBlock">
        <h2 class="padding-top">Filter</h2>
        <div class="child-grid padding-right">
            <mat-form-field class="padding-right">
                <mat-label>Formulary Status</mat-label>
                <mat-select disableOptionCentering="true"
                            id="formularyStatusSelectionId"
                            [(value)]="formularyStatusListSelection"
                            [disabled]="showHighestPriorityChecked">
                    <mat-option *ngFor="let formularyStatus of formularyStatusList" [value]="formularyStatus"
                              (onSelectionChange)="formularyStatusChanged($event)">{{formularyStatus}}</mat-option>
                </mat-select>
                <mat-hint *ngIf="showHighestPriorityChecked">Turn Off Only Show Highest Priority to Edit</mat-hint>
            </mat-form-field>
            <mat-form-field>
                <mat-label>Coded or Default</mat-label>
                <mat-select disableOptionCentering="true"
                            id="codedOrDefaultStatusSelectionId"
                            [(value)]="codedStatusListSelection"
                            [disabled]="showHighestPriorityChecked">
                    <mat-option *ngFor="let codedStatus of codedStatusList" [value]="codedStatus"
                              (onSelectionChange)="codedDefaultChanged($event)">{{codedStatus}}</mat-option>
                </mat-select>
                <mat-hint *ngIf="showHighestPriorityChecked">Turn Off Only Show Highest Priority to Edit</mat-hint>
            </mat-form-field>
        </div>
        <app-date-picker
                (dateEvent)="getDates($event)"
                [dateRadioGroupHeader]="'Drug Tier Effective Date'"
                [dateRangeHeader]="'Effective Date - Term Date'"
                [currentDate]="fromDate"
                [showHighestPriorityChecked]="showHighestPriorityChecked">
        </app-date-picker>
    </div>
</span>
