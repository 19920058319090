<div id="successBanner"></div>
<div id="errorBanner"></div>
<div id="warningBanner"></div>
<div id="successReviewBanner"></div>
<div id="errorReviewBanner"></div>
<button id="backToClientConfigHome" class="cvs-btn-text-only back-button"
        *ngIf="showStepper && currentStep >= clientConfigurationStepper.FORMULARY_NAME
        && currentStep <= clientConfigurationStepper.LOB_WORKFLOW"
        (click)="navigateToPreviousPage()">
    <mat-icon svgIcon="angle-left--xs"></mat-icon>
    Back to Client Configuration Home
</button>
<button id="backReviewToClientConfigHome" class="cvs-btn-text-only back-button"
        *ngIf="showStepper && currentStep == clientConfigurationStepper.REVIEW"
        (click)="navigateReviewToPreviousPage()">
    <mat-icon svgIcon="angle-left--xs"></mat-icon>
    Back to Client Configuration Home
</button>
<div class="configuration-container">
    <h1>Client Configuration</h1>
    <span class="right-justified">
        <button class="pds-btn-ghost" (click)="downloadClientSetupReport()" *ngIf="displayExport">Export</button>
        <button class="pds-btn-primary" *ngIf="!showStepper"
                (click)="editClientSetUpClick()">Edit Client Configuration</button>
        <button class="pds-btn-ghost back-and-save-button"
                *ngIf="showStepper && currentStep >= clientConfigurationStepper.FORMULARY_REQUIREMENTS
                && currentStep <= clientConfigurationStepper.LOB_WORKFLOW"
                (click)="navigateToPreviousStep()">Back</button>
        <button class="pds-btn-ghost back-and-save-button" *ngIf="showStepper && currentStep == clientConfigurationStepper.REVIEW"
                (click)="navigateReviewToPreviousStep()">Back</button>
        <button class="pds-btn-primary back-and-save-button" *ngIf="showStepper && displaySaveAndContinueButton"
                (click)="saveChanges()">Save & Continue</button>
        <button class="pds-btn-primary" *ngIf="showStepper && currentStep == clientConfigurationStepper.REVIEW"
                (click)="openConfirmDialog(reviewFormRef)"
                [disabled]="!_configurationService.isClientConfigurationModified()">Confirm</button>
    </span>
</div>
<div *ngIf="showSpinner && !showStepper" class="loadingSpinner">
    <cvs-loader-overlay-container [isLoading]="showSpinner" [loadingMessage]="'Downloading Report...'"
                                  [opacity]="0.5"></cvs-loader-overlay-container>
</div>
<div *ngIf="showStepper">
    <mat-stepper #stepper (selectionChange)="onStepChange($event)" [selectedIndex]="currentStep">
        <mat-step label="Formulary Name">
            <div *ngIf="stepper.selectedIndex === 0">
            <app-formulary-setup (isSaveSuccessful)="isSaveSuccessful($event)" (isDirty)="setUpdated($event)" (lobDescription)="setLobName($event)" [currentStepFromParent]=currentStep>
            </app-formulary-setup>
            </div>
        </mat-step>
        <mat-step label="Formulary Requirements">
            <div *ngIf="stepper.selectedIndex === 1">
            <app-formulary-requirements [isUpdated]="isUpdated" [lobLastToggled]="lobLastTouched"></app-formulary-requirements>
            </div>
        </mat-step>
        <mat-step label="UM Program, Product, Drug Coverage Requirements">
            <div *ngIf="stepper.selectedIndex === 2">
            <app-product-drug-requirements [isUpdated]="isUpdated" [lobLastToggled]="lobLastTouched"></app-product-drug-requirements>
            </div>
        </mat-step>
        <mat-step label="Line of Business Workflow">
            <div *ngIf="stepper.selectedIndex === 3">
            <app-lob-workflow (isSaveSuccessful)="isSaveSuccessful($event)"
                              [currentStepFromParent]=currentStep></app-lob-workflow>
            </div>
        </mat-step>
        <mat-step label="Review">
            <div *ngIf="stepper.selectedIndex === 4">
            <app-review-page [currentStepFromParent]=currentStep
                             [showSpinner]="showSpinner">
            </app-review-page>
            </div>
        </mat-step>
    </mat-stepper>
</div>
<ng-template #reviewFormRef>
    <p>Approve this setup? By clicking Approve Setup, the customized <br> information will reflect in
        the CLR tool for {{childReviewComponent?.clientName}}.</p>
    <div id="notesDiv">
        <mat-label id="setupNotesHeader">Setup Notes</mat-label>
        <textarea id="setupNotesTextArea" [(ngModel)]="comments" type="text"></textarea>
    </div>
</ng-template>
<ng-template #confirmReviewFormRef>
    <p>Any changes made to the set up for {{childReviewComponent?.clientName}} must be confirmed to <br>
        be implemented into the CLR tool.</p>
</ng-template>
