import {Injectable} from '@angular/core';
import {CVSHttpClient} from 'angular-component-library';
import {ENVIRONMENT} from '../../environments/environment';
import {Observable} from 'rxjs';
import {RequirementReportRequest} from '../../model/RequirementReportRequest';

@Injectable({
    providedIn: 'root'
})
export class ReportService {

    constructor(private cvsHttpClient: CVSHttpClient) {
    }

    submitReport(index: number,
        formularyNumber: string,
        reportType: string,
        fromDate: string,
        thruDate: string): Observable<any> {
        return this.cvsHttpClient.post(`${ENVIRONMENT.backendBaseUrl}report/submit/${index}`, {
            'formularyNumber': formularyNumber,
            'reportType': reportType,
            'fromDate': fromDate,
            'thruDate': thruDate
        });
    }
    loadSubmittedReportInformation() {
        return this.cvsHttpClient.get(`${ENVIRONMENT.backendBaseUrl}report/reportInformation`);
    }
    downloadReport(downloadLink: string) {
        return this.cvsHttpClient.downloadFilePost(`${ENVIRONMENT.backendBaseUrl}report/download`, {downloadLink});
    }
    getRequirementReport(superClientIndex: number, requirementReportRequest: RequirementReportRequest[]) {
        return this.cvsHttpClient.downloadFilePost(`${ENVIRONMENT.backendBaseUrl}report/requirementReport/${superClientIndex}`,
            requirementReportRequest);
    }
    getClientSetupReport(superClientIndex: number) {
        return this.cvsHttpClient.downloadFile(`${ENVIRONMENT.backendBaseUrl}report/clientSetupReport/${superClientIndex}`);
    }
    getClientRequirementMatrixReport(superClientIndex: number, caseIds: string[]) {
        // eslint-disable-next-line max-len
        return this.cvsHttpClient.downloadFile(`${ENVIRONMENT.backendBaseUrl}report/clientRequirementMatrixReport/${superClientIndex}/${caseIds}`);
    }
    getDraftClientRequirementMatrixReport(superClientIndex: number, requirementReportRequest: RequirementReportRequest[]) {
        // eslint-disable-next-line max-len
        return this.cvsHttpClient.downloadFilePost(`${ENVIRONMENT.backendBaseUrl}report/draftClientRequirementMatrixReport/${superClientIndex}`, requirementReportRequest);
    }
}
