import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import {UserService} from '../service/user/user.service';
import {APP_ADMINISTRATOR_PERMISSION} from '../menu/menu-enum';

@Injectable({
    providedIn: 'root'
})
export class AppAdministratorGuard implements CanActivate {
    constructor(private userService: UserService) {
    }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        const userPermissions = this.userService?.getUser()?.permissions.map(per => per.toUpperCase());
        if (userPermissions) {
            const indexWrite = userPermissions.indexOf(APP_ADMINISTRATOR_PERMISSION.CLR_APP_ADMINISTRATOR_WRITE.toUpperCase());
            const indexRead = userPermissions.indexOf(APP_ADMINISTRATOR_PERMISSION.CLR_APP_ADMINISTRATOR_READ.toUpperCase());
            if (indexRead !== -1 || indexWrite !== -1) {
                return true;
            }
        }
        return false;
    }

}
