import * as moment from 'moment';
import {FORMULARY_DATE_BOUNDARY} from '../enum/FormularyDateBoundary';
import {CustomNavigationComponent} from '../common/custom-navigation/custom-navigation.component';
import {MatTabChangeEvent} from '@angular/material/tabs';
import {ADHOC_UPDATE_TYPES} from '../service/tracker-data/tracker-data.service';

export function dateTruncation(date: string): string {
    return date ? date.slice(0, 10) : '';
}

export function dateFormatter(date: string): string {
    if (date) {
        return moment(date, 'YYYY-MM-DD').format('MM/DD/yyyy');
    }
    return '';
}

export function convertDateToMidnightISO(date: Date): string {
    if (date === undefined || date === null) {
        return undefined;
    }
    return date.toLocaleDateString('en-CA', {
        timeZone: 'America/Chicago',
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
    });
}

export function isValidDate(date: string, maxDate?: string) {
    const maximumDate = (maxDate) ? new Date(maxDate) : new Date(FORMULARY_DATE_BOUNDARY.MAXIMUM_DATE);
    return date !== undefined && date !== null &&
        (new Date(date.concat('T06:00:00')) >= new Date(FORMULARY_DATE_BOUNDARY.MINIMUM_DATE)) &&
        (new Date(date.concat('T06:00:00')) <= maximumDate);
}

export function gridHyperLinkSelector( text: string, compareValue: string) {
    return text === compareValue ?  {component: undefined} :  {component: CustomNavigationComponent};
}

export function gridHyperLinkSelectorByUpdateType(updateType: string){
    return (updateType === ADHOC_UPDATE_TYPES.GPI_ADHOC || updateType  === ADHOC_UPDATE_TYPES.NDC_TO_GPI_ADHOC) ?
        {component: CustomNavigationComponent} : {component: undefined};
}

export function buildTabSelectionChangeEvent(ariaLabel: string, textLabel: string, selectedTabIndex?: number): MatTabChangeEvent {
    return  {
        index: selectedTabIndex,
        tab: {
            textLabel: textLabel,
            ariaLabel: ariaLabel
        }
    } as MatTabChangeEvent;
}

export function utcDate(date: Date): string {
    return moment(date).utc().format('MM/DD/yyy');
}

export function dbDateFormat(date: Date): string {
    if (date === undefined) {
        return undefined;
    }
    const dateDDMMYYYY =  date.toLocaleString('en-US', {
        timeZone: 'America/Chicago',
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
    }).replaceAll('/', '-');
    const parts: string[] = dateDDMMYYYY.split('-');
    return  parts[2] + '-' + parts[0] + '-' + parts[1];
}

export function caseNameHeader(data: any): string {
    return `${data.caseName} (${data.workflowResponse
        ?.length})`;
}

export function setGridHeight(data: any[]) {
    if(data === undefined || data.length < 10 ) {
        return  'auto';
    } else {
        return '320px';
    }
}
