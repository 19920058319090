import {Component, EventEmitter, Injectable, Input, OnInit, TemplateRef} from '@angular/core';
import {take} from 'rxjs/operators';
import {MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import {Observable, Subject} from 'rxjs';
import {CVSConfirmationDialogContentComponent} from 'angular-component-library';
import {
    CVSConfirmationDialogData
} from 'angular-component-library/completed/confirmation-dialog/confirmation-dialog-content.component';
import {AgGridEvent, CellClassParams, ColDef, GridOptions} from '@ag-grid-community/core';
import {ColumnsToolPanelModule} from '@ag-grid-enterprise/column-tool-panel';
import {ClientSideRowModelModule} from '@ag-grid-community/client-side-row-model';
import {MenuModule} from '@ag-grid-enterprise/menu';
import {ClipboardModule} from '@ag-grid-enterprise/clipboard';
import {ExcelExportModule} from '@ag-grid-enterprise/excel-export';
import {SelectionChangedEvent} from '@ag-grid-community/core/dist/cjs/es5/events';

@Component({
    selector: 'app-grid-display-modal',
    templateUrl: './grid-display-modal.component.html',
    styleUrls: ['./grid-display-modal.component.scss']
})
export class GridDisplayModalComponent implements OnInit {
    @Input() noRecordsFoundMessage = 'No results found.';
    gridHeight;
    defaultColDef: ColDef = {
        sortable: true,
        resizable: true,
        suppressSizeToFit: false,
        suppressMenu: true
    };

    gridOptions: GridOptions = {
        defaultColDef: this.defaultColDef,
        enableCellTextSelection: true,
    };
    modules = [ColumnsToolPanelModule, ClientSideRowModelModule,
        MenuModule, ClipboardModule, ExcelExportModule];
    overlayNoRowsTemplate: string;
    constructor(public gridDisplayModalService: GridDisplayModalService) {
        this.initialize();
    }

    initialize() {
        this.gridOptions.isRowSelectable = this.gridDisplayModalService.gridOptions.isRowSelectable;
        this.gridDisplayModalService.onReady.pipe(take(1)).subscribe(() => {
            this.setConfirmationDisable(true);
            this.buildColumnDefs();
            this.gridOptions.onSelectionChanged = (event) => this.onGridSelectionChanged(event);
        });
    }

    ngOnInit(): void {
        this.init();
    }

    init() {
        this.overlayNoRowsTemplate = `<span><img src="/assets/error-f--s.svg" width="25px" height="25px"></br>
                                        <b>No results found.</b></span>`;
        if (this.noRecordsFoundMessage) {
            this.overlayNoRowsTemplate = this.noRecordsFoundMessage;
        }

        const maxRowsToDisplay = (this.gridDisplayModalService.rowData.length > 10 ? 10 : this.gridDisplayModalService.rowData.length);
        if (maxRowsToDisplay >= 10) {
            this.gridHeight = '33vh';
        } else if (maxRowsToDisplay !== 0) {
            this.gridHeight = `${maxRowsToDisplay * (this.gridOptions.rowHeight??32) + 61}px`;
        }
    }
    onGridSelectionChanged(event: SelectionChangedEvent) {
        this.setConfirmationDisable(event.api.getSelectedRows().length === 0);
    }

    buildColumnDefs() {
        const colDefs: ColDef[] = [];
        if(this.gridDisplayModalService.gridOptions.columnDefs.length > 0){
            this.gridDisplayModalService.gridOptions.columnDefs.forEach((colDef, index) => {
                colDefs.push({
                    ...colDef,
                    headerCheckboxSelection: this.gridDisplayModalService.enableHeaderCheckbox && index===0,
                    checkboxSelection: this.gridDisplayModalService.enableHeaderCheckbox && index===0,
                    showDisabledCheckboxes: index===0
                });
            });
            if (this.gridOptions.api) {
                this.gridOptions.api.setColumnDefs(colDefs);
            } else {
                this.gridOptions.columnDefs = colDefs;
            }
            this.gridOptions.columnApi?.autoSizeColumns(['gpi', 'gpiName', 'formularyNameId', 'requirementRuleName'], true);
            this.gridOptions.columnApi?.getColumns().forEach(col => {
                if (col.getColId() === 'formularyNameId' || col.getColId() === 'requirementRuleName') {
                    this.gridOptions.api.sizeColumnsToFit();
                }
            });
            this.gridOptions.api?.forEachNode( node => {
                if(this.gridOptions.isRowSelectable) {
                    node.setSelected(node.selectable);
                }
            });
        }
    }

    onSelectionChanged(event: AgGridEvent) {
        this.gridDisplayModalService.setSelectedRows(event.api.getSelectedNodes());
    }
    private setConfirmationDisable(disable: boolean) {
        try {
            const button = document.getElementsByTagName('cvs-confirmation-dialog-content')[0]
                .getElementsByClassName('pds-btn-primary ng-star-inserted')[0] as HTMLButtonElement;
            button.disabled = disable;
        } catch (e) {
        }
    }
}

@Injectable({
    providedIn: 'root'
})
export class GridDisplayModalService {
    bodyMessageParts: string[] = [];
    footerMessage: string;
    rowData;
    gridOptions: GridOptions;
    enableHeaderCheckbox;
    onReady = new EventEmitter<any>();
    selectedNodes;
    showSpinner;
    updateSize = '60vw';

    constructor(private _dialog: MatDialog) {
    }

    show(modalRef: TemplateRef<any>,
        headline: string,
        bodyMessage: string,
        confirmationButtonText,
        rowData: any[],
        gridOptions: GridOptions,
        enableHeaderCheckbox: boolean, footerMessage?: string): Observable<any[]> {
        const dialogResponse = new Subject<string[]>();
        this.bodyMessageParts = bodyMessage.split('\n');
        this.rowData = rowData;
        this.gridOptions = gridOptions;
        this.enableHeaderCheckbox = enableHeaderCheckbox;
        this.footerMessage = footerMessage;
        const dialogRef = this._dialog.open(CVSConfirmationDialogContentComponent, { disableClose:true,
            data: {
                headline: headline,
                actionLabel: confirmationButtonText,
                cancelLabel: 'Cancel',
                templatePortalContent: modalRef,
            } as CVSConfirmationDialogData
        }).componentInstance as CVSConfirmationDialogContentComponent;
        this.showSpinner = false;
        if (confirmationButtonText === 'Copy') {
            this.updateSize = '36vw';
        } else {
            this.updateSize = '60vw';
        }
        dialogRef.dialogRef.updateSize(this.updateSize);
        dialogRef.onConfirmClick.pipe(take(1))
            .subscribe(() => dialogResponse.next(this.selectedNodes));
        dialogRef.onCancelClick.pipe(take(1)).subscribe(() => dialogResponse.next(undefined));
        dialogRef.dialogRef.afterOpened().pipe(take(1))
            .subscribe(() => this.onReady.emit());
        return dialogResponse;
    }

    setSelectedRows(selectedNodes: any[]) {
        this.selectedNodes = selectedNodes;
    };
}
