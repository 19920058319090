import {Injectable} from '@angular/core';
import {ReplaySubject} from 'rxjs';
import {User} from '../../model/User';
import {SuperClient} from '../../model/SuperClient';
import {isNullOrUndefined} from 'angular-component-library';
import {
    CLR_APPLICATION_PERMISSION,
    CONFIGURATION_PERMISSION, CVS_STANDARD_TEMPLATE_PERMISSIONS,
} from '../../menu/menu-enum';

@Injectable({
    providedIn: 'root'
})
export class UserService {

    userSubject: ReplaySubject<User>;
    superClientContextSubject: ReplaySubject<SuperClient>;
    private user: User;
    private superClients: SuperClient[] = [];
    private superClientContext: SuperClient = {
        id: 0
    };

    constructor() {
        this.userSubject = new ReplaySubject<User>();
        this.superClientContextSubject = new ReplaySubject<SuperClient>();
    }

    setUser(user: User) {
        this.user = user;
        this.superClients = user.clients;
        this.superClientContext = user.clients[0];
        this.superClientContextSubject.next(this.superClientContext);
        this.userSubject.next(user);
    }

    getUserSuperClients() {
        return this.superClients;
    }

    getUser(): User {
        return this.user;
    }

    setSuperClientContext(superClient: SuperClient) {
        this.superClientContext = superClient;
        this.superClientContextSubject.next(this.superClientContext);
    }

    getSuperClientContext(): SuperClient {
        return this.superClientContext;
    }

    getSuperClientContextSubject(): ReplaySubject<SuperClient> {
        return this.superClientContextSubject;
    }

    hasEditPermission(): boolean {
        let hasEdit = false;
        if (!isNullOrUndefined(this.user)) {
            if ( this.getSuperClientContext().superClientCode.toUpperCase() === '*ALL') {
                const indexTemp = this.user.permissions.indexOf(CVS_STANDARD_TEMPLATE_PERMISSIONS.CVS_STANDARD_TEMPLATE_WRITE);
                hasEdit = indexTemp !== -1;
            } else {
                const index = this.user.permissions.indexOf(CLR_APPLICATION_PERMISSION.CLR_APPLICATION_WRITE);
                hasEdit = index !== -1;
            }
        }
        return hasEdit;
    }

    hasClientConfigurationEditPermission(): boolean {
        if (!isNullOrUndefined(this.user)) {
            const index = this.user.permissions.indexOf(CONFIGURATION_PERMISSION.CLR_CONFIGURATION_WRITE);
            return index !== -1;
        }
        return false;
    }

    unSubscribeAuthUser() {
        this.userSubject.unsubscribe();
    }
}

