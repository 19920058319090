import {
    APP_ADMINISTRATOR_PERMISSION,
    CLR_APPLICATION_PERMISSION,
    CONFIGURATION_PERMISSION,
} from './menu-enum';
import {FEATURE_NAME} from '../service/feature-flag/feature-flag.service';

export interface IMenu {
    name?: string;
    icon?: string;
    routerLink?: string;
    externalUser?: boolean;
    order?: number;
    featureFlag?: FEATURE_NAME[];
    role?: string[];
    permissions?: string[];
}

export const CLIENT_SELECTION_MENU: { [menuGroup: string]: IMenu} = {
    CLIENT_SELECTION: {
        name: 'Client Selection',
        icon: 'profile--s',
        routerLink: 'clientSelection',
        order: 0,
        permissions: [ CLR_APPLICATION_PERMISSION.CLR_APPLICATION_READ.toString(),
            CLR_APPLICATION_PERMISSION.CLR_APPLICATION_WRITE.toString(),
            APP_ADMINISTRATOR_PERMISSION.CLR_APP_ADMINISTRATOR_READ.toString(),
            APP_ADMINISTRATOR_PERMISSION.CLR_APP_ADMINISTRATOR_WRITE.toString(),
            CONFIGURATION_PERMISSION.CLR_CONFIGURATION_READ.toString(),
            CONFIGURATION_PERMISSION.CLR_CONFIGURATION_WRITE.toString()]
    }
};

export const MY_PBM_URL_MENU: { [menuGroup: string]: IMenu} = {
    myPBM: {
        name: 'myPBM',
        icon: 'app--s',
        routerLink: 'myPBM',
        order: 7
    }
};

export const MENU: { [menuGroup: string]: IMenu } = {
    WORKFLOW: {
        name: 'Workflow',
        icon: 'lock--s',
        routerLink: 'workflow',
        order: 1,
        permissions: [ CLR_APPLICATION_PERMISSION.CLR_APPLICATION_READ.toString(),
            CLR_APPLICATION_PERMISSION.CLR_APPLICATION_WRITE.toString()],
        featureFlag: [FEATURE_NAME.WORKFLOW_PAGE,FEATURE_NAME.WORKFLOW_PAGE_EXTERNAL]
    },
    FORMULARY_SEARCH: {
        name: 'Formulary Search',
        icon: 'formularies--s',
        routerLink: 'formulary/formularySearch',
        order: 2,
        permissions: [ CLR_APPLICATION_PERMISSION.CLR_APPLICATION_READ.toString(),
            CLR_APPLICATION_PERMISSION.CLR_APPLICATION_WRITE.toString()],
    },
    DRUG_SEARCH: {
        name: 'Drug Search',
        icon: 'drug--s',
        routerLink: 'formulary/drugSearch',
        order: 3,
        permissions: [ CLR_APPLICATION_PERMISSION.CLR_APPLICATION_READ.toString(),
            CLR_APPLICATION_PERMISSION.CLR_APPLICATION_WRITE.toString()],
    },
    REPORTS: {
        name: 'Reports',
        icon: 'file-xls--s',
        routerLink: 'reports',
        order: 4,
        permissions: [ CLR_APPLICATION_PERMISSION.CLR_APPLICATION_READ.toString(),
            CLR_APPLICATION_PERMISSION.CLR_APPLICATION_WRITE.toString()],
        featureFlag: [FEATURE_NAME.REPORTS_PAGE]
    },
    CLIENT_CONFIGURATION: {
        name: 'Client Configuration',
        icon: 'gear--s',
        routerLink: 'configuration',
        order: 5,
        permissions: [CONFIGURATION_PERMISSION.CLR_CONFIGURATION_READ.toString(),
            CONFIGURATION_PERMISSION.CLR_CONFIGURATION_WRITE.toString()]
    },
    APP_ADMINISTRATION: {
        name: 'App Administration',
        icon: 'admin--s',
        routerLink: 'appAdministration',
        order: 6,
        permissions: [APP_ADMINISTRATOR_PERMISSION.CLR_APP_ADMINISTRATOR_READ.toString(),
            APP_ADMINISTRATOR_PERMISSION.CLR_APP_ADMINISTRATOR_WRITE.toString()]
    }
};
