import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {UserService} from '../../service/user/user.service';
import {ColDef, GridOptions} from '@ag-grid-community/core';
import {SetFilterModule} from '@ag-grid-enterprise/set-filter';
import {ClientSideRowModelModule} from '@ag-grid-community/client-side-row-model';
import {FiltersToolPanelModule} from '@ag-grid-enterprise/filter-tool-panel';
import {MenuModule} from '@ag-grid-enterprise/menu';
import {ColumnsToolPanelModule} from '@ag-grid-enterprise/column-tool-panel';
import {ConfigurationService} from '../../service/configuration/configuration.service';
import {FormularyService} from '../../service/formulary/formulary.service';
import {Formulary} from '../../model/Formulary';
import {ClientFormularyMapping} from '../../model/ClientFormularyMapping';
import {take} from 'rxjs';
import {LinesOfBusiness} from '../../model/LinesOfBusiness';
import {dateFormatter} from '../../utility/utility';
import {ClientLobWorkflow} from '../../model/ClientLobWorkflow';
import {BUSINESS_LOB_NAME, CLIENT_MULTI_SOURCE_SETUP, UM_PROGRAM_TYPE} from '../../default-values/DefaultValues';
import {ClientFormularyData} from '../../model/ClientFormularyData';
import {CVSBannerService, CVSBannerType} from 'angular-component-library';
import {CLIENT_CONFIGURATION_STATUS} from '../../enum/ClientConfigurationStatus';
import {CLIENT_CONFIGURATION_STEPPER} from '../../enum/ClientConfigurationStepper';
import { CacheService } from 'src/app/service/HttpDataCache';
import { Router } from '@angular/router';
import { ICLRRouteReuseStrategy } from 'src/app/ICLRRouteReuseStrategy';
import { UMProductDrugCoverageReviewResponse } from 'src/app/model/UMProductDrugCoverageReviewResponse';

@Component({
    selector: 'app-review-page',
    templateUrl: './review-page.component.html',
    styleUrls: ['./review-page.component.scss']
})
export class ReviewPageComponent implements OnChanges {
    @Input() currentStepFromParent;
    @Input() showSpinner: boolean;
    clientName: string;
    domLayout = 'normal';
    modules = [SetFilterModule, ClientSideRowModelModule, FiltersToolPanelModule, MenuModule, ColumnsToolPanelModule];
    defaultColDef: ColDef = {
        sortable: true,
        resizable: true,
        headerClass: 'grid-formulary-Header',
        filter: true,
        menuTabs: ['filterMenuTab', 'columnsMenuTab', 'generalMenuTab']
    };
    columnDefs: ColDef[] = [
        {headerName: 'Formulary Name', field: 'formularyName', pinned: 'left', width: 100},
        {headerName: 'Line of Business', field: 'blob', pinned: 'left', width: 100},
        {headerName: 'Formulary Number', field: 'formularyNumber', width: 100},
        {headerName: 'Formulary From Date', field: 'fromDate', width: 110},
        {headerName: 'Formulary Thru Date', field: 'throughDate', width: 110},
        {headerName: 'Formulary Year', field: 'formularyYear', width: 90},
        {headerName: 'Client Formulary Name*', field: 'clientFormularyName', width: 120},
        {headerName: 'Hide/View Formulary in CLR', field: 'showFormulary', width: 140},
        {headerName: 'Number of Requirement Rules', field: 'activeFormularyRuleCount', width: 140 },
    ];
    gridOptions: GridOptions = {
        defaultColDef: this.defaultColDef,
        columnDefs: this.columnDefs
    };
    clientFormularyData: ClientFormularyData[];
    clientLobWorkflowData: String[];
    clientMultiSourceSetup: String;
    displayLobAndClientMultiSourceSetup = false;
    index;
    umProgramProductDrugCoverageData: UMProductDrugCoverageReviewResponse[];
    columnDefsUMProgramProductDrugCoverage: ColDef[] = [
        { headerName: 'Line of Business', field: 'lobDescription', width: 100 },
        { headerName: 'Number of PA UM Program(s)', field: 'umProgramCountMap', width: 100,
            valueGetter: (params) => this.getUmProgramTypeCount(params, UM_PROGRAM_TYPE[0].code) },
        { headerName: 'Number of ST UM Program(s)', field: 'umProgramCountMap', width: 100,
            valueGetter: (params) => this.getUmProgramTypeCount(params, UM_PROGRAM_TYPE[1].code)},
        { headerName: 'Number of QL UM Program(s)', field: 'umProgramCountMap', width: 100,
            valueGetter: (params) => this.getUmProgramTypeCount(params, UM_PROGRAM_TYPE[2].code)},
        { headerName: 'Number of Product & Drug Coverage', field: 'productDrugCoverageCount', width: 100,
            valueGetter: (params) => this.getProductDrugCoverageCount(params)},
    ];
    gridOptionsUMProgramProductDrugCoverage: GridOptions = {
        defaultColDef: this.defaultColDef,
        columnDefs: this.columnDefsUMProgramProductDrugCoverage
    };

    constructor(private userService: UserService,
        private configurationService: ConfigurationService,
        private _router: Router,
        private formularyService: FormularyService,
        private _bannerService: CVSBannerService) {
    }


    ngOnChanges(changes: SimpleChanges): void {
        this.index = this.userService.getSuperClientContext().id;
        this.clientName = this.userService.getSuperClientContext().clientName;
        if (changes.currentStepFromParent && changes.currentStepFromParent.currentValue === CLIENT_CONFIGURATION_STEPPER.REVIEW) {
            this.fetchAndBuildClientFormularyData();
            this.getClientLobWorkflows();
            this.buildUmProgramProductDrugCoverageData();
        }
    }

    buildUmProgramProductDrugCoverageData() {
        this.configurationService.getClientConfigurationRequirementCounts(this.index).pipe(take(1)).subscribe(
            response => {
                this.umProgramProductDrugCoverageData = response;
            });
    }

    getUmProgramTypeCount(params, code) {
        const result =  params.data?.umProgramCountMap[code];
        return result ? result : 'N/A';
    }

    getProductDrugCoverageCount(params) {
        const result =  params.data?.productDrugCoverageCount;
        return result ? result : 'N/A';
    }

    getActiveFormularyRuleCount(clientFormularyMapping) {
        return clientFormularyMapping?.showFormulary === 'Y' ? clientFormularyMapping?.activeFormularyRuleCount : 'N/A';
    }

    buildClientFormularyData(formularies: Formulary[], clientFormularyMappings: ClientFormularyMapping[]) {
        const clientFormularyData: ClientFormularyData[] = [];
        formularies.forEach(formulary => {
            const clientFormularyMapping = clientFormularyMappings?.find(cfm => cfm.formularyId === formulary.formularyNumber);
            const clientFormulary: ClientFormularyData = {
                clientFormularyName: clientFormularyMapping?.formularyName,
                formularyYear: clientFormularyMapping?.formularyYear,
                showFormulary: clientFormularyMapping?.showFormulary === 'Y' ? 'View' : 'Hide',
                formularyNumber: formulary.formularyNumber,
                formularyName: formulary.name,
                blob: this.concatBlob(formulary.linesOfBusiness),
                fromDate: dateFormatter(formulary.fromDate.toString()),
                throughDate: dateFormatter(formulary.thruDate.toString()),
                activeFormularyRuleCount: this.getActiveFormularyRuleCount(clientFormularyMapping)
            };
            clientFormularyData.push(clientFormulary);
        });
        this.clientFormularyData = [...clientFormularyData];
    }

    fetchAndBuildClientFormularyData() {
        this.formularyService.getFormularyData(this.index, true).pipe(take(1)).subscribe(formularies => {
            this.configurationService.getClientFormularies(this.index).pipe(take(1)).subscribe(clientFormularyData => {
                if (clientFormularyData && clientFormularyData.length > 0) {
                    this.buildClientFormularyData(formularies, clientFormularyData);
                    clientFormularyData?.every(cfd =>
                        Number(cfd.clientConfigurationStatus) === CLIENT_CONFIGURATION_STATUS.CONFIRMED);
                }
            });
        });
    }

    concatBlob(linesOfBusiness: LinesOfBusiness[]) {
        return linesOfBusiness.map(lob => lob.description).join(', ');
    }

    getClientLobWorkflows() {
        this.configurationService.getClientLobWorkflows(this.index).pipe(take(1)).subscribe(data => {
            let isLobModified;
            if (data && data.length > 0) {
                this.populateClientLobWorkflowData(data);
                this.displayLobAndClientMultiSourceSetup = true;
                const isLobSaved = data?.every(d => d.lobWorkflowStatus === 100);
                isLobModified = !isLobSaved;
            } else {
                this.clientLobWorkflowData = [];
                this.clientMultiSourceSetup = '';
                this.displayLobAndClientMultiSourceSetup = false;
                isLobModified = false;
            }
            this.configurationService.setIsLobConfigModified(isLobModified);
        });
    }

    populateClientLobWorkflowData(clientLobWorkflows: ClientLobWorkflow[]) {
        const sortedClientLobWorkflows: string[] = [];
        clientLobWorkflows.sort((a, b) => BUSINESS_LOB_NAME.get(a.businessLobCode).localeCompare(BUSINESS_LOB_NAME.get(b.businessLobCode)));
        clientLobWorkflows.forEach(clientLobWorkflow => {
            if (clientLobWorkflow.clientWorkflowYears.length === 0) {
                sortedClientLobWorkflows.push(BUSINESS_LOB_NAME.get(clientLobWorkflow.businessLobCode));
            } else {
                clientLobWorkflow.clientWorkflowYears.sort((a, b) => Number(a.year) - Number(b.year))
                    .forEach(clientWorkflowYear => {
                        sortedClientLobWorkflows.push(
                            `${BUSINESS_LOB_NAME.get(clientLobWorkflow.businessLobCode)} ${clientWorkflowYear.year}`);
                    });
            }
        });
        this.clientLobWorkflowData = sortedClientLobWorkflows;
        this.clientMultiSourceSetup = CLIENT_MULTI_SOURCE_SETUP
            .find(setup => setup.code === clientLobWorkflows[0].clientMultiSourceSetupCode).description;
    }

    submitClientSetupRequest(comments: string) {
        this.configurationService.submitClientSetUpRequest(this.index, {comment: comments}).pipe(take(1)).subscribe(
            result => {
                this._bannerService.sendAlert({
                    bannerType: CVSBannerType.Success,
                    headline: 'Success',
                    body: 'Latest configuration has been saved.',
                    outletId: '#successReviewBanner'
                });
                this.configurationService.setIsFormularyConfigModified(false);
                this.configurationService.setIsLobConfigModified(false);
                CacheService.instance().clearCache();
                (this._router.routeReuseStrategy as ICLRRouteReuseStrategy)?.delete('formularyformularySearch');
            }, error => {
                this._bannerService.sendAlert({
                    bannerType: CVSBannerType.Error,
                    headline: 'System error: Changes have not been saved',
                    body: 'Please try again.',
                    outletId: '#errorReviewBanner',
                    bannerLinks: [
                        {
                            linkText: 'Retry',
                            linkFunc: () => {
                                this.submitClientSetupRequest(comments);
                            }
                        }
                    ]
                });
            });
    }
}
