// eslint-disable-next-line no-shadow
export enum CLR_APPLICATION_PERMISSION {
    CLR_APPLICATION_READ = 'VIEW_CLR',
    CLR_APPLICATION_WRITE = 'EDIT_CLR'
}

// eslint-disable-next-line no-shadow
export enum CONFIGURATION_PERMISSION {
    CLR_CONFIGURATION_READ = 'CLR_CONFIGURATION_READ',
    CLR_CONFIGURATION_WRITE = 'CLR_CONFIGURATION_WRITE'
}

// eslint-disable-next-line no-shadow
export enum APP_ADMINISTRATOR_PERMISSION {
    CLR_APP_ADMINISTRATOR_READ = 'CLR_APP_ADMINISTRATOR_READ',
    CLR_APP_ADMINISTRATOR_WRITE = 'CLR_APP_ADMINISTRATOR_WRITE'
}

// eslint-disable-next-line no-shadow
export enum CVS_STANDARD_TEMPLATE_PERMISSIONS {
    CVS_STANDARD_TEMPLATE_READ = 'VIEW_CVS_STANDARD_TEMPLATE',
    CVS_STANDARD_TEMPLATE_WRITE = 'VIEW_EDIT_CVS_STANDARD_TEMPLATE'
}

