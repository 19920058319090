import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output
} from '@angular/core';
import {
    CVSAlertType,
    CVSBannerService,
    CVSBannerType, CVSSnackBarData, CVSSnackBarService, PDSSelectionOption,
} from 'angular-component-library';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {FORMULARY_DATE_BOUNDARY} from 'src/app/enum/FormularyDateBoundary';
import {take} from 'rxjs/operators';
import {CVSBannerComponentData} from 'angular-component-library/completed/banner/banner.interfaces';
import {ClientProductDrugCoverage} from '../../../model/ClientProductDrugCoverage';
import {PRODUCT_DRUG_COVERAGE_TYPE} from '../../../default-values/DefaultValues';
import {AppService} from '../../../service/app/app.service';
import {ConfigurationService} from '../../../service/configuration/configuration.service';
import {validDateRange} from '../dateRangeValidation';
import {utcDate} from '../../../utility/utility';
import {ClientFormularyMapping} from '../../../model/ClientFormularyMapping';

@Component({
    selector: 'app-product-drug-coverage',
    templateUrl: './product-drug-coverage.component.html',
    styleUrls: ['./product-drug-coverage.component.scss']
})
export class ProductDrugCoverageComponent implements OnInit {
    @Output() closeEvent: EventEmitter<any> = new EventEmitter<any>();
    @Output() submitEvent: EventEmitter<any> = new EventEmitter<any>();
    @Input() isAnUpdate: boolean;
    @Input() productDrugCoverageState: ClientProductDrugCoverage;
    @Input() businessLobCode;
    @Input() formularies: ClientFormularyMapping[];
    @Input() formularyDescriptionForDD: PDSSelectionOption[];
    hasTruncRejectMessageError = false;
    rejectionMessageValue = '';
    productDrugCoverageForm: FormGroup;
    coverageTypes = PRODUCT_DRUG_COVERAGE_TYPE;
    appearance = 'standard';
    submit = false;


    constructor(
        private fb: FormBuilder,
        private _appService: AppService,
        private _configurationService: ConfigurationService,
        private _bannerService: CVSBannerService,
        private _snackBarService: CVSSnackBarService,
    ) {
        this.createDrugCoverageForm();
    }

    ngOnInit(): void {
        this.resetDrugCoverageModalFields();
        if(this.isAnUpdate) {
            this.loadPrepopulatedProductDrugCoverage();
        }
    }

    public createDrugCoverageForm() {
        this.productDrugCoverageForm = this.fb.group({
            type: [''],
            name: [''],
            description: [''],
            viewStartDate: [new Date(FORMULARY_DATE_BOUNDARY.MINIMUM_DATE), [Validators.required, validDateRange]],
            viewEndDate: [new Date(FORMULARY_DATE_BOUNDARY.MAXIMUM_DATE), [Validators.required, validDateRange]],
            effectiveStartDate: [new Date(FORMULARY_DATE_BOUNDARY.MINIMUM_DATE), [Validators.required, validDateRange]],
            effectiveEndDate: [new Date(FORMULARY_DATE_BOUNDARY.MAXIMUM_DATE), [Validators.required, validDateRange]],
            rejectionMessage: [''],
            formularyId: [''],
            formularyDescription: ['']
        });
    }
    public loadPrepopulatedProductDrugCoverage() {
        this.productDrugCoverageForm = this.fb.group({
            type: [this.productDrugCoverageState.productDrugCoverageTypeCd],
            name: [this.productDrugCoverageState.productDrugCoverageName],
            description: [this.productDrugCoverageState.productDrugCoverageDescription],
            viewStartDate: [new Date(utcDate(this.productDrugCoverageState.productDrugCoverageViewStartDate)),
                [Validators.required, validDateRange]],
            viewEndDate: [new Date(utcDate(this.productDrugCoverageState.productDrugCoverageViewEndDate)),
                [Validators.required, validDateRange]],
            effectiveStartDate: [new Date(utcDate(this.productDrugCoverageState.productDrugCoverageEffectiveStartDate)),
                [Validators.required, validDateRange]],
            effectiveEndDate: [new Date(utcDate(this.productDrugCoverageState.productDrugCoverageEffectiveEndDate)),
                [Validators.required, validDateRange]],
            rejectionMessage: [this.productDrugCoverageState.rejectionMessage],
            formularyId: [this.productDrugCoverageState?.formularyId],
            formularyDescription: [this.productDrugCoverageState?.formularyDescription]
        });
    }

    saveProductDrugCoverage() {
        this.addDrugCoverageModalValidators();
        this.productDrugCoverageForm.markAllAsTouched();
        const isFormValid = this.validateProductDrugCoverageForm() && this.productDrugCoverageForm.valid;
        if (isFormValid) {
            const clientProductDrugCoverage: ClientProductDrugCoverage = {}  as ClientProductDrugCoverage;
            clientProductDrugCoverage.businessLobCode =this.businessLobCode;
            clientProductDrugCoverage.formularyId = this.productDrugCoverageForm.controls['formularyId'].value;
            clientProductDrugCoverage.productDrugCoverageTypeCd = this.productDrugCoverageForm.controls['type'].value;
            clientProductDrugCoverage.productDrugCoverageName = this.productDrugCoverageForm.controls['name'].value.trim();
            clientProductDrugCoverage.productDrugCoverageDescription = this.productDrugCoverageForm.controls['description'].value.trim();
            clientProductDrugCoverage.productDrugCoverageViewStartDate = this.productDrugCoverageForm.controls['viewStartDate'].value;
            clientProductDrugCoverage.productDrugCoverageViewEndDate = this.productDrugCoverageForm.controls['viewEndDate'].value;
            clientProductDrugCoverage.productDrugCoverageEffectiveStartDate =
                this.productDrugCoverageForm.controls['effectiveStartDate'].value;
            clientProductDrugCoverage.productDrugCoverageEffectiveEndDate = this.productDrugCoverageForm.controls['effectiveEndDate'].value;
            clientProductDrugCoverage.rejectionMessage = this.productDrugCoverageForm.controls['rejectionMessage'].value;

            const index = this._appService.getSuperClientContext().id;
            if(this.isAnUpdate) {
                clientProductDrugCoverage.id = this.productDrugCoverageState.id;
                // eslint-disable-next-line max-len
                this._configurationService.updateProductDrugCoverage(index, clientProductDrugCoverage).pipe(take(1))
                    .subscribe({
                        next: () => {
                            this._snackBarService.open({
                                iconName: 'check-circle--s',
                                message: 'Product or Drug Coverage updated successfully',
                                duration: 5,
                                verticalPosition: 'bottom',
                                horizontalPosition: 'center'
                            } as CVSSnackBarData);
                            this.productDrugCoverageForm.markAsPristine();
                            this.submitEvent.emit(true);
                        },error: () => {
                            this.onSaveProductDrugCoverageError();
                        }
                    });
            } else {
                this._configurationService.saveClientProductDrugCoverage(clientProductDrugCoverage, index).pipe(take(1))
                    .subscribe({
                        next: () => {
                            this.productDrugCoverageForm.markAsPristine();
                            this.onSaveProductDrugCoverageSuccess();
                            this.submitEvent.emit(true);
                        }, error: () => {
                            this.onSaveProductDrugCoverageError();
                        }
                    });
            }
        }
    }

    onSaveProductDrugCoverageSuccess() {
        const successBannerData = {
            bannerType: CVSBannerType.Success,
            outletId: '#successBanner',
            headline: 'Success',
            body: 'Product and Drug Coverage has been created.',
            hideX: false,
            alertType: CVSAlertType.Success,
        } as CVSBannerComponentData;
        this._bannerService.sendAlert(successBannerData);
    }

    onSaveProductDrugCoverageError() {
        const errorBannerData = {
            bannerType: CVSBannerType.Error,
            outletId: '#productDrugCoverageErrorBanner',
            hideX: true,
            headline: 'System error: Changes have not been saved',
            body: 'Please try again.',
            bannerLinks: [
                {
                    linkText: 'Retry',
                    linkFunc: () => {
                        this.saveProductDrugCoverage();
                    }
                }
            ]
        } as CVSBannerComponentData;
        this._bannerService.sendAlert(errorBannerData);
    }

    stopWarning() {
        this.hasTruncRejectMessageError = false;
    }

    validateProductDrugCoverageForm() {
        if (!this.productDrugCoverageForm.controls['type'].errors?.required &&
            !this.productDrugCoverageForm.controls['name'].errors?.required &&
            !this.productDrugCoverageForm.controls['description'].errors?.required &&
            !this.productDrugCoverageForm.controls['formularyId'].errors?.required &&
            !this.productDrugCoverageForm.controls['formularyDescription'].errors?.required
        ) {
            return true;
        } else {
            return false;
        }
    }

    onRejectionMessageDrugChanges() {
        if (this.productDrugCoverageForm.controls['rejectionMessage'].value?.length > 120) {
            if (this.rejectionMessageValue.length === 0) {
                this.hasTruncRejectMessageError = true;
            }
            this.productDrugCoverageForm.controls['rejectionMessage']
                .setValue(this.productDrugCoverageForm.controls['rejectionMessage'].value?.substring(0, 120));
        } else {
            this.hasTruncRejectMessageError = false;
        }
        this.rejectionMessageValue = this.productDrugCoverageForm.controls['rejectionMessage'].value;
    }

    onModalCancel() {
        this.closeEvent.emit(true);
    }

    setFormularyId() {
        let formularyList: string[] = [];
        const formularyIds: string[] = [];
        formularyList = this.productDrugCoverageForm.controls['formularyDescription'].value;
        if (formularyList && formularyList.length > 0) {
            formularyList.map(m => {
                formularyIds.push(m.split('-')[0].trim());
            });
        }
        this.productDrugCoverageForm.controls['formularyId'].patchValue(formularyIds.join(','));
        this.productDrugCoverageForm.controls['formularyId'].updateValueAndValidity();
    }

    private addDrugCoverageModalValidators() {
        this.productDrugCoverageForm.controls['type'].addValidators([Validators.required]);
        this.productDrugCoverageForm.controls['type'].updateValueAndValidity({onlySelf: true});
        this.productDrugCoverageForm.controls['name'].addValidators([Validators.required]);
        this.productDrugCoverageForm.controls['name'].updateValueAndValidity({onlySelf: true});
        this.productDrugCoverageForm.controls['description'].addValidators([Validators.required]);
        this.productDrugCoverageForm.controls['description'].updateValueAndValidity({onlySelf: true});
        this.productDrugCoverageForm.controls['formularyId'].addValidators([Validators.required]);
        this.productDrugCoverageForm.controls['formularyId'].updateValueAndValidity({onlySelf: true});
        this.productDrugCoverageForm.controls['formularyDescription'].addValidators(Validators.required);
        this.productDrugCoverageForm.controls['formularyDescription'].updateValueAndValidity({onlySelf: true});

    }

    private resetDrugCoverageModalFields() {
        this.productDrugCoverageForm.reset({
            viewStartDate: new Date(FORMULARY_DATE_BOUNDARY.MINIMUM_DATE),
            viewEndDate: new Date(FORMULARY_DATE_BOUNDARY.MAXIMUM_DATE),
            effectiveStartDate: new Date(FORMULARY_DATE_BOUNDARY.MINIMUM_DATE),
            effectiveEndDate: new Date(FORMULARY_DATE_BOUNDARY.MAXIMUM_DATE)
        });
        this.productDrugCoverageForm.controls['type'].removeValidators([Validators.required]);
        this.productDrugCoverageForm.controls['type'].updateValueAndValidity({onlySelf: true});
        this.productDrugCoverageForm.controls['name'].removeValidators([Validators.required]);
        this.productDrugCoverageForm.controls['name'].updateValueAndValidity({onlySelf: true});
        this.productDrugCoverageForm.controls['description'].removeValidators([Validators.required]);
        this.productDrugCoverageForm.controls['description'].updateValueAndValidity({onlySelf: true});
        this.productDrugCoverageForm.controls['formularyId'].removeValidators([Validators.required]);
        this.productDrugCoverageForm.controls['formularyId'].updateValueAndValidity({onlySelf: true});
        this.rejectionMessageValue = '';
        this.productDrugCoverageForm.controls['formularyDescription'].removeValidators(Validators.required);
        this.productDrugCoverageForm.controls['formularyDescription'].updateValueAndValidity({onlySelf: true});
    }

}
