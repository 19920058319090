import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {ColDef, GridOptions, MenuItemDef} from '@ag-grid-community/core';
import {SetFilterModule} from '@ag-grid-enterprise/set-filter';
import {ColumnsToolPanelModule} from '@ag-grid-enterprise/column-tool-panel';
import {ClientSideRowModelModule} from '@ag-grid-community/client-side-row-model';
import {MenuModule} from '@ag-grid-enterprise/menu';
import {ClipboardModule} from '@ag-grid-enterprise/clipboard';
import {RichSelectModule} from '@ag-grid-enterprise/rich-select';
import {KabobCellComponent} from '../../../common/kabob-cell/kabob-cell.component';
import {CustomStateComponent} from '../../../common/custom-state/custom-state.component';
import {UserService} from '../../../service/user/user.service';
import {BUSINESS_LOB} from '../../../enum/BusinessLob';
import {RecommendationService} from '../../../service/recommendation/recommendation.service';
import {AppService} from '../../../service/app/app.service';
import {Subscription, take} from 'rxjs';
import {ClientUmProgram} from '../../../model/ClientUmProgram';
import {
    BUSINESS_LOB_DESC_CD,
    BUSINESS_LOB_NAME,
    MED_D, PA_SELECTION_COM_EX_MED, PA_SELECTION_MEDICARE, QL_TYPE_FOR_ALL, QL_UNIT_OF_MEASURE,
    RX_OTC, STEP_TYPE_MEDICARE, STEP_TYPE_OTHERS,
    UM_PROGRAM_TYPE
} from '../../../default-values/DefaultValues';
import {MatTabChangeEvent} from '@angular/material/tabs';
import {buildTabSelectionChangeEvent, setGridHeight} from '../../../utility/utility';
import {MedispanTrackerResponse} from '../../../model/MedispanTrackerResponse';
import {
    RecommendationFormState
} from '../recommendations-formulary-requirements/recommendations-formulary-requirements.component';
import {TREE_LEVELS} from '../recommendation-left-navigation/TreeData';
import {Router} from '@angular/router';
import {PriorAuthorizationRequirements} from '../../../model/PriorAuthorizationRequirements';
import {StepTherapyRequirements} from '../../../model/StepTherapyRequirements';
import {QuantityLimitRequirements} from '../../../model/QuantityLimitRequirements';
import {isNullOrUndefined} from 'angular-component-library';

@Component({
    selector: 'app-recommendations-um-program',
    templateUrl: './recommendations-um-program.component.html',
    styleUrls: ['./recommendations-um-program.component.scss']
})
export class RecommendationsUmProgramComponent implements OnInit, OnChanges {
    @Input() medispanTrackerResponse: MedispanTrackerResponse;
    @Input() mainTabName: string;
    @Input() subTabIndex: number;
    @Input() fromSource;
    @Input() updateLoading: boolean;
    @Input() productDetail;
    ndcBusinessLob;
    recommendationFormState: RecommendationFormState;
    selectedBusinessLobName = undefined;
    selectedBusinessLobCd;
    encryptedRecordId;
    BUSINESS_LOB = BUSINESS_LOB;
    NA = 'N/A';
    modules = [SetFilterModule, ColumnsToolPanelModule, ClientSideRowModelModule,
        MenuModule, ClipboardModule, RichSelectModule];
    showRequirementSpinner = true;
    clientUmProgramRowData: ClientUmProgram[];
    filteredClientUmProgramData: ClientUmProgram[];
    subscriptions: Subscription[] = [];
    programTypes = UM_PROGRAM_TYPE;
    gridHeight: any = 'auto';
    context = {this: this};
    popupParent: HTMLElement | null = document.querySelector('body');
    activeGridOptions: GridOptions = {
        defaultColDef: {
            sortable: true,
            resizable: true,
            suppressSizeToFit: true,
        },
        enableCellTextSelection: true,
        suppressLoadingOverlay: true
    };
    savedPriorAuthRequirements: PriorAuthorizationRequirements[] = [];
    savedStepTherapyRequirements: StepTherapyRequirements[] = [];
    savedQuantityLimits: QuantityLimitRequirements[] = [];
    requirementDrugInfo: any[];
    rxOtcList = RX_OTC;
    medDList = MED_D;
    paSelection = Object.values(PA_SELECTION_MEDICARE);
    paSelectionNonMedicare = PA_SELECTION_COM_EX_MED;
    stMedicareSelection = Object.values(STEP_TYPE_MEDICARE);
    stNonMedicareSelection = Object.values(STEP_TYPE_OTHERS);
    qlTypeSelection = QL_TYPE_FOR_ALL;
    qlUnitOfMeasureOptions = QL_UNIT_OF_MEASURE;

    constructor(private appService: AppService,
        private userService: UserService,
        private _router: Router,
        private recommendationService: RecommendationService) {

    }
    ngOnInit(): void {
        this.ndcBusinessLob = this.medispanTrackerResponse.businessLob;
        this.selectedBusinessLobName = this.medispanTrackerResponse.businessLob;
        if ( isNullOrUndefined(this.clientUmProgramRowData) || this.clientUmProgramRowData.length === 0) {
            this.getActiveUmProgramData();
        }
        this.buildColDefs();
        this.subscriptions.push(this.recommendationService.getLoadUmProgramRecommendationsComponentGridDataObservable()
            .subscribe( {
                next: () => {
                    this.filterClientUmProgram(buildTabSelectionChangeEvent(
                        BUSINESS_LOB_DESC_CD.get(this.ndcBusinessLob),
                        this.ndcBusinessLob
                    ));
                }
            }));
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.updateLoading) {
            if (isNullOrUndefined(this.clientUmProgramRowData) || this.clientUmProgramRowData.length === 0) {
                this.getActiveUmProgramData();
            }
            this.showRequirementSpinner = !this.updateLoading;
        }
    }

    getActiveUmProgramData() {
        this.clientUmProgramRowData = [];
        const index = this.appService.getSuperClientContext().id;
        this.subscriptions.push(this.recommendationService.getActiveUmProgram(index).pipe(take(1)).subscribe({
            next: (data) => {
                this.clientUmProgramRowData = data;
            },
            complete: () => {
                this.filterClientUmProgram(buildTabSelectionChangeEvent(
                    BUSINESS_LOB_DESC_CD.get(this.ndcBusinessLob),
                    this.ndcBusinessLob
                ));
            }
        }));
    }

    filterClientUmProgram(event: MatTabChangeEvent) {
        this.showRequirementSpinner = true;
        this.requirementDrugInfo = [];
        this.filteredClientUmProgramData = [];
        if (event.tab.textLabel) {
            this.selectedBusinessLobName = event.tab.textLabel as BUSINESS_LOB;
            this.selectedBusinessLobCd = BUSINESS_LOB_DESC_CD.get(this.selectedBusinessLobName?.toString());
        }
        if (this.clientUmProgramRowData !== undefined) {
            this.filteredClientUmProgramData = this.clientUmProgramRowData
                .filter(o => BUSINESS_LOB_NAME.get(o.businessLobCode) === this.selectedBusinessLobName);
        }
        this.encryptedRecordId = this.productDetail?.filter(
            data => BUSINESS_LOB_NAME.get(data.businessLobCd) === this.selectedBusinessLobName)[0]?.encryptedRecordId;
        if (this.encryptedRecordId) {
            this.subscriptions.push(
                this.recommendationService.getPriorAuthorizationRequirements(this.encryptedRecordId, this.selectedBusinessLobCd)
                    .pipe(take(1))
                    .subscribe({
                        next: (priorAuthorization) => {
                            this.savedPriorAuthRequirements = priorAuthorization;
                            this.buildUmProgramRequirementInfo();
                        }
                    }));
            this.subscriptions.push(
                this.recommendationService.getStepTherapyRequirements(this.encryptedRecordId, this.selectedBusinessLobCd)
                    .pipe(take(1))
                    .subscribe({
                        next: (stepTherapy) => {
                            this.savedStepTherapyRequirements = stepTherapy;
                            this.buildUmProgramRequirementInfo();
                        }
                    }));
            this.subscriptions.push(
                this.recommendationService.getQuantityLimitRequirements(this.encryptedRecordId, this.selectedBusinessLobCd)
                    .pipe(take(1))
                    .subscribe({
                        next: (quantityLimit) => {
                            this.savedQuantityLimits = quantityLimit;
                            this.buildUmProgramRequirementInfo();
                        }
                    }));
        } else {
            this.buildUmProgramRequirementInfo();
        }
        this.recommendationService.setActiveUmProgramsByLOBTab(
            BUSINESS_LOB_DESC_CD.get(this.selectedBusinessLobName?.toString()), this.filteredClientUmProgramData);
        this.buildColDefs();
        this.showRequirementSpinner = false;
    }

    allowEditing(): boolean {
        return (this.ndcBusinessLob === this.selectedBusinessLobName) && this.userService.hasEditPermission();
    }

    buildColDefs() {
        let colDef: ColDef[];
        {
            const allowEditing = this.allowEditing();
            if (this.selectedBusinessLobName === BUSINESS_LOB.MEDICARE) {
                colDef = [
                    {
                        headerName: 'Action', field: 'kabob', cellRenderer: KabobCellComponent, editable: false, width: 65,
                        // eslint-disable-next-line @typescript-eslint/naming-convention
                        cellStyle: {cursor: 'pointer', display: 'flex', 'align-items': 'center'}, pinned: true,
                        onCellClicked: (params) => this.kabobClicked(params),
                        suppressMenu: true,
                        hide: !allowEditing
                    },
                    {
                        headerName: 'Program Type',
                        field: 'umProgramTypeCd',
                        filter: 'agSetColumnFilter',
                        width: 140,
                        pinned: true
                    },
                    {
                        headerName: 'Program Name',
                        field: 'umProgramName',
                        filter: 'agSetColumnFilter',
                        width: 140,
                        pinned: true
                    },
                    {
                        headerName: 'Formulary ID',
                        field: 'formularyId',
                        filter: 'agSetColumnFilter',
                        width: 140,
                        pinned: true
                    },
                    {
                        headerName: 'Requirement State', field: 'statusCd', filter: 'agSetColumnFilter', width: 185,
                        cellRenderer: CustomStateComponent
                    },
                    {
                        headerName: 'Attachment Type', field: 'attachmentType', filter: 'agSetColumnFilter', width: 150
                    },
                    {
                        headerName: 'Attachment Level',
                        field: 'attachmentLevel',
                        filter: 'agSetColumnFilter',
                        width: 150
                    },
                    {
                        headerName: 'Attachment MONY', field: 'monyCode', filter: 'agSetColumnFilter', width: 172
                    },
                    {
                        headerName: 'Attachment Rx/OTC', field: 'coverageMiscRxOtc', filter: 'agSetColumnFilter', width: 160
                    },
                    {
                        headerName: 'Attachment Med D', field: 'coverageMiscMedD', filter: 'agSetColumnFilter', width: 160
                    },
                    {
                        headerName: 'Prior Authorization', field: 'priorAuthorization',
                        filter: 'agSetColumnFilter', width: 190
                    },
                    {
                        headerName: 'Step Type', field: 'stepType',
                        filter: 'agSetColumnFilter', width: 190
                    },
                    {
                        headerName: 'QL Type', field: 'qlType', filter: 'agSetColumnFilter', width: 190
                    },
                    {
                        headerName: 'QL Amount', field: 'qlAmount', filter: 'agSetColumnFilter', width: 190
                    },
                    {
                        headerName: 'QL Unit', field: 'qlUnit', filter: 'agSetColumnFilter', width: 190
                    },
                    {
                        headerName: 'QL Qty Per Period (Days)', field: 'qlQtyPerPeriod', filter: 'agSetColumnFilter', width: 190
                    },
                    {
                        headerName: 'QL Description', field: 'qlDescription', filter: 'agSetColumnFilter', width: 190
                    },
                ];
            }

            if (this.selectedBusinessLobName === BUSINESS_LOB.COMMERCIAL ||
                this.selectedBusinessLobName === BUSINESS_LOB.EXCHANGE ||
                this.selectedBusinessLobName === BUSINESS_LOB.MEDICAID) {
                colDef = [
                    {
                        headerName: 'Action', field: 'kabob', cellRenderer: KabobCellComponent, editable: false, width: 65,
                        // eslint-disable-next-line @typescript-eslint/naming-convention
                        cellStyle: {cursor: 'pointer', display: 'flex', 'align-items': 'center'}, pinned: true,
                        onCellClicked: (params) => this.kabobClicked(params),
                        suppressMenu: true,
                        hide: !allowEditing
                    },
                    {
                        headerName: 'Program Type',
                        field: 'umProgramTypeCd',
                        filter: 'agSetColumnFilter',
                        width: 160,
                        pinned: true
                    },
                    {
                        headerName: 'Program Name',
                        field: 'umProgramName',
                        filter: 'agSetColumnFilter',
                        width: 140,
                        pinned: true
                    },
                    {
                        headerName: 'Formulary ID',
                        field: 'formularyId',
                        filter: 'agSetColumnFilter',
                        width: 140,
                        pinned: true
                    },
                    {
                        headerName: 'Requirement State', field: 'statusCd', filter: 'agSetColumnFilter', width: 185,
                        cellRenderer: CustomStateComponent
                    },
                    {
                        headerName: 'Attachment Type', field: 'attachmentType', filter: 'agSetColumnFilter', width: 150
                    },
                    {
                        headerName: 'Attachment Level',
                        field: 'attachmentLevel',
                        filter: 'agSetColumnFilter',
                        width: 150
                    },
                    {
                        headerName: 'Attachment MONY', field: 'monyCode', filter: 'agSetColumnFilter', width: 172
                    },
                    {
                        headerName: 'Attachment Rx/OTC', field: 'coverageMiscRxOtc', filter: 'agSetColumnFilter', width: 160
                    },
                    {
                        headerName: 'Prior Authorization', field: 'priorAuthorization',
                        filter: 'agSetColumnFilter', width: 190
                    },
                    {
                        headerName: 'Step Type', field: 'stepType',
                        filter: 'agSetColumnFilter', width: 190
                    },
                    {
                        headerName: 'QL Type', field: 'qlType', filter: 'agSetColumnFilter', width: 190
                    },
                    {
                        headerName: 'QL Amount', field: 'qlAmount', filter: 'agSetColumnFilter', width: 190
                    },
                    {
                        headerName: 'QL Unit', field: 'qlUnit', filter: 'agSetColumnFilter', width: 190
                    },
                    {
                        headerName: 'QL Qty Per Period (Days)', field: 'qlQtyPerPeriod', filter: 'agSetColumnFilter', width: 190
                    },
                    {
                        headerName: 'QL Description', field: 'qlDescription', filter: 'agSetColumnFilter', width: 190
                    },
                ];
            }
        }

        if (this.activeGridOptions.api) {
            this.activeGridOptions.api.setColumnDefs(colDef);
        } else {
            this.activeGridOptions.columnDefs = colDef;
        }
    }

    getContextMenuItems(params): MenuItemDef[] {
        const name = 'Edit Requirements';
        const options: MenuItemDef[] = [
            {
                name: name,
                action: () => {
                    params.context.this._router.navigate(['/recommendationsForm'],
                        {state: params.context.this.recommendationFormState});
                }
            }];
        return options;
    }

    buildRecommendationFormState(umProgram?: ClientUmProgram) {
        let umIndex = 0;
        if (umProgram.umProgramTypeCd === UM_PROGRAM_TYPE[0].code) {
            umIndex = TREE_LEVELS.PRIOR_AUTH.code;
        } else if (umProgram.umProgramTypeCd === UM_PROGRAM_TYPE[1].code) {
            umIndex = TREE_LEVELS.STEP_THERAPY.code;
        } else {
            umIndex = TREE_LEVELS.QUANTITY_LIMIT.code;
        }
        this.recommendationFormState = {
            medispanTrackerResponse: this.medispanTrackerResponse,
            tabs: [this.mainTabName, this.subTabIndex],
            programIndex: umIndex,
            umProgram: umProgram,
        };
    }

    public kabobClicked(params: any) {
        this.buildRecommendationFormState(this.filteredClientUmProgramData?.at(params.rowIndex));
        params.api.contextMenuFactory.showMenu(
            params.node, params.column, params.value, params.event
        );
    }

    buildUmProgramRequirementInfo() {
        this.requirementDrugInfo = [];
        this.filteredClientUmProgramData.forEach(umProgram => {
            let priorAuth: PriorAuthorizationRequirements;
            let stepTherapy: StepTherapyRequirements;
            let quantityLimit: QuantityLimitRequirements;
            if (this.savedPriorAuthRequirements) {
                const priorAuthorizationRequirements = this.savedPriorAuthRequirements?.
                    filter(p => Number(p.clientUmProgramId) === umProgram.id);
                if (priorAuthorizationRequirements.length > 0) {
                    priorAuth = priorAuthorizationRequirements[0];
                }
            }
            if (this.savedStepTherapyRequirements) {
                const stepTherapyRequirements = this.savedStepTherapyRequirements?.
                    filter(s => Number(s.clientUmProgramId) === umProgram.id);
                if (stepTherapyRequirements.length > 0) {
                    stepTherapy = stepTherapyRequirements[0];
                }
            }
            if (this.savedQuantityLimits) {
                const quantityLimitRequirements = this.savedQuantityLimits?.
                    filter(q => Number(q.clientUmProgramId) === umProgram.id);
                if (quantityLimitRequirements.length > 0) {
                    quantityLimit = quantityLimitRequirements[0];
                }
            }
            if (umProgram.umProgramTypeCd === '1') {
                this.requirementDrugInfo.push(this.populateRequirementDrugInfo(umProgram, priorAuth));
            } else if (umProgram.umProgramTypeCd === '2') {
                this.requirementDrugInfo.push(this.populateRequirementDrugInfo(umProgram, stepTherapy));
            } else {
                this.requirementDrugInfo.push(this.populateRequirementDrugInfo(umProgram, quantityLimit));
            }
        });
        this.gridHeight = setGridHeight(this.requirementDrugInfo);
    }

    populateRequirementDrugInfo(umProgram, umType): any {
        const umProgramType = umType;
        let drugInfoData = {};
        drugInfoData = {umProgramTypeCd: this.getUmProgramType(umProgram.umProgramTypeCd),
            umProgramName: umProgram.umProgramName,
            formularyId: umProgram ? umProgram.formularyId ? umProgram.formularyId : 'N/A' : 'N/A',
            statusCd: umProgramType ? umProgramType.statusCd ? umProgramType.statusCd : '0' : '0',
            attachmentType: umProgramType ? umProgramType.attachmentType ? umProgramType.attachmentType : 'N/A' : 'N/A' ,
            attachmentLevel: umProgramType ? umProgramType.attachmentLevel ? '(' + umProgramType.attachmentType + ' ' +
                umProgramType.attachmentLevel.length + ') ' + umProgramType.attachmentLevel : 'N/A' : 'N/A',
            monyCode: umProgramType ? umProgramType.attachmentMony ? umProgramType.attachmentMony : 'N/A' : 'N/A',
            coverageMiscRxOtc: umProgramType ? umProgramType.attachmentRxOtcCd ? this.rxOtcList.
                filter(rxOtc => rxOtc.code === umProgramType.attachmentRxOtcCd)[0].description : 'N/A' : 'N/A',
            coverageMiscMedD: umProgramType ? umProgramType.attachmentMedDCd ? umProgramType.businessLobCd !== 'BM' ?
                'N/A' : this.medDList.filter(rxOtc => rxOtc.code === umProgramType.attachmentMedDCd)[0].description : 'N/A' : 'N/A',
            priorAuthorization: umProgramType ? umProgramType.priorAuthId ?
                this.getPaSelection(umProgramType.priorAuthId) : 'N/A' : 'N/A',
            stepType: umProgramType ? umProgramType.stepTypeCd ?
                this.getStSelection(umProgramType.stepTypeCd) : 'N/A' : 'N/A',
            qlType: umProgramType ? umProgramType.qlType ? this.qlTypeSelection[this.qlTypeSelection.
                findIndex(i=> i.code === umProgramType.qlType)].description : 'N/A' : 'N/A',
            qlAmount: umProgramType ? umProgramType.qlAmount ? umProgramType.qlAmount : 'N/A' : 'N/A',
            qlUnit: umProgramType ? umProgramType.qlUnitOfMeasure ? this.qlUnitOfMeasureOptions
                [Number(umProgramType.qlUnitOfMeasure) - 1].formattedSearchResult : 'N/A' : 'N/A',
            qlQtyPerPeriod: umProgramType ? umProgramType.qlPeriod ? umProgramType.qlPeriod : 'N/A' : 'N/A',
            qlDescription: umProgramType ? umProgramType.qlDescription ?
                umProgramType.qlDescription : 'N/A' : 'N/A'};
        return drugInfoData;
    }
    getUmProgramType(code) {
        return this.programTypes[this.programTypes.findIndex(i => i.code === code)].description;
    }

    getPaSelection(code) {
        const lobDescription = (this.selectedBusinessLobName !== undefined) ?
            this.selectedBusinessLobName : this.medispanTrackerResponse.businessLob;
        if (lobDescription === BUSINESS_LOB.MEDICARE) {
            return this.paSelection[this.paSelection.findIndex(i=> i.code === code)].description;
        } else {
            return this.paSelectionNonMedicare[this.paSelectionNonMedicare.findIndex(i=> i.code === code)].description;
        }
    }

    getStSelection(code) {
        const lobDescription = (this.selectedBusinessLobName !== undefined) ?
            this.selectedBusinessLobName : this.medispanTrackerResponse.businessLob;
        if (lobDescription === BUSINESS_LOB.MEDICARE) {
            return this.stMedicareSelection[this.stMedicareSelection.findIndex(i=> i.code === code)].description;
        } else {
            return this.stNonMedicareSelection[this.stNonMedicareSelection.findIndex(i=> i.code === code)].description;
        }
    }
}
