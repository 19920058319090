
<div class="collapse-expend-section">
    <div>
        <p id="requiredFieldStatement">All fields marked with an asterisk (*) are required.</p>
    </div>
    <div>
    <button class="pds-btn-white" (click)="accordion.closeAll()">
        <mat-icon svgIcon="collapse--xs"></mat-icon>
        Collapse All
    </button>
    <button class="pds-btn-white" (click)="accordion.openAll()">
        <mat-icon svgIcon="expand--xs"></mat-icon>
        Expand All
    </button>
    </div>
</div>
<ng-container>
    <mat-accordion multi>
        <mat-expansion-panel  [@.disabled]="true" #panel *ngFor="let qlLimit of quantityLimits"
                              class="gap-mat-expansion"
                              [expanded]="panelOpenState"
                              [formGroup]="quantityLimitFormGroups[qlLimit.id]">
            <mat-expansion-panel-header class="umProgram-QL-collapse-hdr">
                <mat-panel-title id="qlTitle">
                    <div id="quantity-limit-{{qlLimit.umProgramName}}-{{qlLimit.rank}}" class="anchorTarget"></div>
                    <div class="ql-div-hdr"><h2 class="qlH2">{{qlLimit.umProgramName}}</h2></div>
                    <div class="ql-div-hdr-icon"><app-custom-state id="recommendationStatusAll" [status]="mappedStatus[qlLimit.id]"></app-custom-state></div>
                    <div class="ql-div-hdr-formulary">
                        <span class="ql-div-hdr-formulary-label">Formulary ID</span>
                        <span *ngIf="qlLimit?.formularyId;else formulary_na">{{qlLimit.formularyId}}</span>
                        <ng-template #formulary_na>N/A</ng-template>
                    </div>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-card>
                <div class="sub-field-font">
                    <div  class="qlTypeWidth">
                    <mat-card-content>
                        <mat-form-field >
                            <mat-select placeholder="QL Type" formControlName="qlType"
                                        (selectionChange)="enableGridNotes(qlLimit.id)"
                                      >
                                <mat-option *ngFor="let qlType of qlTypeSelection" [value]="qlType.code">
                                    {{qlType.description}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </mat-card-content>
                    </div>
                    <div class="qlOptions" >
                        <mat-card-content >
                        <mat-form-field >
                            <mat-label>QL Amount</mat-label>
                            <app-smart-input
                                    (input)="listenToInputChanges(qlLimit.id)"
                                    maxlength="9"
                                    [allowDigitAndPeriod]="true"
                                    formControlName="qlAmount">
                            </app-smart-input>
                            <mat-error *ngIf="quantityLimitFormGroups[qlLimit.id].controls.qlAmount.value === '' && quantityLimitFormGroups[qlLimit.id].controls.qlAmount.errors?.required" aria-label="error">
                                <mat-icon svgIcon="error-f--xs" matPrefix></mat-icon>
                                Provide QL Amount
                            </mat-error>
                            <mat-error *ngIf="quantityLimitFormGroups[qlLimit.id].controls.qlAmount.errors?.min" aria-label="error">
                                <mat-icon svgIcon="error-f--xs" matPrefix></mat-icon>
                                QL Amount cannot be zero
                            </mat-error>
                        </mat-form-field>
                            </mat-card-content>
                        <mat-card-content *ngIf="enableQlUnitOfMeasure[qlLimit.id]" >
                                <pds-autocomplete-select [dropDownOptions]="observableData"
                                                         [label]="'QL Unit of Measure'"
                                                         [errorMsgs]="{required: '',
                                           valueNotInList: 'Please select a value in the list'}"
                                                         formControlName="qlUnitOfMeasure"
                                                         displayNameKey="formattedSearchResult"
                                                         [inputAppearance]="'standard'"
                                                         [style]="'width: 270px;'"
                                                         (focusin)="listenToInputChanges(qlLimit.id)"
                             >
                                </pds-autocomplete-select>
                            <div id="err" style="width: 58.5%; margin-top: -17px; display: flex"
                                 *ngIf="quantityLimitFormGroups[qlLimit.id].controls.qlUnitOfMeasure.errors?.required">
                                <mat-icon class="ql-unit-of-measure-icon" svgIcon="error-f--xs"></mat-icon>
                                <mat-label class="ql-unit-of-measure-text">
                                    Provide QL Unit</mat-label>
                            </div>
                        </mat-card-content>
                        <mat-card-content *ngIf="disableQlUnitOfMeasure[qlLimit.id]">
                            <mat-form-field>
                                    <app-smart-input
                                            placeholder="QL Unit of Measure"
                                            formControlName="disabledQlUnitOfMeasure">
                                    </app-smart-input>
                            </mat-form-field>
                        </mat-card-content>
                        <mat-card-content>
                        <mat-form-field >
                            <mat-label>QL Period (Days)</mat-label>
                            <app-smart-input
                                    (input)="listenToInputChanges(qlLimit.id)"
                                    [allowDigits]="true"
                                    maxlength="3"
                                    formControlName="qlPeriod">
                            </app-smart-input>
                            <mat-error *ngIf="quantityLimitFormGroups[qlLimit.id].controls.qlPeriod.errors?.required" aria-label="error">
                                <mat-icon svgIcon="error-f--xs" matPrefix></mat-icon>
                                Provide QL Period (Days)
                            </mat-error>
                            <mat-error *ngIf="quantityLimitFormGroups[qlLimit.id].controls.qlPeriod.errors?.min" aria-label="error">
                                <mat-icon svgIcon="error-f--xs" matPrefix></mat-icon>
                                QL Period (Days) cannot be zero
                            </mat-error>
                        </mat-form-field>
                            </mat-card-content>
                        <mat-card-content>
                        <mat-form-field class="{{enableReadOnlys[qlLimit.id]}}" disabled>
                            <mat-label>QL Description</mat-label>
                            <input matInput formControlName="qlDescription">
                        </mat-form-field>
                            </mat-card-content>
                    </div>
                    <mat-card-content class="rejectMessageAndPaNotesGrid">
                        <mat-form-field appearance="outline">
                            <mat-label class="formFieldLabelHeight">Custom Claim Reject Message</mat-label>
                            <textarea (input)="onRejectionMessageQuantityLimitChanges(qlLimit.id, qlLimit.umProgramName)" matInput formControlName="customClaimRejectMessage" name="Custom Claim Reject Message" id="customClaimRejectMessage"></textarea>
                             <mat-hint *ngIf="hasTruncRejectMessageError && (forRejectionMessageFieldName == qlLimit.umProgramName)" class="warning-error-rejection-color">
                                <mat-icon svgIcon="warning-circle--xs" class="warning-icon-color"></mat-icon>Pasted data truncated to {{quantityLimitFormGroups[qlLimit.id].controls['customClaimRejectMessage'].value?.length}} characters
                            </mat-hint>
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                            <mat-label class="formFieldLabelHeight">QL Notes</mat-label>
                            <textarea (input)="onQlNotesQuantityLimitChanges(qlLimit.id, qlLimit.umProgramName)" matInput formControlName="qlNotes" name="QL Notes" id="qlNotes"></textarea>
                            <mat-hint *ngIf="hasTruncQlNotesError && (forQlNotesFieldName == qlLimit.umProgramName)" class="warning-error-rejection-color">
                                <mat-icon svgIcon="warning-circle--xs" class="warning-icon-color"></mat-icon>Pasted data truncated to {{quantityLimitFormGroups[qlLimit.id].controls['qlNotes'].value?.length}} characters
                            </mat-hint>
                            <mat-hint *ngIf="!hasTruncQlNotesError">Provide concise notes for Quantity Limit</mat-hint>
                        </mat-form-field>
                    </mat-card-content>

                </div>
                <div>
                    <mat-checkbox
                            formControlName="applyDefaultCheckBox"
                            (change)="setDefaultSelection(qlLimit.id)">
                        Apply Default Attachment</mat-checkbox>
                </div>
                <div id="tierAttachmentGridAll" class="sub-field-font">
                    <mat-card-content>
                        <mat-form-field>
                            <mat-select placeholder="Attachment Type" formControlName="attachmentType" (selectionChange)="buildAttachmentLevels($event.value, qlLimit.id, false)">
                                <mat-option *ngFor="let attachmentType of attachmentTypes" [value]="attachmentType">
                                    {{attachmentType}}
                                </mat-option>
                            </mat-select>
                            <mat-error><mat-icon svgIcon="error-f--xs"></mat-icon> Select Attachment Type</mat-error>
                        </mat-form-field>
                    </mat-card-content>
                    <mat-card-content>
                        <mat-form-field>
                            <mat-select placeholder="Attachment Level" formControlName="attachmentLevel">
                                <mat-option *ngFor="let level of attachmentLevelsKey[qlLimit.id.toString()]" [value]="level[1]">({{(level[0].replace('_', ' '))}}) {{level[1]}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </mat-card-content>
                    <mat-card-content *ngIf="['GPI'].includes(quantityLimitFormGroups[qlLimit.id].controls.attachmentType.value)">
                        <mat-form-field>
                            <mat-select #attachmentMony
                                        multiple
                                        placeholder="Attachment MONY"
                                        formControlName="attachmentMonyDD">
                                <mat-checkbox #attachmentMonyCheckbox
                                              class="margin"
                                              formControlName="attachmentMonyCheckbox"
                                              [indeterminate]="monyIndeterminates[qlLimit.id]"
                                              click="$event.preventDefault()"
                                              (change)="toggleAllSelection(qlLimit.id)">
                                    Select All
                                </mat-checkbox>
                                <mat-option *ngFor="let data of attachmentMonyList"
                                            [value]="data.code"
                                            (click)="onAttachmentMonyDDChange(qlLimit.id)">{{data.description}}</mat-option>
                            </mat-select>
                            <mat-error><mat-icon svgIcon="error-f--xs"></mat-icon> Select M, O, N, and/or Y</mat-error>
                        </mat-form-field>
                    </mat-card-content>
                    <mat-card-content *ngIf="[undefined, null, '','NDC'].includes(quantityLimitFormGroups[qlLimit.id].controls.attachmentType.value)">
                        <mat-form-field>
                            <app-smart-input
                                    placeholder="Attachment MONY"
                                    formControlName="monyCode">
                            </app-smart-input>
                            <mat-error><mat-icon svgIcon="error-f--xs"></mat-icon> Select M, O, N, and/or Y</mat-error>
                        </mat-form-field>
                    </mat-card-content>
                </div>
                <div id="tierAttachmentRxOtcAll" class="sub-field-font">
                    <mat-card-content>
                        <mat-form-field>
                            <mat-select matInput placeholder="Attachment Rx/OTC" formControlName="coverageMiscRxOtc">
                                <mat-option *ngFor="let data of rxOtcList"
                                            [value]="data.code">{{data.description}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </mat-card-content>
                    <mat-card-content *ngIf="medispanTrackerResponse.businessLob === BUSINESS_LOB.MEDICARE">
                        <mat-form-field>
                            <mat-select  matInput placeholder="Attachment Med D" formControlName="coverageMiscMedD">
                                <mat-option *ngFor="let data of medDList"
                                            [value]="data.code">{{data.description}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </mat-card-content>
                </div>

            </mat-card>
        </mat-expansion-panel>
    </mat-accordion>
</ng-container>
