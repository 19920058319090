<div class="collapse-expend-section">
    <div>
        <p id="requiredFieldStatement">All fields marked with an asterisk (*) are required.</p>
    </div>
    <div>
    <button class="pds-btn-white" (click)="accordion.closeAll()">
        <mat-icon svgIcon="collapse--xs"></mat-icon>
        Collapse All
    </button>
    <button class="pds-btn-white" (click)="accordion.openAll()">
        <mat-icon svgIcon="expand--xs"></mat-icon>
        Expand All
    </button>
    </div>
</div>
<ng-container>
    <mat-accordion multi>
        <mat-expansion-panel  [@.disabled]="true" #panel *ngFor="let umProgramAuth of priorAuths"
                              class="gap-mat-expansion"
                              [expanded]="panelOpenState"
                              [formGroup]="priorAuthFormGroups[umProgramAuth.id]">
            <mat-expansion-panel-header class="umProgram-Prior-Auth-collapse-hdr">
                <mat-panel-title id="priorAuthTitle">
                    <div id="pa-{{umProgramAuth.umProgramName}}-{{umProgramAuth.rank}}" class="anchorTarget"></div>
                    <div class="pa-div-hdr"><h2 class="paH2">{{umProgramAuth.umProgramName}}</h2></div>
                    <div class="pa-div-hdr-icon"><app-custom-state id="recommendationStatusAll" [status]="mappedStatus[umProgramAuth.id]"></app-custom-state></div>
                    <div class="pa-div-hdr-formulary">
                        <span class="pa-div-hdr-formulary-label">Formulary ID</span>
                        <span *ngIf="umProgramAuth?.formularyId;else formulary_na">{{umProgramAuth.formularyId}}</span>
                        <ng-template #formulary_na>N/A</ng-template>
                    </div>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-card>
                <div class="sub-field-font">
                    <mat-card-content>
                        <mat-form-field >
                            <mat-select placeholder="Prior Authorization" formControlName="priorAuthType"
                                                (selectionChange)="onPriorAuthTypeChange(umProgramAuth.id)"
                                        *ngIf="this.medispanTrackerResponse.businessLob === BUSINESS_LOB.MEDICARE">
                                <mat-option *ngFor="let priorAuth of paSelection" [value]="priorAuth.code">
                                    {{priorAuth.description}}
                                </mat-option>
                            </mat-select>
                            <mat-select placeholder="Prior Authorization" formControlName="priorAuthType"
                                                (selectionChange)="onPriorAuthTypeChange(umProgramAuth.id)"
                                        *ngIf="this.medispanTrackerResponse.businessLob !== BUSINESS_LOB.MEDICARE">
                                <mat-option *ngFor="let priorAuth of paSelectionNonMedicare" [value]="priorAuth.code">
                                    {{priorAuth.description}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </mat-card-content>
                    <mat-card-content *ngIf="umProgramAuth.businessLobCode!=='BM' && ageLimitError[umProgramAuth.id]">
                        <div style="width: 38.5%">
                            <cvs-inline-callout [type]="inlineCalloutType.error"
                                                message="Inputted data cannot be saved. Age Limit Min should be
                                                less than Age Limit Max. Resolve the error and save again."
                                                appearance="standard">
                            </cvs-inline-callout>
                        </div>
                    </mat-card-content>
                            <mat-card-content>
                                <pds-autocomplete-select #ageLimitMin *ngIf="displayAgeLimitMinKey[umProgramAuth.id]"
                                        class="matFormFieldLength"
                                        [dropDownOptions]="observableAgeLimitOptions"
                                                         placeholder="Age Limit Min"
                                                         [errorMsgs]="{required: 'Select a valid age between 0-100 year(s)' ,
                                           valueNotInList: 'Select a valid age between 0-100 year(s)'}"
                                [label]="'Age Limit Min'"
                                [hint]="'Select a valid age between 0-100 year(s)'"
                                formControlName="ageLimitMin"
                                displayNameKey="formattedSearchResult"
                                [inputAppearance]="inputAppearance"
                                [required]="'required'"
                                (selectionchange)="editAgeValue(umProgramAuth.id)"
                                >
                                </pds-autocomplete-select>
                                <pds-autocomplete-select #ageLimitMax *ngIf="displayAgeLimitMaxKey[umProgramAuth.id]"
                                                         [class]="addAgeLimitMaxGapKey[umProgramAuth.id] ? 'ageLimitMaxGap matFormFieldLength ': 'matFormFieldLength' "
                                                         [dropDownOptions]="observableAgeLimitOptions"
                                                         placeholder="Age Limit Max"
                                                         [errorMsgs]="{required: 'Select a valid age between 0-100 year(s)',
                                           valueNotInList: 'Select a valid age between 0-100 year(s)'}"
                                                         [label]="'Age Limit Max'"
                                                         [hint]="'Select a valid age between 0-100 year(s)'"
                                                         formControlName="ageLimitMax"
                                                         displayNameKey="formattedSearchResult"
                                                         [inputAppearance]="inputAppearance"
                                                         [required]="'required'"
                                                         (selectionchange)="editAgeValue(umProgramAuth.id)"
                                                         >
                                </pds-autocomplete-select>
                            </mat-card-content>
                    <mat-card-content class="rejectMessageAndPaNotesGrid">
                        <mat-form-field appearance="outline">
                            <mat-label class="formFieldLabelHeight">Custom Claim Reject Message</mat-label>
                            <textarea matInput (input)="onRejectionMessagePriorAuthChanges(umProgramAuth.id, umProgramAuth.umProgramName)" formControlName="customClaimRejectMessage" name="Custom Claim Reject Message" id="customClaimRejectMessage"></textarea>
                            <mat-hint *ngIf="hasTruncRejectMessageError && (forRejectionMessageFieldName == umProgramAuth.umProgramName)" class="warning-error-rejection-color">
                                <mat-icon svgIcon="warning-circle--xs" class="warning-icon-color"></mat-icon>Pasted data truncated to {{priorAuthFormGroups[umProgramAuth.id].controls['customClaimRejectMessage'].value?.length}} characters
                            </mat-hint>
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                            <mat-label class="formFieldLabelHeight">PA Notes</mat-label>
                            <textarea matInput (input)="onPaNotesPriorAuthChanges(umProgramAuth.id, umProgramAuth.umProgramName)" formControlName="paNotes" name="Pa Notes" id="paNotes"></textarea>
                            <mat-hint *ngIf="hasTruncPaNotesError && (forPaNotesFieldName == umProgramAuth.umProgramName)" class="warning-error-rejection-color">
                                <mat-icon svgIcon="warning-circle--xs" class="warning-icon-color"></mat-icon>Pasted data truncated to {{priorAuthFormGroups[umProgramAuth.id].controls['paNotes'].value?.length}} characters
                            </mat-hint>
                            <mat-hint *ngIf="!hasTruncPaNotesError">Provide concise notes for Prior Authorization.</mat-hint>
                        </mat-form-field>
                    </mat-card-content>
                </div>
                <div>
                    <mat-checkbox formControlName="applyDefaultCheckBox"
                                  (change)="setDefaultSelection(umProgramAuth.id)">
                        Apply Default Attachment</mat-checkbox>
                </div>
                <div id="tierAttachmentGridAll" class="sub-field-font">
                    <mat-card-content>
                        <mat-form-field>
                            <mat-select placeholder="Attachment Type" formControlName="attachmentType" (selectionChange)="buildAttachmentLevels($event.value, umProgramAuth.id, false)">
                                <mat-option *ngFor="let attachmentType of attachmentTypes" [value]="attachmentType">
                                    {{attachmentType}}
                                </mat-option>
                            </mat-select>
                            <mat-error><mat-icon svgIcon="error-f--xs"></mat-icon> Select Attachment Type</mat-error>
                        </mat-form-field>
                    </mat-card-content>
                    <mat-card-content>
                        <mat-form-field>
                            <mat-select placeholder="Attachment Level" formControlName="attachmentLevel">
                                <mat-option *ngFor="let level of attachmentLevels[umProgramAuth.id.toString()]" [value]="level[1]">({{(level[0].replace('_', ' '))}}) {{level[1]}}</mat-option>
                            </mat-select>
                            <mat-error><mat-icon svgIcon="error-f--xs"></mat-icon> Select Attachment Level</mat-error>
                        </mat-form-field>
                    </mat-card-content>
                    <mat-card-content *ngIf="['GPI'].includes(priorAuthFormGroups[umProgramAuth.id].controls.attachmentType.value)">
                        <mat-form-field id="multiple-select-all-a11y">
                            <mat-select #attachmentMony
                                        multiple
                                        placeholder="Attachment MONY"
                                        formControlName="attachmentMonyDD"
                                      pdsMultiSelectWithSelectAll>
                                <mat-checkbox #attachmentMonyCheckbox
                                              class="margin"
                                              formControlName="attachmentMonyCheckbox"
                                              [indeterminate]="monyIndeterminates[umProgramAuth.id]"
                                              click="$event.preventDefault()"
                                              (change)="toggleAllSelection(umProgramAuth.id)">
                                    Select All
                                </mat-checkbox>
                                <mat-option *ngFor="let data of attachmentMonyList"
                                            [value]="data.code"
                                            (click)="onAttachmentMonyDDChange(umProgramAuth.id)">{{data.description}}</mat-option>
                            </mat-select>
                            <mat-error><mat-icon svgIcon="error-f--xs"></mat-icon> Select M, O, N, and/or Y</mat-error>
                        </mat-form-field>
                    </mat-card-content>
                    <mat-card-content *ngIf="[undefined, null, '','NDC'].includes(priorAuthFormGroups[umProgramAuth.id].controls.attachmentType.value)">
                        <mat-form-field>
                            <app-smart-input
                                    placeholder="Attachment MONY"
                                    formControlName="monyCode">
                            </app-smart-input>
                            <mat-error><mat-icon svgIcon="error-f--xs"></mat-icon> Select M, O, N, and/or Y</mat-error>
                        </mat-form-field>
                    </mat-card-content>
                </div>
                <div id="tierAttachmentRxOtcAll" class="sub-field-font">
                    <mat-card-content>
                        <mat-form-field>
                            <mat-select matInput placeholder="Attachment Rx/OTC" formControlName="coverageMiscRxOtc">
                                <mat-option *ngFor="let data of rxOtcList"
                                            [value]="data.code">{{data.description}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </mat-card-content>
                    <mat-card-content *ngIf="medispanTrackerResponse.businessLob === BUSINESS_LOB.MEDICARE">
                        <mat-form-field>
                            <mat-select  matInput placeholder="Attachment Med D" formControlName="coverageMiscMedD">
                                <mat-option *ngFor="let data of medDList"
                                            [value]="data.code">{{data.description}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </mat-card-content>
                </div>

            </mat-card>
        </mat-expansion-panel>
    </mat-accordion>
</ng-container>
